// based on draft-js-clear-formatting
import { Modifier, EditorState, SelectionState, ContentBlock, Entity } from 'draft-js';
import { getSelectedBlocksList } from 'utils/draft.utils';

const styles = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH'];
const blockTypes = [
  'ordered-list-item',
  'unordered-list-item',
  'blockquote',
  'paragraph',
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
];

const removeInlineStyles = (editorState: EditorState): EditorState => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();

  const block = editorState.getCurrentContent().getBlockForKey(startKey);
  const startEntityKey = block.getEntityAt(startOffset);
  const endEntityKey = block.getEntityAt(endOffset);

  const isStartLink = startEntityKey !== null && contentState.getEntity(startEntityKey).getType() === 'LINK';
  const isEndLink = endEntityKey !== null && contentState.getEntity(endEntityKey).getType() === 'LINK';
  const isLink = isStartLink || isEndLink;

  const ranges: { start: number; end: number }[] = [];

  block.findEntityRanges(
    character => {
      const entityKey = character.getEntity();
      return entityKey !== null && Entity.get(entityKey).getType() === 'LINK';
    },
    (start, end) => {
      ranges.push({ start, end });
    },
  );

  const range = ranges.find(range => {
    return (
      (startOffset >= range.start && startOffset < range.end) || (endOffset > range.start && endOffset <= range.end)
    );
  });

  const startId = range && range.start < startOffset ? range.start : startOffset;
  const endId = range && range.end > endOffset ? range.end : endOffset;

  const newStartOffset = startId !== -1 ? startId : 0;
  const newEndOffset = endId !== -1 ? endId : block.getText().length;

  const updatedSelection = isLink
    ? new SelectionState({
        anchorKey: block.getKey(),
        anchorOffset: newStartOffset,
        focusKey: block.getKey(),
        focusOffset: newEndOffset,
      })
    : selection;

  const contentWithoutStyles = styles.reduce(
    (acc, style) => Modifier.removeInlineStyle(acc, updatedSelection, style),
    contentState,
  );

  return EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');
};

const removeEntities = (editorState: EditorState): EditorState => {
  const contentState = editorState.getCurrentContent();
  const contentWithoutEntities = Modifier.applyEntity(contentState, editorState.getSelection(), null);

  return EditorState.push(editorState, contentWithoutEntities, 'apply-entity');
};

export const removeLists = (editorState: EditorState, isCleanAll: boolean): EditorState => {
  let contentWithoutLists = editorState.getCurrentContent();
  let newEditorState = editorState;
  const blocksMap = getSelectedBlocksList(editorState);

  blocksMap.forEach((block: ContentBlock | undefined) => {
    if (!block) return;
    const blockType = block.getType();
    const selection = editorState.getSelection();
    const isSelectedWholeBlock =
      (selection.getStartOffset() === 0 && selection.getEndOffset() >= block.getText().length) || isCleanAll;

    if (blockTypes.includes(blockType) && isSelectedWholeBlock) {
      const updatedSelection = isCleanAll
        ? selection
        : new SelectionState({
            anchorKey: block.getKey(),
            anchorOffset: 0,
            focusKey: block.getKey(),
            focusOffset: block.getText().length,
          });

      contentWithoutLists = Modifier.setBlockType(contentWithoutLists, updatedSelection as SelectionState, 'unstyled');
    }
  });

  return EditorState.push(newEditorState, contentWithoutLists, 'change-block-type');
};

const makeHelpersArray = (isCleanAll: boolean) => {
  const helpers = [];
  helpers.push(removeInlineStyles);
  helpers.push(removeEntities);
  helpers.push(removeLists);

  return helpers;
};

export default (editorState: EditorState, isCleanAll: boolean): EditorState => {
  const helpers = makeHelpersArray(isCleanAll);
  return helpers.reduce((acc, helper) => helper(acc, isCleanAll), editorState);
};
