import React, { memo } from 'react';

const CalendarIcon = () => (
  <svg
    className="preview__time-n-place-icon"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="2.62"
      width="12.96"
      height="11.34"
      rx="1.3122"
      stroke="#AAB6CA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.5846 1V3.77714" stroke="#AAB6CA" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.37461 1V3.77714" stroke="#AAB6CA" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.80957 6.20713H13.9596" stroke="#AAB6CA" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default memo(CalendarIcon);
