import * as React from 'react';
import { IntlShape } from 'react-intl';
import ru from 'date-fns/locale/ru';
import { registerLocale } from 'react-datepicker';
import { RawDraftContentState } from 'draft-js';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import IImageInfo, { InitImageInfo } from 'models/IImageInfo';
import { NewsFieldsValuesType } from 'constants/customTypes';
import { themeLimit, descriptionLimit } from 'constants/inputValueLimits';
import { fieldNames } from 'constants/fieldNames';
import { LOCALE } from 'constants/localization';
import CheckedIcon from 'assets/images/icons/checkedicon.svg';
import CustomInput from 'components/CustomInput/CustomInput';
import RichEditor from 'components/Editor/RichEditor';
import ImageDropzone from 'components/ImageDropzone/ImageDropzone';
import UploadedImage from 'components/UploadedImage/UploadedImage';

registerLocale(LOCALE.RU, ru);

interface INewsCreateRequiredFormProps {
  intl: IntlShape;
  onChange: (name: string, value: NewsFieldsValuesType) => void;
  defaultImages: string[];
  defaultImageIds: string[];
  formInfo: {
    subject: string;
    description?: RawDraftContentState;
    image: string | null;
    tmpImageOrig?: string | File | null;
    picId: string | null;
  };
}

interface INewsCreateRequiredFormState {
  isFileUpload: boolean;
  image: File[] | null;
  imageInfo: IImageInfo;
}

export default class NewsCreateRequiredForm extends React.Component<
  INewsCreateRequiredFormProps,
  INewsCreateRequiredFormState
> {
  public imageIds = this.props.defaultImageIds;

  public state = {
    isFileUpload: false,
    image: null,
    imageInfo: new InitImageInfo(),
  };

  public clearSelectedImage = () => {
    this.props.onChange(fieldNames.image, '');
    this.setState({ isFileUpload: false });
  };

  public componentDidMount(): void {
    if (this.props.formInfo.picId === null || this.props.formInfo.picId === undefined) {
      this.props.onChange(fieldNames.defaultImage, this.imageIds[1]);
    }
  }

  public chooseImage = (selectedImageId: string) => {
    this.props.onChange(fieldNames.defaultImage, selectedImageId);
  };

  public fileDropHandler = (files: File[]) => {
    let reader = new FileReader();

    if (files && files.length > 0) {
      let file = files[0];

      reader.onloadend = () => {
        const imagePreviewUrl = !!reader.result ? reader.result.toString() : '';
        this.setState({
          isFileUpload: true,
          imageInfo: {
            imageTitle: file.name,
            imageSize: file.size,
            imagePreviewUrl,
          },
        });
        this.props.onChange(fieldNames.image, file);
      };

      reader.readAsDataURL(file);
    }
  };

  public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.props.onChange(name, value);
  };

  public render() {
    const { formInfo, intl, defaultImages, defaultImageIds } = this.props;
    const { isFileUpload, imageInfo } = this.state;
    const picId = this.props.formInfo.picId;
    const firstDefaultImageId = defaultImageIds[0];
    const secondDefaultImageId = defaultImageIds[1];

    return (
      <div className="additional-form">
        <Card className="card card_form">
          <CardContent>
            <form>
              <CustomInput
                intl={intl}
                maxLength={themeLimit}
                label={intl.formatMessage({
                  id: 'newsList.newsCreate.labelTheme',
                })}
                className="subject"
                multiline={true}
                onChange={this.onChange}
                rows={1}
                name="subject"
                value={formInfo.subject}
              />
              <RichEditor intl={intl} readonly={false} onChange={this.props.onChange} maxLength={descriptionLimit} />
              {!isFileUpload ? (
                <>
                  <div className="input-label input-label__description">
                    {intl.formatMessage({
                      id: 'image.text',
                    })}
                  </div>
                  <div className="image-block">
                    <div className="images-wrapper">
                      <div className="image-wrap">
                        <img
                          src={CheckedIcon}
                          alt="icon"
                          className={
                            firstDefaultImageId
                              ? picId === firstDefaultImageId
                                ? 'active-icon'
                                : 'hide-icon'
                              : 'active-icon'
                          }
                        />
                        <img
                          src={defaultImages ? defaultImages[0] : ''}
                          alt="defaultImage"
                          className={picId === firstDefaultImageId ? 'active-image' : 'default-image'}
                          onClick={() => this.chooseImage(firstDefaultImageId)}
                        />
                      </div>
                      <div className="image-wrap">
                        <img
                          src={CheckedIcon}
                          alt="icon"
                          className={picId === secondDefaultImageId ? 'active-icon' : 'hide-icon'}
                        />
                        <img
                          src={defaultImages ? defaultImages[1] : ''}
                          alt="defaultImage"
                          className={picId === secondDefaultImageId ? 'active-image' : 'default-image'}
                          onClick={() => this.chooseImage(secondDefaultImageId)}
                        />
                      </div>
                    </div>
                    <ImageDropzone onDrop={this.fileDropHandler} />
                  </div>
                </>
              ) : (
                <UploadedImage clearSelectedImage={this.clearSelectedImage} imageInfo={imageInfo} />
              )}
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
