export const meetupTimeInterval = 15;
export const meetupPlanningDistanceInYears = 1;
export const meetupLatestHourForBookingStart = 23;
export const meetupLatestHourForBookingFinish = 23;
export const meetupLatestMinuteForBookingStart = 45;
export const meetupLatestMinuteForBookingFinish = 45;
export const meetupFirstHourForBooking = 0;
export const meetupFirstMinuteForBooking = 0;
export const meetupCalendarDateFormat = 'd MMMM, yyyy HH:mm';
export const meetupCalendarTimeFormat = 'HH:mm';
