import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink, Route, Switch } from 'react-router-dom';
import { RouteUrls } from 'constants/RouteUrls';
import Typography from '@material-ui/core/Typography';
import Themes from './MeetupNavs/Themes';
import DraftMeetup from './MeetupNavs/DraftMeetup';
import FutureMeetup from './MeetupNavs/FutureMeetup';
import PastMeetup from './MeetupNavs/PastMeetup';
import IMeetupShort from 'models/IMeetupShort';

interface ITabContainerProps {
  children?: React.ReactNode;
}

export function TabContainer(props: ITabContainerProps) {
  return (
    <Typography className="meetup-create__content" component="div">
      {props.children}
    </Typography>
  );
}

class MeetupList extends React.Component {
  public restoreScrollPositionIfNeeded(meetup: IMeetupShort[], selectedMeetup: string) {
    const MeetupNode: HTMLElement | null = document.getElementById(selectedMeetup)!;
    const coordinates = (MeetupNode && MeetupNode.offsetTop) - window.innerHeight / 2.5;
    meetup.forEach(meetupItem => {
      if (`${meetupItem.id}` === selectedMeetup && selectedMeetup !== null) {
        window.scroll(0, coordinates);
      } else if (selectedMeetup === '') {
        window.scrollTo(0, 0);
      }
    });
  }

  public render() {
    return (
      <div className="meetup-list__wrapper">
        <div className="meetup-list">
          <div className="meetup-list__header">
            <h1 className="meetup-list__header__heading">
              <FormattedMessage id="meetupList.title" />
            </h1>
          </div>
          <div className="meetup-list__menu">
            <div className="switch">
              <nav className="nav">
                <NavLink className="NavLink  meetup-list__menu__item" to={RouteUrls.Themes}>
                  {<FormattedMessage id="meetupList.tabs.title.all" />}
                </NavLink>
                <NavLink className="NavLink  meetup-list__menu__item" to={RouteUrls.DraftMeetup}>
                  {<FormattedMessage id="meetupList.tabs.title.moderation" />}
                </NavLink>
                <NavLink className="NavLink  meetup-list__menu__item" to={RouteUrls.FutureMeetup}>
                  {<FormattedMessage id="meetupList.tabs.title.published" />}
                </NavLink>
                <NavLink className="NavLink  meetup-list__menu__item" to={RouteUrls.PastMeetup}>
                  {<FormattedMessage id="meetupList.tabs.title.past" />}
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
        <Switch>
          <Route path={RouteUrls.Themes} render={() => <Themes restoreScroll={this.restoreScrollPositionIfNeeded} />} />
          <Route
            path={RouteUrls.DraftMeetup}
            render={() => <DraftMeetup restoreScroll={this.restoreScrollPositionIfNeeded} />}
          />
          <Route
            path={RouteUrls.FutureMeetup}
            render={() => <FutureMeetup restoreScroll={this.restoreScrollPositionIfNeeded} />}
          />
          <Route
            path={RouteUrls.PastMeetup}
            render={() => <PastMeetup restoreScroll={this.restoreScrollPositionIfNeeded} />}
          />
        </Switch>
      </div>
    );
  }
}

export default injectIntl(MeetupList);
