import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import apiConfig from 'config/apiConfig';
import TranslateService from './TranslateService';
import qs from 'qs';
import { NotificationManager } from 'react-notifications';
import { RouteUrls } from 'constants/RouteUrls';
import history from 'utils/history';
import { mediaTypes } from '../constants/mediaTypes';
import { notificationGap } from '../constants/notificationsConstants';
import { MemoEditFieldsValuesType } from '../constants/customTypes';

const UnauthorizedErrorCode = 401;
const ForbiddenErrorCode = 403;
const PageNotFoundErrorCode = 404;

class Service {
  private instance: AxiosInstance;

  constructor() {
    const instance = axios.create(apiConfig);
    instance.interceptors.request.use(
      config => {
        // Check and acquire a token before the request is sent
        return new Promise((resolve, reject) => {
          if (!!apiConfig.idToken) {
            config.headers.Authorization = `Bearer ${apiConfig.idToken}`;
            resolve(config);
          } else {
            // Do something with error of acquiring the token
            reject(config);
          }
        });
      },
      error => {
        // Do something with error of the request
        return Promise.reject(error);
      },
    );

    // Response interceptor to get and display errors in a right way
    instance.interceptors.response.use(
      (value: AxiosResponse) => {
        return value;
      },
      async (error: AxiosError) => {
        const { config, request, response } = error;
        if (response && response.status === PageNotFoundErrorCode) {
          if (!this.isUserPhotoRequest(request)) {
            history.replace(RouteUrls.PageNotFound);
          } else {
            return Promise.resolve({ data: null });
          }
        }
        // If user is not authorized
        else if (response && response.status === UnauthorizedErrorCode) {
          history.push(RouteUrls.UnauthorizedPage);
        }
        // if user has no access to the page
        else if (response && response.status === ForbiddenErrorCode) {
          history.push(RouteUrls.RestrictedPage);
        } else if (response) {
          // If error is present in response data -> error is shown in notification manager
          if (response.data[0] && Array.isArray(response.data[0])) {
            NotificationManager.error(
              response.data.map((e: { message: string; errorCode: number; field: string }) => {
                return e.field !== null
                  ? `${TranslateService.translate('generalUI.error.request')}:
                 ${e.errorCode} /n ${e.message} ${e.field}`
                  : `${TranslateService.translate('generalUI.error.request')}: ${e.errorCode} /n ${e.message}`;
              }),
            );
          } else if (response.data[0] && response.data[0].message) {
            NotificationManager.error(
              `${TranslateService.translate('generalUI.error.request')} ` +
                `${TranslateService.translate('generalUI.error.request.failed')}: ` +
                `${response.data[0].message}`,
            );
          } else {
            NotificationManager.error(
              `${TranslateService.translate('generalUI.error.request')} ` +
                `${config.method} ${config.url} ${TranslateService.translate('generalUI.error.request.failed')}`,
            );
          }
        } else if (request) {
          // No server response
          NotificationManager.error(TranslateService.translate('error.no.response'));
          return instance.request(config);
        }
        return Promise.reject(error);
      },
    );
    this.instance = instance;
  }

  public authUser(token: string) {
    return this.instance.post(apiConfig.endPoints.auth, { accessToken: token });
  }

  public meetUps() {
    return {
      getById: (id: string) => this.instance.get(apiConfig.endPoints.getMeetups + '/' + id),
      createNew: (toCreate: {}) => this.instance.post(apiConfig.endPoints.postMeetup, toCreate),
      edit: (id: string, toEdit: {}) => this.instance.put(apiConfig.endPoints.getMeetups + '/' + id, toEdit),
      delete: (id: string) => this.instance.delete(apiConfig.endPoints.getMeetups + '/' + id),
      publish: (id: string) => this.instance.post(apiConfig.endPoints.publishMeetUp + id + '/confirm'),
      approve: (id: string) => this.instance.post(apiConfig.endPoints.publishMeetUp + id + '/approve'),
      goToMeetup: (id: string) => this.instance.post(apiConfig.endPoints.goToMeetup + id + '/go'),
      cancelGoToMeetup: (id: string) => this.instance.delete(apiConfig.endPoints.goToMeetup + id + '/go'),
      backToModerate: (id: string) => this.instance.post(apiConfig.endPoints.toModerate + id + '/moderate'),
      deleteFile: (fileId: string, config?: AxiosRequestConfig) =>
        this.instance.delete(apiConfig.endPoints.file + fileId, config),
      rateMeetup: (id: string, rate: number) =>
        this.instance.post(apiConfig.endPoints.rateMeetup + id + '/rate/' + rate),
      unrateMeetup: (id: string) => this.instance.delete(apiConfig.endPoints.rateMeetup + id + '/rate'),
    };
  }

  public news() {
    return {
      getById: (id: number) => this.instance.get(apiConfig.endPoints.getNews + '/' + id),
      createNew: (toCreate: {}) => this.instance.post(apiConfig.endPoints.postNews, toCreate),
      edit: (id: number, toEdit: {}) => this.instance.put(apiConfig.endPoints.getNews + '/' + id, toEdit),
      delete: (id: number) => this.instance.delete(apiConfig.endPoints.getNews + '/' + id),
      like: (id: string) => this.instance.post(apiConfig.endPoints.postNews + '/' + id + '/like'),
      dislike: (id: string) => this.instance.post(apiConfig.endPoints.postNews + '/' + id + '/unlike'),
    };
  }

  public themes() {
    return {
      getById: (id: string) => this.instance.get(apiConfig.endPoints.getMeetups + '/' + id),
      delete: (id: string) => this.instance.delete(apiConfig.endPoints.getMeetups + '/' + id),
      like: (id: string) => this.instance.post(apiConfig.endPoints.likeTheme + id + '/like'),
      dislike: (id: string) => this.instance.delete(apiConfig.endPoints.likeTheme + id + '/like'),
      createNew: (toCreate: {}) => this.instance.post(apiConfig.endPoints.postMeetup, toCreate),
      edit: (id: number, toEdit: {}) => this.instance.put(apiConfig.endPoints.getMeetups + '/' + id, toEdit),
    };
  }

  public memo() {
    return {
      getMemo: () => this.instance.get(apiConfig.endPoints.memo),
      editMemo: (toEdit: MemoEditFieldsValuesType) => this.instance.put(apiConfig.endPoints.memo, toEdit),
    };
  }

  public fileUpload(mediaType: string) {
    const prefix =
      mediaType === mediaTypes.Meetup ? apiConfig.endPoints.meetupImageUpload : apiConfig.endPoints.newsImageUpload;
    return {
      meetupImageUpload: (id: string, file: any, config?: AxiosRequestConfig) =>
        this.instance.put(prefix + id + '/pic', file, config),
      meetupFileUpload: (id: string, file: any, config?: AxiosRequestConfig) =>
        this.instance.post(apiConfig.endPoints.meetupFileUpload + id + '/file', file, config),
      getDefaultImageList: (params: AxiosRequestConfig) => this.instance.get(apiConfig.endPoints.defaultImages, params),
    };
  }

  public fileDownload = (fileId: string, config?: AxiosRequestConfig) => {
    return this.instance.get(apiConfig.endPoints.file + fileId, config);
  };

  public archiveFilesDownload = (id: string, config?: AxiosRequestConfig) => {
    return this.instance.get(apiConfig.endPoints.getFileArchive + id + '/file/archive', config);
  };

  public getAllThemes(params: AxiosRequestConfig) {
    const { status, filter } = params.params;
    const parameters = qs.stringify({ status, filter }, { indices: false });
    return this.instance.get(apiConfig.endPoints.getMeetups + '?' + parameters);
  }

  public getAllNews() {
    return this.instance.get(apiConfig.endPoints.getNews);
  }

  public searchUsers(query: string) {
    return this.instance.get(apiConfig.endPoints.searchSpeaker + query);
  }

  public getMeetupImage(meetupId: string, config?: AxiosRequestConfig) {
    return this.instance.get(apiConfig.endPoints.getPicture + meetupId + '?format=base64', config);
  }

  public getNewsImage(picId: string, config?: AxiosRequestConfig) {
    return this.instance.get(apiConfig.endPoints.getPicture + picId + '?format=base64', config);
  }

  public getNewsListImage(picId: string, config?: AxiosRequestConfig) {
    return this.instance.get(apiConfig.endPoints.getPicture + picId + '?size=SMALL&format=base64', config);
  }

  public getNotifications() {
    return this.instance.get(apiConfig.endPoints.getNotifications + `?hours=${notificationGap}`);
  }

  public readNotifications(ids: number[]) {
    return this.instance.post(apiConfig.endPoints.readNotifications, { ids: ids });
  }

  public getFileById(fileId: string, config?: AxiosRequestConfig) {
    return this.instance.get(apiConfig.endPoints.getFileById + fileId, config);
  }

  public getUserPhoto(userId: string, config?: AxiosRequestConfig) {
    return this.instance.get(`${apiConfig.endPoints.users}/${userId}/photo?format=base64`, config);
  }

  private isUserPhotoRequest = (request: any): boolean => {
    return (
      `${request!.responseURL}`.includes(apiConfig.endPoints.users) && `${request!.responseURL}`.includes('/photo')
    );
  };
}

export default new Service();
