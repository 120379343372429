import {
  EDIT_MEMO_ERROR,
  EDIT_MEMO_SUCCESS,
  EDIT_MEMO,
  UPDATE_MEMO_DATA,
  ADD_MEMO,
  DELETE_MEMO,
  MOVE_MEMO_ITEM,
  RESET_MEMO_DATA,
} from '../../../constants/actionConstants';

import { MemoFieldsValuesType } from '../../../constants/customTypes';
import IMemo from '../../../models/IMemo';

export function editMemo(memo: IMemo[]) {
  return { type: EDIT_MEMO, payload: memo };
}

export function updateMemoData(payload: { name: string; value: MemoFieldsValuesType; id?: number }) {
  return { type: UPDATE_MEMO_DATA, payload };
}

export function addMemo() {
  return { type: ADD_MEMO };
}

export function deleteMemo(id: number) {
  return { type: DELETE_MEMO, payload: id };
}

export function editMemoSuccess() {
  return { type: EDIT_MEMO_SUCCESS };
}

export function editMemoError(error: Error) {
  return { type: EDIT_MEMO_ERROR, payload: error };
}

export function moveMemoItem(sourceIndex: number, destinationIndex: number) {
  return {
    type: MOVE_MEMO_ITEM,
    payload: { sourceIndex, destinationIndex },
  };
}

export function resetMemoData() {
  return { type: RESET_MEMO_DATA };
}
