import * as React from 'react';
import { IntlShape } from 'react-intl';

import person from 'assets/images/icons/person.svg';
import CustomButton from 'components/CustomButton/CustomButton';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { dislikeTheme, likeTheme } from '../ThemeLike/ThemeLike.actions';
import { changeSelectedMeetup } from '../../MeetupList/MeetupList.actions';
import classNames from 'classnames';

interface IThemeCardFooterProps {
  id: string;
  likesCount: number;
  intl: IntlShape;
  likeTheme: (themeId: string) => void;
  dislikeTheme: (themeId: string) => void;
  changeSelectedMeetup: (themeId: string) => void;
  isLiked: boolean;
}

class ThemeCardFooter extends React.Component<IThemeCardFooterProps, {}> {
  public handleSupport = () => {
    this.props.changeSelectedMeetup(this.props.id);
    this.props.isLiked ? this.props.dislikeTheme(this.props.id) : this.props.likeTheme(this.props.id);
  };
  render() {
    const { likesCount, intl, isLiked } = this.props;

    return (
      <div className="meetup-card__footer">
        {likesCount > 0 ? (
          <div className="meetup-card__footer__participants">
            <img src={person} alt="person" className="meetup-card__footer__participants__image" />
            <div className="meetup-card__footer__participants__count">{likesCount}</div>
            {intl!.formatMessage({ id: 'themeList.card.supporters' })}
          </div>
        ) : (
          <div />
        )}
        <CustomButton
          className={classNames(
            'button m_button theme-card__footer__button',
            isLiked ? 'dislike-button' : 'like-button',
          )}
          variant="outlined"
          onClick={this.handleSupport}
          label={
            isLiked
              ? intl.formatMessage({ id: 'meetupList.meetupPreview.button.supported' })
              : intl.formatMessage({ id: 'meetupList.meetupPreview.button.support' })
          }
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    likeTheme: bindActionCreators(likeTheme, dispatch),
    dislikeTheme: bindActionCreators(dislikeTheme, dispatch),
    changeSelectedMeetup: bindActionCreators(changeSelectedMeetup, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(ThemeCardFooter);
