import IAppUser from 'models/IAppUser';
import {
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_ERROR,
  AUTHENTICATION_EXPIRED,
} from 'constants/actionConstants';
import { AAD_LOGIN_SUCCESS, AAD_LOGOUT_SUCCESS, IAccountInfo } from 'react-aad-msal';

export interface IUserStore {
  isAuthorized: boolean;
  isAccountError: boolean;
  user: IAppUser;
  aad?: IAccountInfo;
}

const initState = () => {
  return {
    isAuthorized: false,
    isAccountError: false,
    user: {
      id: '',
      name: '',
      surname: '',
      post: '',
      roles: [],
    },
  };
};

export default function userReducer(state: IUserStore = initState(), action: { type: string; payload: any }) {
  switch (action.type) {
    case AAD_LOGIN_SUCCESS:
      return {
        ...state,
        aad: action.payload,
      };
    case AAD_LOGOUT_SUCCESS:
      return {
        ...state,
        aad: undefined,
      };
    case GET_ACCOUNT_INFO:
      return {
        ...state,
      };
    case GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        user: action.payload.data,
      };
    case GET_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        isAccountError: true,
        user: null,
      };
    case AUTHENTICATION_EXPIRED: {
      return {
        ...state,
        isAuthorized: false,
        user: null,
      };
    }
    default:
      return state;
  }
}
