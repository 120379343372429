import * as React from 'react';

interface ITrashBinIconProps {
  width?: number;
  height?: number;
  colour?: string;
}

class TrashBinIcon extends React.Component<ITrashBinIconProps, {}> {
  public render() {
    const { width = 18, height = 18, colour = '#C2CBDA' } = this.props;
    return (
      <svg width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4.19999H2.6H15.4" stroke={colour} strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M14.3001 6.59998C14.3001 6.32384 14.0762 6.09998 13.8001 6.09998C13.524 6.09998 13.3001 6.32384 13.3001 6.59998H14.3001ZM3.1001 6.59998C3.1001 6.32384 2.87624 6.09998 2.6001 6.09998C2.32396 6.09998 2.1001 6.32384 2.1001 6.59998H3.1001ZM4.50003 4.2C4.50003 4.47614 4.72389 4.7 5.00003 4.7C5.27617 4.7 5.50003 4.47614 5.50003 4.2H4.50003ZM10.9 4.2C10.9 4.47614 11.1239 4.7 11.4 4.7C11.6762 4.7 11.9 4.47614 11.9 4.2H10.9ZM13.3001 6.59998V15.4H14.3001V6.59998H13.3001ZM13.3001 15.4C13.3001 16.0075 12.8076 16.5 12.2001 16.5V17.5C13.3599 17.5 14.3001 16.5598 14.3001 15.4H13.3001ZM12.2001 16.5H4.2001V17.5H12.2001V16.5ZM4.2001 16.5C3.59258 16.5 3.1001 16.0075 3.1001 15.4H2.1001C2.1001 16.5598 3.0403 17.5 4.2001 17.5V16.5ZM3.1001 15.4V6.59998H2.1001V15.4H3.1001ZM5.50003 4.2V2.6H4.50003V4.2H5.50003ZM5.50003 2.6C5.50003 1.99249 5.99252 1.5 6.60003 1.5V0.5C5.44023 0.5 4.50003 1.4402 4.50003 2.6H5.50003ZM6.60003 1.5H9.80003V0.5H6.60003V1.5ZM9.80003 1.5C10.4075 1.5 10.9 1.99249 10.9 2.6H11.9C11.9 1.4402 10.9598 0.5 9.80003 0.5V1.5ZM10.9 2.6V4.2H11.9V2.6H10.9Z"
          fill={colour}
        />
        <path d="M6.6002 8.20001V13" stroke={colour} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.7999 8.20001V13" stroke={colour} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
}

export default TrashBinIcon;
