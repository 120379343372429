import apiConfig from 'config/apiConfig';
import ApiService from 'services/ApiService';
import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_ACCOUNT_INFO, GET_ACCOUNT_INFO_ERROR, GET_ACCOUNT_INFO_SUCCESS } from 'constants/actionConstants';

function* getAccountInfoAsync({ payload }: any) {
  try {
    const accountData = yield call(async () => {
      apiConfig.idToken = payload.userInfo.jwtIdToken;
      const result = await ApiService.authUser(payload.userInfo.jwtAccessToken);
      localStorage.setItem('userAccountHint', payload.userInfo.account.userName);
      return result;
    });
    yield put({
      type: GET_ACCOUNT_INFO_SUCCESS,
      payload: accountData,
    });
  } catch (error) {
    yield put({ type: GET_ACCOUNT_INFO_ERROR, payload: error });
  }
}

export const accountSagas = [takeEvery(GET_ACCOUNT_INFO, getAccountInfoAsync)];
