import {
  FETCH_MEETUP_ERROR,
  FETCH_MEETUP_SUCCESS,
  RESET_CURRENT_MEETUP,
  SEARCH_USER_ERROR,
  SEARCH_USER_SUCCESS,
} from 'constants/actionConstants';
import IMeetupDetails, { InitMeetupDetailsState } from 'models/IMeetupDetails';
import { convertStringToRichText } from 'utils/draft.utils';

const initState: IMeetupDetails = new InitMeetupDetailsState();

export default function initialMeetupReducer(
  state: IMeetupDetails = initState,
  action: { type: string; name?: string; payload: any },
) {
  switch (action.type) {
    case RESET_CURRENT_MEETUP:
      return { ...initState };
    case FETCH_MEETUP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        description: convertStringToRichText(action.payload.description),
        speakerId: action.payload.speakers[0].id,
      };
    case FETCH_MEETUP_ERROR:
      return { ...state };
    case SEARCH_USER_SUCCESS:
      return { ...state, availableSpeakers: action.payload.data };
    case SEARCH_USER_ERROR:
      return { ...state };
    default:
      return state;
  }
}
