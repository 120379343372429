import IMemo from '../../models/IMemo';
import { convertStringToRichText, fixDescription, isRawStateEmpty } from '../../utils/draft.utils';
import { descriptionLimit } from '../../constants/inputValueLimits';
import { MemoEditFieldsValuesType } from '../../constants/customTypes';

export const mappingMemoData = (memoData: { subject: string; body: string }[]): IMemo[] => {
  return memoData.map((item: { subject: string; body: string }) => {
    return {
      subject: item.subject,
      body: convertStringToRichText(item.body),
    };
  });
};

export const mappingEditMemoData = (memoData: IMemo[]): MemoEditFieldsValuesType => {
  return memoData.map((item: IMemo) => {
    return {
      subject: item.subject ? item.subject : undefined,
      body: item.body && isRawStateEmpty(item.body) ? fixDescription(item.body, descriptionLimit) : undefined,
    };
  });
};

const isEmpytField = (field: { subject?: string; body?: string }) => !field.subject && !field.body;

export const removeEmptyFields = (memoData: MemoEditFieldsValuesType) => {
  return memoData.filter((item: { subject?: string; body?: string }) => !isEmpytField(item));
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getIdForMemo = (): string => {
  return Math.random().toString();
};
