import * as React from 'react';
import DeleteModal from 'components/Modal/DeleteModal';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'assets/images/icons/EditIcon';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { deleteNews } from 'containers/News/NewsDelete/NewsDelete.actions';
import { FormattedDate } from 'react-intl';
import { IStore } from 'store/rootReducer';
import { Link } from 'react-router-dom';
import { mediaTypes } from 'constants/mediaTypes';
import { UserRoles } from 'constants/UserRoles';
import { triggerTypes } from 'constants/triggerTypes';
import { RouteUrls } from 'constants/RouteUrls';
import { replaceUrlParams } from 'utils/routeUrls';
import history from 'utils/history';
import iconLike from 'assets/images/icons/icon-like.svg';
import iconDislike from 'assets/images/icons/icon-dislike.svg';
import classNames from 'classnames';
import { dislikeNews, likeNews } from '../../../News/NewsLike/NewsLike.actions';
import { changeSelectedNews } from '../../NewsList.actions';

interface INewsCardContentProps {
  id: number | null;
  subject: string;
  excerpt: string;
  postedDate: Date;
  userRole?: UserRoles[];
  image: string | null;
  deleteNews: (newsId: any) => void;
  isLikedByUser: boolean;
  likes: number | null;
  likeNews: (newsId: string) => void;
  dislikeNews: (newsId: string) => void;
  changeSelectedNews: (id: string) => void;
}

class NewsCardContent extends React.Component<INewsCardContentProps, {}> {
  public state = {
    subjectRef: React.createRef<HTMLDivElement>(),
    excerptRef: React.createRef<HTMLDivElement>(),
    subject: this.props.subject,
    excerpt: this.props.excerpt,
  };

  componentDidMount(): void {
    const subjectEl = this.state.subjectRef.current;
    const excerptEl = this.state.excerptRef.current;
    if (subjectEl && subjectEl.scrollHeight > subjectEl.offsetHeight + 1) {
      this.setState({ subject: subjectEl.innerHTML.slice(0, 55) + '...' });
    }
    if (excerptEl && excerptEl.scrollHeight > excerptEl.offsetHeight) {
      this.setState({ excerpt: excerptEl.innerHTML.slice(0, 85) + '...' });
    }
  }

  public handleDelete = () => {
    this.props.deleteNews(this.props.id);
    return;
  };

  public handleLike = () => {
    const { id, isLikedByUser } = this.props;
    this.props.changeSelectedNews(String(id));
    isLikedByUser ? this.props.dislikeNews(String(id)) : this.props.likeNews(String(id));
  };

  public handleEditNews = (id: number | null) => () => history.push(replaceUrlParams(RouteUrls.EditNews, { id }));

  public render() {
    const { postedDate, userRole, id, image, likes, isLikedByUser } = this.props;
    const { excerpt, subject, excerptRef, subjectRef } = this.state;
    const hasLikes = likes !== null && likes > 0;
    return (
      <section className="news-card__content">
        <div className="news-card__header">
          <Link to={replaceUrlParams(RouteUrls.PreviewNews, { id })}>
            <div ref={subjectRef} className="news-card__header__subject">
              {subject}
            </div>
          </Link>
          {userRole!.includes(UserRoles.NEWS_PUBLISHER) ? (
            <div className="news-card__header__buttons">
              <DeleteModal
                handleDelete={this.handleDelete}
                mediaType={mediaTypes.News}
                triggerType={triggerTypes.Icon}
                isConfirmationNeeded={true}
              />
              <IconButton onClick={this.handleEditNews(id)}>
                <EditIcon />
              </IconButton>
            </div>
          ) : null}
        </div>
        <Link to={replaceUrlParams(RouteUrls.PreviewNews, { id })}>
          <div className="news-card__topic-img-wrapper">
            {image && <img className="news-card__topic-img" src={image} alt="meetup-example" />}
          </div>
          <div className="news-card__body">
            <p ref={excerptRef} className="news-card__content__excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
          </div>
        </Link>
        <div className="news-card__footer">
          <span className="news-card__footer__date">
            <FormattedDate value={postedDate} day="numeric" month="numeric" year="numeric" />
          </span>
          <div className="news-card__footer__likes">
            <span className={classNames('news-card__footer__count', { hidden: !hasLikes })}>{likes}</span>
            {isLikedByUser ? (
              <img src={iconLike} alt={'icon-like'} onClick={this.handleLike} />
            ) : (
              <img src={iconDislike} alt={'icon-dislike'} onClick={this.handleLike} />
            )}
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    news: state.news.news,
    userRole: state.userInfo.user.roles,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    deleteNews: bindActionCreators(deleteNews, dispatch),
    likeNews: bindActionCreators(likeNews, dispatch),
    dislikeNews: bindActionCreators(dislikeNews, dispatch),
    changeSelectedNews: bindActionCreators(changeSelectedNews, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsCardContent);
