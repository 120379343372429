import IThemeDetails from 'models/IThemeDetails';
import { FETCH_THEME, FETCH_THEME_ERROR, FETCH_THEME_SUCCESS, RESET_CURRENT_THEME } from 'constants/actionConstants';

export function fetchTheme(id: string) {
  return { type: FETCH_THEME, payload: { id } };
}

export function fetchThemeSuccess(theme: IThemeDetails) {
  return { type: FETCH_THEME_SUCCESS, payload: theme };
}

export function fetchThemeError(error: {}) {
  return {
    type: FETCH_THEME_ERROR,
    payload: error,
  };
}

export function resetThemeData() {
  return { type: RESET_CURRENT_THEME };
}
