import {
  CREATE_MEETUP_ERROR,
  CREATE_MEETUP_SUCCESS,
  DELETE_MEETUP_ERROR,
  DELETE_MEETUP_SUCCESS,
  EDIT_MEETUP_ERROR,
  EDIT_MEETUP_SUCCESS,
  FETCH_MEETUP_ERROR,
  FETCH_MEETUP_SUCCESS,
  GET_DEFAULT_IMAGES_MEETUP_ERROR,
  GET_DEFAULT_IMAGES_MEETUP_SUCCESS,
  RESET_CURRENT_MEETUP,
  SEARCH_USER_ERROR,
  SEARCH_USER_SUCCESS,
  UPDATE_MEETUP_DATA,
  UPLOAD_MEETUP_FILE_STATUS,
} from 'constants/actionConstants';
import IMeetupDetails, { InitMeetupDetailsState } from 'models/IMeetupDetails';
import { convertStringToRichText } from 'utils/draft.utils';

const initState: IMeetupDetails = new InitMeetupDetailsState();

export default function meetupReducer(
  state: IMeetupDetails = initState,
  action: { type: string; name?: string; payload: any },
) {
  switch (action.type) {
    case RESET_CURRENT_MEETUP:
      return { ...initState };
    case CREATE_MEETUP_SUCCESS:
      return { ...state };
    case CREATE_MEETUP_ERROR:
      return { ...state };
    case EDIT_MEETUP_SUCCESS:
      return { ...state };
    case EDIT_MEETUP_ERROR:
      return { ...state };
    case UPDATE_MEETUP_DATA: {
      if (action.payload.index !== undefined) {
        state[action.payload.name][action.payload.index] = action.payload.value;
        return { ...state };
      }
      return { ...state, [action.payload.name]: action.payload.value };
    }
    case FETCH_MEETUP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        description: convertStringToRichText(action.payload.description),
        speakerId: action.payload.speakers[0].id,
      };
    case FETCH_MEETUP_ERROR:
      return { ...state };
    case DELETE_MEETUP_SUCCESS:
      return { ...state };
    case DELETE_MEETUP_ERROR:
      return { ...state };
    case SEARCH_USER_SUCCESS:
      return { ...state, availableSpeakers: action.payload.data };
    case SEARCH_USER_ERROR:
      return { ...state };
    case GET_DEFAULT_IMAGES_MEETUP_SUCCESS:
      return {
        ...state,
        defaultImages: action.payload.defaultImages,
        defaultImageIds: action.payload.defaultImageIds,
      };
    case GET_DEFAULT_IMAGES_MEETUP_ERROR:
      return { ...state };
    case UPLOAD_MEETUP_FILE_STATUS:
      return { ...state, fileUploaded: action.payload.isUploaded };
    default:
      return state;
  }
}
