import { takeEvery, call, put, select } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import { EDIT_MEMO } from 'constants/actionConstants';
import { RouteUrls } from 'constants/RouteUrls';
import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import history from 'utils/history';
import { editMemoError, editMemoSuccess } from './MemoEdit.actions';
import { getMemo } from '../../../selectors/storeSelectors';
import { mappingEditMemoData, removeEmptyFields } from '../Memo.utils';

function* editMemo() {
  const payload = yield select(getMemo);
  const newPayload = mappingEditMemoData(payload);
  const newPayloadWithoutEmptyFields = removeEmptyFields(newPayload);

  try {
    yield call(ApiService.memo().editMemo, newPayloadWithoutEmptyFields);
    yield put(editMemoSuccess());
    NotificationManager.success(TranslateService.translate('memo.memoEdit.success'));
    yield history.push(RouteUrls.MemoManagement);
  } catch (error) {
    yield put(editMemoError(error));
    NotificationManager.error(TranslateService.translate('memo.memoEdit.error'));
  }
}

export const memoEditSagas = [takeEvery(EDIT_MEMO, editMemo)];
