import {
  FETCH_NEWS_LIST,
  FETCHED_NEWS_LIST_SUCCESS,
  FETCHED_NEWS_LIST_ERROR,
  CHANGE_SELECTED_NEWS,
  DELETE_SELECTED_NEWS,
} from 'constants/actionConstants';
import INewsDetails from 'models/INewsDetails';

export interface INewsStore {
  news: INewsDetails[];
  isEmpty: boolean;
  selectedNews: string;
}

const initState: INewsStore = {
  news: [],
  isEmpty: false,
  selectedNews: '',
};

export default function newsReducer(state: INewsStore = initState, action: { type: string; payload: any }) {
  switch (action.type) {
    case FETCH_NEWS_LIST:
      return { ...state };
    case FETCHED_NEWS_LIST_SUCCESS:
      return {
        ...state,
        news: action.payload,
        isEmpty: action.payload.length === 0,
      };
    case FETCHED_NEWS_LIST_ERROR:
      return { ...state };
    case CHANGE_SELECTED_NEWS:
      return { ...state, selectedNews: action.payload };
    case DELETE_SELECTED_NEWS:
      return { ...state, selectedNews: action.payload };
    default:
      return state;
  }
}
