import {
  FETCH_MEETUPS_LIST,
  FETCHED_MEETUPS_LIST_SUCCESS,
  FETCHED_MEETUPS_LIST_ERROR,
  CHANGE_SELECTED_MEETUP,
  DELETE_SELECTED_MEETUP,
  SAVE_PATHNAME_MEETUP_TABS,
  CLEAR_SELECTED_MEETUP,
  SAVE_ID_MEETUP_FOR_DELETE,
  CLEAR_DELETED_MEETUP,
  CANCEL_GO_TO_MEETUP,
  GO_TO_MEETUP,
} from 'constants/actionConstants';
import IMeetupShort from 'models/IMeetupShort';

export function fetchMeetupsList(meetupFilter: { status: string; filter: string }) {
  return { type: FETCH_MEETUPS_LIST, payload: meetupFilter };
}

export function fetchedMeetupsListSuccess(meetupSelector: { status: string; filter: string }, meetups: IMeetupShort[]) {
  return { type: FETCHED_MEETUPS_LIST_SUCCESS, payload: { meetupSelector: meetupSelector, meetups: meetups } };
}

export function fetchedMeetupListError(error: {}) {
  return { type: FETCHED_MEETUPS_LIST_ERROR, payload: error };
}

export function changeSelectedMeetup(meetupId: string) {
  return { type: CHANGE_SELECTED_MEETUP, payload: meetupId };
}

export function saveMeetabLinkUrl(goBackUrl: string) {
  return { type: SAVE_PATHNAME_MEETUP_TABS, payload: goBackUrl };
}

export function saveMeetupIdForDelete(deletetMeetup: string) {
  return { type: SAVE_ID_MEETUP_FOR_DELETE, payload: deletetMeetup };
}

export function clearDeletedMeetup() {
  return { type: CLEAR_DELETED_MEETUP, payload: '' };
}

export function clearSelectedMeetup() {
  return { type: CLEAR_SELECTED_MEETUP, payload: '' };
}

export function deleteSelectedMeetup() {
  return { type: DELETE_SELECTED_MEETUP, payload: '' };
}

export function goToMeetup(id: string) {
  return { type: GO_TO_MEETUP, payload: id };
}

export function cancelGoToMeetup(id: string) {
  return { type: CANCEL_GO_TO_MEETUP, payload: id };
}
