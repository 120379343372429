import React, { memo } from 'react';

const ActiveFirstIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <title>Group 8</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" fill="none" fillRule="evenodd">
      <g id="1" transform="translate(-450.000000, -84.000000)">
        <g id="Group-4" transform="translate(450.000000, 84.000000)">
          <g id="Group-3">
            <g id="Group-8">
              <circle id="Oval" fill="#314AD7" cx="12" cy="12" r="12" />
              <polygon
                id="1"
                fill="#FFFFFF"
                points="13.7509766 16 12.0576172 16 12.0576172 9.47265625 10.0361328 10.0996094 10.0361328 8.72265625 13.5693359 7.45703125 13.7509766 7.45703125"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default memo(ActiveFirstIcon);
