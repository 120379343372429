import { FETCH_THEME_ERROR, FETCH_THEME_SUCCESS, RESET_CURRENT_THEME } from 'constants/actionConstants';

export default function ThemeReducer(state = {}, action: { type: string; name?: string; payload: any }) {
  switch (action.type) {
    case RESET_CURRENT_THEME: {
      return {};
    }
    case FETCH_THEME_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_THEME_ERROR:
      return { ...state };
    default:
      return state;
  }
}
