import * as React from 'react';
import { IntlShape } from 'react-intl';
import ru from 'date-fns/locale/ru';
import { registerLocale } from 'react-datepicker';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import IImageInfo, { InitImageInfo } from 'models/IImageInfo';
import { LOCALE } from 'constants/localization';
import { fieldNames } from 'constants/fieldNames';
import { placeLimit } from 'constants/inputValueLimits';
import { convertTimeAndDate } from 'utils/timeConverter';
import {
  getMaxDateFinish,
  getMaxDateStart,
  getMaxTimeFinish,
  getMaxTimeStart,
  getMinDateFinish,
  getMinDateStart,
  getMinTimeStart,
  getMinTimeFinish,
  getSelectedFinishDate,
  getSelectedStartDate,
} from 'utils/validateCalendar';
import CheckedIcon from 'assets/images/icons/checkedicon.svg';
import CustomInput from 'components/CustomInput/CustomInput';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import ImageDropzone from 'components/ImageDropzone/ImageDropzone';
import UploadedImage from 'components/UploadedImage/UploadedImage';

registerLocale(LOCALE.RU, ru);

interface IMeetupCreateAdditionalFormProps {
  intl: IntlShape;
  onChange: (name: string, value: string | Date | File | undefined) => void;
  changeImageInfo: (imageInfo: IImageInfo) => void;
  defaultImages: string[];
  defaultImageIds: string[];
  formInfo: {
    place: string;
    start: Date | undefined;
    finish: Date | undefined;
    picId: string | null;
  };
  imageInfo: IImageInfo;
}

interface IMeetupCreateAdditionalFormState {
  isFileUpload: boolean;
}

export default class MeetupCreateAdditionalForm extends React.Component<
  IMeetupCreateAdditionalFormProps,
  IMeetupCreateAdditionalFormState
> {
  public imageIds = this.props.defaultImageIds;

  public state = {
    isFileUpload: false,
  };

  public clearSelectedImage = () => {
    this.props.onChange(fieldNames.image, '');
    this.props.changeImageInfo(new InitImageInfo());
    this.setState({ isFileUpload: false });
  };

  public chooseImage = (selectedImageId: string) => {
    this.props.onChange(fieldNames.defaultImage, selectedImageId);
  };

  public componentDidMount(): void {
    if (this.props.formInfo.picId === null) {
      this.props.onChange(fieldNames.defaultImage, this.imageIds[0]);
    }
    if (this.props.imageInfo.imageSize > 0) {
      this.setState({
        isFileUpload: true,
      });
    }
  }

  public fileDropHandler = (files: File[]) => {
    if (files && files.length > 0) {
      this.setState({
        isFileUpload: true,
      });

      const imageInfo = {
        imageTitle: files[0].name,
        imageSize: files[0].size,
      };
      this.props.onChange(fieldNames.image, files[0]);
      this.props.changeImageInfo(imageInfo);
    }
  };

  public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.props.onChange(name, value);
  };

  public setStartDate = (date: Date | undefined) => {
    this.props.onChange(fieldNames.start, convertTimeAndDate(date));
  };

  public setEndDate = (date: Date | undefined) => {
    this.props.onChange(fieldNames.finish, convertTimeAndDate(date));
  };

  public render() {
    const { start, finish } = this.props.formInfo;
    const { formInfo, intl, defaultImages, imageInfo } = this.props;
    const { isFileUpload } = this.state;
    const value = {
      start: start ? new Date(start) : undefined,
      finish: finish ? new Date(finish) : undefined,
    };

    return (
      <div className="additional-form">
        <Card className="card card_form">
          <CardContent>
            <form>
              <DateTimePicker
                setStartDate={this.setStartDate}
                setEndDate={this.setEndDate}
                value={value}
                intl={intl}
                minDateStart={getMinDateStart()}
                maxDateStart={getMaxDateStart()}
                minTimeStart={getMinTimeStart(start!)}
                maxTimeStart={getMaxTimeStart()}
                minTimeFinish={getMinTimeFinish(start!, finish!)}
                maxTimeFinish={getMaxTimeFinish()}
                minDateFinish={getMinDateFinish(start!)}
                maxDateFinish={getMaxDateFinish()}
                selectedStartDateAndTime={getSelectedStartDate(start!)}
                selectedFinishDateAndTime={getSelectedFinishDate(start!, finish!)}
                isOver={false}
              />
              <CustomInput
                intl={intl}
                maxLength={placeLimit}
                label={intl.formatMessage({
                  id: 'meetupList.meetupCreate.labelPlace',
                })}
                className="place"
                multiline={true}
                onChange={this.onChange}
                rows={1}
                name={fieldNames.place}
                value={formInfo.place}
              />
              {!isFileUpload ? (
                <>
                  <div className="input-label">
                    {intl.formatMessage({ id: 'meetupList.meetupCreate.defaultImage.text' })}
                  </div>
                  <div className="image-block">
                    <div className="images-wrapper">
                      <div className="image-wrap">
                        <img
                          src={CheckedIcon}
                          alt="icon"
                          className={
                            this.props.formInfo.picId === this.props.defaultImageIds[0] ? 'active-icon' : 'hide-icon'
                          }
                        />
                        <img
                          src={defaultImages[0]}
                          alt="defaultImage"
                          className={
                            this.props.formInfo.picId === this.props.defaultImageIds[0]
                              ? 'active-image'
                              : 'default-image'
                          }
                          onClick={() => this.chooseImage(this.imageIds[0])}
                        />
                      </div>
                      <div className="image-wrap">
                        <img
                          src={CheckedIcon}
                          alt="icon"
                          className={
                            this.props.formInfo.picId === this.props.defaultImageIds[1] ? 'active-icon' : 'hide-icon'
                          }
                        />
                        <img
                          src={defaultImages[1]}
                          alt="defaultImage"
                          className={
                            this.props.formInfo.picId === this.props.defaultImageIds[1]
                              ? 'active-image'
                              : 'default-image'
                          }
                          onClick={() => this.chooseImage(this.imageIds[1])}
                        />
                      </div>
                    </div>
                    <ImageDropzone onDrop={this.fileDropHandler} />
                  </div>
                </>
              ) : (
                <UploadedImage clearSelectedImage={this.clearSelectedImage} imageInfo={imageInfo} />
              )}
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
