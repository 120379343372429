import IThemeDetails from 'models/IThemeDetails';
import { EDIT_THEME, EDIT_THEME_ERROR, EDIT_THEME_SUCCESS } from 'constants/actionConstants';

export function editTheme(theme: IThemeDetails) {
  return { type: EDIT_THEME, payload: theme };
}

export function editThemeSuccess() {
  return { type: EDIT_THEME_SUCCESS };
}

export function editThemeError(error: any) {
  return { type: EDIT_THEME_ERROR, payload: error };
}
