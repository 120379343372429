import * as React from 'react';
import Select from 'react-select';
import { IntlShape } from 'react-intl';
import { RawDraftContentState } from 'draft-js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IMeetupAuthor from 'models/IMeetupAuthor';
import IMeetupSpeaker from 'models/IMeetupSpeaker';
import { themeLimit, descriptionLimit } from 'constants/inputValueLimits';
import { fieldNames } from 'constants/fieldNames';
import { MeetupFieldsValuesType } from 'constants/customTypes';
import RichEditor from 'components/Editor/RichEditor';
import CustomInput from 'components/CustomInput/CustomInput';

interface IMeetupCreateRequiredFormProps {
  intl: IntlShape;
  onChange: (name: string, value: MeetupFieldsValuesType) => void;
  searchSpeaker: (query: string) => void;
  formInfo: {
    subject: string;
    author: IMeetupAuthor;
    description?: RawDraftContentState;
    availableSpeakers: IMeetupSpeaker[];
    speakerId: string;
  };
}

export class MeetupCreateRequiredForm extends React.Component<IMeetupCreateRequiredFormProps, {}> {
  public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.props.onChange(name, value);
  };

  public handleAuthorChange = (event: any) => {
    this.props.onChange(fieldNames.author, event);
    this.props.onChange(fieldNames.speaker, event.value.id);
  };

  public render() {
    const input = () => ({
      alignItems: 'center',
      display: 'flex',
      boxShadow: 'none',
      fontSize: '0.875rem',
    });

    const customStyles = {
      option: (provided: any) => ({
        ...provided,
        textAlign: 'left',
        color: '#5b6887',
        fontSize: '0.875rem',
      }),
      placeholder: () => ({
        color: '#aab6ca',
        position: 'absolute',
        paddingLeft: '2px',
      }),
      noOptionsMessage: () => ({
        fontSize: '0.875rem',
        color: '#5b6887',
        padding: '7px',
      }),
      control: (base: any, state: any) => ({
        ...input(),
        ...base,
        background: 'rgba(244, 246, 252, 0.4)',
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? '#8065ec' : base.borderColor,
        paddingLeft: '7px',
        ':hover': {
          borderColor: '#8065ec',
        },
        minHeight: '40px',
      }),
      singleValue: () => ({
        color: '#5b6887',
      }),
    };

    const { formInfo, intl } = this.props;
    return (
      <div className="required-form">
        <Card className="card card_form">
          <CardContent>
            <form>
              <CustomInput
                intl={intl}
                maxLength={themeLimit}
                label={intl.formatMessage({
                  id: 'meetupList.meetupCreate.labelName',
                })}
                className="theme"
                multiline={false}
                onChange={this.onChange}
                rows={1}
                name={fieldNames.subject}
                value={formInfo.subject}
              />
              <div className="input-label">
                {intl.formatMessage({
                  id: 'meetupList.meetupCreate.labelSpeaker',
                })}
              </div>
              <Select
                options={formInfo.availableSpeakers.map(author => ({
                  label: `${author.name} ${author.surname}`,
                  value: author,
                }))}
                styles={customStyles}
                className="speaker"
                value={formInfo.author.id !== '' ? formInfo.author : null}
                placeholder={''}
                noOptionsMessage={() =>
                  intl.formatMessage({
                    id: 'meetupList.meetupCreate.speaker.not.found',
                  })
                }
                onChange={this.handleAuthorChange}
                onInputChange={this.props.searchSpeaker}
                name={fieldNames.author}
              />
              <RichEditor
                intl={intl}
                readonly={false}
                onChange={this.props.onChange}
                maxLength={descriptionLimit}
                value={formInfo.description}
              />
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
