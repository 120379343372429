import { SWITCH_LOCALE } from 'constants/actionConstants';

export interface ILocaleStore {
  currentLocale: 'ru';
}

const initState: ILocaleStore = {
  currentLocale: 'ru'
};

export default function localeReducer(
  state: ILocaleStore = initState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case SWITCH_LOCALE:
      return { ...state, currentLocale: action.payload.locale };
    default:
      return state;
  }
}
