import * as React from 'react';

import ClockIcon from 'assets/images/icons/ClockIcon';
import CalendarIcon from 'assets/images/icons/CalendarIcon';
import CustomTimeDisplay from 'components/CustomTimeDisplay/CustomTimeDisplay';
import RichEditor from 'components/Editor/RichEditor';
import UserAvatar from 'containers/UserAvatar/UserAvatar';
import IMeetupDetails from 'models/IMeetupDetails';
import PointIcon from 'assets/images/icons/PointIcon';
import { FormattedMessage, FormattedDate, IntlShape, injectIntl } from 'react-intl';
import { descriptionLimit } from 'constants/inputValueLimits';

interface IMeetupEditPreviewProps {
  intl: IntlShape;
  currentMeetup: IMeetupDetails;
  disablePreviewMode: () => void;
}

class MeetupEditPreview extends React.Component<IMeetupEditPreviewProps, {}> {
  public render() {
    const { subject, start, place, author, speakers, description, image, tmpImage, finish } = this.props.currentMeetup;
    const { intl } = this.props;
    const speaker = speakers[0] || author;

    return (
      <div className="meetup-list__wrapper">
        <div className="meetup-list">
          <h1 className="meetup-list__header__heading margin-bottom-50">
            <FormattedMessage id="meetupList.meetupPreview.meetupTitle" />
          </h1>
          <div className="card card__no-padding">
            <div className="preview__topic-img-wrapper">
              <img className="preview__topic-img" src={tmpImage || image!} alt="meetup-example" />
            </div>
            <h2 className="preview__topic-name preview__pb30">{subject}</h2>
          </div>
          <h3 className="card__heading">
            <FormattedMessage id="meetupList.meetupPreview.timeAndPlace" />
          </h3>
          <ul className="card card__time-n-place">
            <li className="preview__time-n-place-item">
              <CalendarIcon />
              {start ? (
                start ? (
                  <FormattedDate weekday="long" value={start} year="numeric" day="numeric" month="long" />
                ) : (
                  '-'
                )
              ) : null}
            </li>
            <li className="preview__time-n-place-item">
              <ClockIcon />
              <CustomTimeDisplay start={start} finish={finish} />
            </li>
            <li className="preview__time-n-place-item">
              <PointIcon />
              {place ? place : '-'}
            </li>
          </ul>

          <h3 className="card__heading">
            <FormattedMessage id="meetupList.meetupPreview.speaker" />
          </h3>
          <div className="card card__speaker">
            <UserAvatar user={speaker} round={true} size="40" />
            <p className="preview__speaker-name">{`${speaker.name}
            ${speaker.surname}`}</p>
          </div>

          <h3 className="card__heading">
            <FormattedMessage id="meetupList.meetupPreview.description" />
          </h3>
          <div className="card">
            {description && description.blocks && description.blocks.length > 0 ? (
              <RichEditor intl={intl} value={description!} readonly={true} maxLength={descriptionLimit} />
            ) : null}
          </div>
          <div className="card card__controls card__actions">
            <button onClick={this.props.disablePreviewMode} className="button go-back-button">
              <FormattedMessage id="meetupList.meetupPreview.button.goBack" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(MeetupEditPreview);
