import { FETCH_THEMES_LIST, FETCHED_THEMES_LIST_ERROR, FETCHED_THEMES_LIST_SUCCESS } from 'constants/actionConstants';
import IMeetupShort from 'models/IMeetupShort';

export function fetchThemesList() {
  return { type: FETCH_THEMES_LIST };
}

export function fetchedThemesListSuccess(themes: IMeetupShort[]) {
  return { type: FETCHED_THEMES_LIST_SUCCESS, payload: themes };
}

export function fetchedThemesListError(error: {}) {
  return { type: FETCHED_THEMES_LIST_ERROR, payload: error };
}
