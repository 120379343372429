import {
  FETCH_NEWS,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_ERROR,
  RESET_CURRENT_NEWS
} from 'constants/actionConstants';
import INewsDetails from 'models/INewsDetails';

export function fetchNews(id: string) {
  return { type: FETCH_NEWS, payload: id };
}

export function fetchNewsSuccess(news: INewsDetails) {
  return { type: FETCH_NEWS_SUCCESS, payload: news };
}

export function fetchNewsError(error: {}) {
  return {
    type: FETCH_NEWS_ERROR,
    payload: error
  };
}

export function resetNewsData() {
  return { type: RESET_CURRENT_NEWS };
}
