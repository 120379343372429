import * as React from 'react';
import GooeyRing from 'assets/images/icons/GooeyRing';
interface ILoaderProps {
  isLoading: boolean;
}

class GooeyLoader extends React.Component<ILoaderProps> {
  public render() {
    const { isLoading } = this.props;
    return <>{isLoading ? <GooeyRing /> : this.props.children}</>;
  }
}

export default GooeyLoader;
