import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ReactDropzone from 'react-dropzone';
import { NotificationManager } from 'react-notifications';
import TranslateService from 'services/TranslateService';
import uploadImage from 'assets/images/upload.png';
import { maxFileSizeInBytes, maxFilesQuantity } from 'constants/fileConstants';
import IFile from 'models/IFile';

interface IFileDropzoneProps {
  onDrop: (files: File[]) => void;
  disabled?: boolean;
  edit?: boolean;
  files: IFile[];
}

class FileDropzone extends React.Component<IFileDropzoneProps, {}> {
  public handleDropRejected = (files: File[]) => {
    if (this.props.files.length + files.length > maxFilesQuantity) {
      NotificationManager.error(TranslateService.translate('meetupList.meetupPreview.fileUpload.cautionNumber'));
    }
    files.forEach(file => {
      if (file.size > maxFileSizeInBytes) {
        NotificationManager.error(TranslateService.translate('meetupList.meetupPreview.fileUpload.cautionSize'));
      }
    });
  };

  public render() {
    const { onDrop, disabled = false, edit = false } = this.props;

    return (
      <ReactDropzone
        onDrop={onDrop}
        maxSize={maxFileSizeInBytes}
        onDropRejected={this.handleDropRejected}
        disabled={disabled}
        multiple={true}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={classNames('image-dropzone', { 'image-dropzone--edit': edit })}>
            <div {...getRootProps()} className="image-dropzone__upload-container">
              <input {...getInputProps()} />
              <img src={uploadImage} alt="upload" className="image-dropzone__upload-image" />
              <p className="image-dropzone__explanation">
                <FormattedMessage id="meetupList.meetupPreview.fileUpload.text" />
                <span>
                  <FormattedMessage id="meetupList.meetupPreview.fileUpload.uploadText" />
                </span>
              </p>
              <p className="image-dropzone__warning">
                <FormattedMessage id="meetupList.meetupPreview.fileUpload.cautionNumber" />
              </p>
              <p className="image-dropzone__warning">
                <FormattedMessage id="meetupList.meetupPreview.fileUpload.cautionSize" />
              </p>
            </div>
          </section>
        )}
      </ReactDropzone>
    );
  }
}

export default FileDropzone;
