import IMeetupDetails from 'models/IMeetupDetails';
import { combineReducers, ReducersMapObject } from 'redux';
import localeReducer, { ILocaleStore } from 'containers/IntlWrapComponent/IntlWrapComponent.reducer';
import meetupReducer from 'containers/Meetup/Meetup.reducer';
import themeReducer from 'containers/Theme/Theme.reducer';
import meetupsReducer, { IMeetupStore } from 'containers/MeetupList/MeetupList.reducer';
import userReducer, { IUserStore } from 'containers/Auth/Auth.reducer';
import newsListReducer, { INewsStore } from 'containers/NewsList/NewsList.reducer';
import newsReducer from 'containers/News/News.reducer';
import INewsDetails from 'models/INewsDetails';
import initialMeetupReducer from 'containers/Meetup/InitialMeetup.reducer';
import initialNewsReducer from 'containers/News/InitialNews.reducer';
import IThemeDetails from 'models/IThemeDetails';
import privateRouteReducer, { IAccessRoute } from 'containers/PrivateRoute/PrivateRoute.reducer';
import userPhotoReducer, { IUserPhoto } from 'containers/UserAvatar/UserAvatar.reducer';
import notificationReducer, { INotificationStore } from 'containers/NotificationList/NotificationList.reducer';
import { IMemoStore, default as memoReducer } from '../containers/Memo/Memo.reducer';

export interface IStore {
  locale: ILocaleStore;
  userInfo: IUserStore;
  meetups: IMeetupStore;
  news: INewsStore;
  currentNews: INewsDetails;
  currentMeetup: IMeetupDetails;
  currentTheme: IThemeDetails;
  initialMeetupData: IMeetupDetails;
  initialNewsData: INewsDetails;
  accessRoute: IAccessRoute;
  usersPhoto: IUserPhoto[];
  notifications: INotificationStore;
  memoInfo: IMemoStore;
}

export default combineReducers({
  locale: localeReducer,
  userInfo: userReducer,
  meetups: meetupsReducer,
  news: newsListReducer,
  currentNews: newsReducer,
  currentMeetup: meetupReducer,
  currentTheme: themeReducer,
  initialMeetupData: initialMeetupReducer,
  initialNewsData: initialNewsReducer,
  accessRoute: privateRouteReducer,
  usersPhoto: userPhotoReducer,
  notifications: notificationReducer,
  memoInfo: memoReducer,
} as ReducersMapObject<IStore, any>);
