import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IntlShape, injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';

import { fetchTheme, resetThemeData } from 'containers/Theme/Theme.actions';
import { IStore } from 'store/rootReducer';
import IThemeDetails from 'models/IThemeDetails';
import IParticipant from 'models/IParticipant';
import IAppUser from 'models/IAppUser';
import DeleteModal from 'components/Modal/DeleteModal';
import CustomButton from 'components/CustomButton/CustomButton';
import RichEditor from 'components/Editor/RichEditor';
import UserAvatar from 'containers/UserAvatar/UserAvatar';
import { RouteUrls } from 'constants/RouteUrls';
import { maxVisibleParticipants } from 'constants/maxVisibleParticipants';
import { mediaTypes } from 'constants/mediaTypes';
import { triggerTypes } from 'constants/triggerTypes';
import { deleteTheme } from '../ThemeDelete/ThemeDelete.actions';
import { approveTheme } from '../ThemeApprove/ThemeApprove.actions';
import { likeTheme, dislikeTheme } from '../ThemeLike/ThemeLike.actions';
import { replaceUrlParams } from 'utils/routeUrls';
import { changeSelectedMeetup } from 'containers/MeetupList/MeetupList.actions';
import { canApproveTheme, canDeleteTheme, canEditTheme, isChief } from 'utils/userRules';
import history from '../../../utils/history';

interface IThemePreviewProps {
  fetchTheme: (id: string) => void;
  deleteTheme: (id: string) => void;
  approveTheme: (id: string) => void;
  likeTheme: (id: string) => void;
  dislikeTheme: (id: string) => void;
  changeSelectedMeetup: (id: string) => void;
  currentTheme: IThemeDetails;
  resetThemeData: () => void;
  user: IAppUser;
  match: {
    params: {
      id: string;
    };
  };
  intl: IntlShape;
}

interface IThemePreviewState {
  saveSelectedMeetup: boolean;
}

class ThemePreview extends React.Component<IThemePreviewProps, IThemePreviewState> {
  state = {
    saveSelectedMeetup: false,
  };

  componentDidMount(): void {
    this.props.fetchTheme(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: Readonly<IThemePreviewProps>): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.fetchTheme(this.props.match.params.id);
    }
  }

  public componentWillUnmount(): void {
    this.props.resetThemeData();
    this.state.saveSelectedMeetup && this.props.changeSelectedMeetup(this.props.match.params.id);
  }

  getParticipantsView = (participants: IParticipant[]) => {
    return participants.map((participant, index) => {
      return index < maxVisibleParticipants ? (
        <span key={participant.id} title={`${participant.name} ${participant.surname}`}>
          <UserAvatar user={participant} round={true} size="48" />
        </span>
      ) : index === maxVisibleParticipants ? (
        <span key={participant.id}>
          <Avatar color="#AAB6CA" round={true} size="48" value={`+${participants.length - maxVisibleParticipants}`} />
        </span>
      ) : null;
    });
  };

  deleteTheme = () => {
    this.props.deleteTheme(this.props.currentTheme.id);
    this.setSaveSelectedMeetup(false);
    history.push(RouteUrls.Themes);
  };

  approveTheme = () => {
    this.props.approveTheme(this.props.currentTheme.id);
    this.setSaveSelectedMeetup(false);
  };

  setSaveSelectedMeetup = (value: boolean) => {
    this.setState({ saveSelectedMeetup: value });
  };

  onSupport(): void {
    this.props.currentTheme.liked
      ? this.props.dislikeTheme(this.props.currentTheme.id)
      : this.props.likeTheme(this.props.currentTheme.id);
  }

  render() {
    const { id, subject, author, description, liked, participants, isOver } = this.props.currentTheme;
    const { user, intl } = this.props;
    const canEdit = canEditTheme(author, user);
    const canDelete = canDeleteTheme(author, user);
    const canApprove = canApproveTheme(user);
    return (
      <div className="meetup-list__wrapper">
        {id && (
          <div className="meetup-list">
            <h1 className="meetup-list__header__heading margin-bottom-24 text-align-center">
              <FormattedMessage id={`meetupList.meetupPreview.topicTitle`} />
            </h1>
            <h3 className="card__heading">
              <FormattedMessage id="meetupList.meetupPreview.topicName" />
            </h3>
            <div className="card card__no-padding">
              <h2 className="preview__topic-name preview__pb30 word-wrap">{subject}</h2>
            </div>

            <h3 className="card__heading">
              <FormattedMessage id={`meetupList.meetupPreview.author`} />
            </h3>
            <div className="card card__speaker">
              <UserAvatar user={author} round={true} size="40" />
              <p className="preview__speaker-name">{`${author.name} ${author.surname}`}</p>
            </div>
            <h3 className="card__heading">
              <FormattedMessage id="meetupList.meetupPreview.description" />
            </h3>
            <div className="card card__description">
              {description && description.blocks && description.blocks.length > 0 ? (
                <RichEditor intl={intl} readonly value={description} />
              ) : null}
            </div>
            {participants && participants.length > 0 && (
              <>
                <h3 className="card__heading">
                  <FormattedMessage id="meetupList.meetupPreview.topicParticipants" />
                </h3>
                <div className="card card__participants  ">{this.getParticipantsView(participants)}</div>
              </>
            )}
            <div className="card card__controls card__actions">
              <Link to={RouteUrls.Themes}>
                <button className="button xs_button go-back-button" onClick={() => this.setSaveSelectedMeetup(true)}>
                  <FormattedMessage id="meetupList.meetupPreview.button.goBack" />
                </button>
              </Link>
              <div className="card__main-actions">
                {canDelete && (
                  <DeleteModal
                    handleDelete={this.deleteTheme}
                    mediaType={mediaTypes.Theme}
                    triggerType={triggerTypes.Button}
                    isConfirmationNeeded={true}
                  />
                )}
                {canEdit && (
                  <Link className="button__no-outline" to={replaceUrlParams(RouteUrls.EditTheme, { id })}>
                    <CustomButton
                      className={'button m_button button_outlined-violet margin-left-5'}
                      label={intl.formatMessage({ id: 'meetupList.meetupPreview.button.edit' })}
                      variant="outlined"
                    />
                  </Link>
                )}
                <CustomButton
                  onClick={() => this.onSupport()}
                  className={
                    !liked
                      ? !isChief(user)
                        ? 'button m_button publish-button button_contained-violet margin-left-5'
                        : 'button m_button button_outlined-violet margin-left-5'
                      : 'button m_button dislike-button margin-left-5'
                  }
                  label={
                    liked
                      ? intl.formatMessage({ id: 'meetupList.meetupPreview.button.supported' })
                      : intl.formatMessage({ id: 'meetupList.meetupPreview.button.support' })
                  }
                  variant="outlined"
                />
                {canApprove && (
                  <CustomButton
                    className={
                      isChief
                        ? 'button s_button publish-button button_contained-violet margin-left-5'
                        : 'button s_button margin-left-5'
                    }
                    onClick={this.approveTheme}
                    label={intl.formatMessage({ id: 'themeList.themePreview.button.approve' })}
                    disabled={isOver}
                    variant={'contained'}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(store: IStore) {
  return {
    currentTheme: store.currentTheme,
    user: store.userInfo.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchTheme: bindActionCreators(fetchTheme, dispatch),
    deleteTheme: bindActionCreators(deleteTheme, dispatch),
    approveTheme: bindActionCreators(approveTheme, dispatch),
    likeTheme: bindActionCreators(likeTheme, dispatch),
    dislikeTheme: bindActionCreators(dislikeTheme, dispatch),
    resetThemeData: bindActionCreators(resetThemeData, dispatch),
    changeSelectedMeetup: bindActionCreators(changeSelectedMeetup, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ThemePreview),
);
