import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { IStore } from '../../../store/rootReducer';
import { getMemo } from './Memo.actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import IMemo from '../../../models/IMemo';
import { RouteUrls } from '../../../constants/RouteUrls';
import { Link } from 'react-router-dom';
import RichEditor from '../../../components/Editor/RichEditor';

interface IMemoPreviewProps {
  memoInfo: IMemo[];
  getMemo: () => void;
  intl: IntlShape;
}

class MemoPreview extends React.Component<IMemoPreviewProps> {
  componentDidMount() {
    this.props.getMemo();
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="meetup-list__wrapper">
        <div className="meetup-list">
          <h1 className="meetup-list__header__heading margin-bottom-24 text-align-center">
            <FormattedMessage id="memo.memoPreview.topicTitle" />
          </h1>

          <div className="card">
            {this.props.memoInfo.map((memoItem: IMemo, index) => {
              return (
                <div key={`_${index}`} className="memo-preview__item-wrap">
                  <h3 className="memo-preview__topic-name">{memoItem.subject}</h3>
                  <div className="memo-preview__description">
                    <RichEditor intl={intl} readonly value={memoItem.body} />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="card card__controls card__actions">
            <Link to={RouteUrls.AdminPanel}>
              <button className="button button_outlined-violet">
                <FormattedMessage id="memo.memoPreview.button.goBack" />
              </button>
            </Link>
            <div className="card__main-actions">
              <Link to={RouteUrls.EditMemo}>
                <button className="button xl_button button_outlined-violet">
                  <FormattedMessage id="memo.memoPreview.button.edit" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    memoInfo: state.memoInfo.memoInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getMemo: bindActionCreators(getMemo, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MemoPreview),
);
