import * as React from 'react';
import { RouteUrls } from 'constants/RouteUrls';
import { Link } from 'react-router-dom';
import CustomButton from 'components/CustomButton/CustomButton';
import history from 'utils/history';
import { IStore } from 'store/rootReducer';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { canCreateDraftMeetup } from 'utils/userRules';
import IAppUser from 'models/IAppUser';

interface IListCounterProps {
  intl: IntlShape;
  user: IAppUser;
  selectedMeetup: string;
  count: number;
  label: string;
}

class MeetupListCounter extends React.Component<IListCounterProps, {}> {
  render() {
    const { intl, user } = this.props;
    return (
      <div className="meetup-list__counter">
        <p>
          {`${intl.formatMessage({
            id: this.props.label,
          })} ${this.props.count}`}
        </p>

        {history.location.pathname === RouteUrls.Themes && (
          <Link to={RouteUrls.CreateTheme}>
            <CustomButton
              className="button button_outlined-violet"
              variant="outlined"
              label={intl.formatMessage({ id: 'themeList.button.createTheme' })}
            />
          </Link>
        )}

        {canCreateDraftMeetup(user) && (
          <Link to={RouteUrls.CreateMeetup}>
            <CustomButton
              className="button button_outlined-violet"
              variant="outlined"
              label={intl.formatMessage({ id: 'meetupList.button.createMeetup' })}
            />
          </Link>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    user: state.userInfo.user,
    selectedMeetup: state.meetups.selectedMeetup,
  };
}

export default injectIntl(connect(mapStateToProps)(MeetupListCounter));
