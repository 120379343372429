import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import Select from 'react-select';
import { Card, CardActions, CardContent } from '@material-ui/core';
import { IStore } from 'store/rootReducer';
import IAppUser from 'models/IAppUser';
import IMeetupDetails from 'models/IMeetupDetails';
import IImageInfo, { InitImageInfo } from 'models/IImageInfo';
import { descriptionLimit, placeLimit, themeLimit } from 'constants/inputValueLimits';
import { RouteUrls } from 'constants/RouteUrls';
import { fieldNames } from 'constants/fieldNames';
import { MeetupFieldsValuesType } from 'constants/customTypes';
import { fileToBase64 } from 'utils/convertToBase64';
import { convertTimeAndDate } from 'utils/timeConverter';
import {
  getMaxDateFinish,
  getMaxDateStart,
  getMaxTimeFinish,
  getMaxTimeStart,
  getMinDateFinish,
  getMinDateStart,
  getMinTimeFinish,
  getMinTimeStart,
  getSelectedFinishDate,
  getSelectedStartDate,
} from 'utils/validateCalendar';
import { replaceUrlParams } from 'utils/routeUrls';
import { fetchMeetup } from 'containers/Meetup/MeetupPreview/MeetupPreview.actions';
import {
  getDefaultImagesMeetup,
  resetMeetupData,
  searchUser,
  updateMeetupData,
} from 'containers/Meetup/MeetupCreate/MeetupCreate.actions';
import { editMeetup } from 'containers/Meetup/MeetupEdit/MeetupEdit.actions';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import GooeyLoader from 'components/GooeyLoader/GooeyLoader';
import ImageDropzone from 'components/ImageDropzone/ImageDropzone';
import UploadedImage from 'components/UploadedImage/UploadedImage';
import RichEditor from 'components/Editor/RichEditor';
import MeetupEditPreview from './MeetupEditPreview/MeetupEditPreview';
import { isFieldEditable } from './MeetupEdit.rules';
import { isNoEmptyDescription } from 'utils/draft.utils';
import TrashBinIcon from 'assets/images/icons/TrashBinIcon';
import DialogModal from '../../../components/Modal/DialogModal';

interface IMeetupEditProps {
  intl: IntlShape;
  match: {
    params: {
      id: string;
    };
  };
  initialMeetupData: IMeetupDetails;
  currentMeetup: IMeetupDetails;
  fetchMeetup: (id: string) => {};
  editMeetup: (id: string, currentMeetup: IMeetupDetails) => {};
  updateMeetupData: (payload: { name: string; value: MeetupFieldsValuesType; index?: number }) => void;
  resetMeetupData: () => {};
  user: IAppUser;
  searchSpeaker: (query: string) => {};
  defaultImages: string[];
  defaultImageIds: string[];
  getDefaultImages: () => {};
}

interface IMeetupEditState {
  isPreviewMode: boolean;
  image: File | null;
  tmpImage: string | null;
  imageInfo: IImageInfo;
  isFileUpload: boolean;
  isDialogVisible: boolean;
}

class MeetupEdit extends React.Component<IMeetupEditProps, IMeetupEditState> {
  public state = {
    isPreviewMode: false,
    isFileUpload: false,
    image: null,
    tmpImage: null,
    imageInfo: new InitImageInfo(),
    isDialogVisible: false,
  };

  public componentDidMount(): void {
    const meetupId = this.props.match.params.id;
    this.props.fetchMeetup(meetupId);
    this.props.searchSpeaker('');
    this.props.getDefaultImages();
  }

  public editMeetup = () => {
    this.props.editMeetup(this.props.currentMeetup.id, this.props.currentMeetup);
  };

  public clearSelectedImage = () => {
    this.onChange(fieldNames.tmpImage, '');
    this.onChange(fieldNames.tmpImageOrig, '');
    this.setState({ isFileUpload: false });
  };

  public componentWillUnmount(): void {
    this.props.resetMeetupData();
  }

  public onChange = (name: string, value: MeetupFieldsValuesType, index?: number) => {
    this.props.updateMeetupData({ name, value, index });
  };

  public onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.onChange(name, value);
  };

  public setStartDate = (date: Date | undefined) => {
    this.onChange(fieldNames.start, convertTimeAndDate(date));
  };

  public setEndDate = (date: Date | undefined) => {
    this.onChange(fieldNames.finish, convertTimeAndDate(date));
  };

  public enablePreviewMode = () => {
    this.setState({
      isPreviewMode: true,
    });
  };

  public disablePreviewMode = () => {
    this.setState({
      isPreviewMode: false,
    });
  };

  public fileDropHandler = (files: File[]) => {
    if (files && files.length > 0) {
      this.setState({
        isFileUpload: !this.state.isFileUpload,
        imageInfo: {
          imageTitle: files[0].name,
          imageSize: files[0].size,
        },
      });
      this.onChange('tmpImageOrig', files[0]);
      fileToBase64(files[0]).then((val: any) => this.onChange(fieldNames.tmpImage, val));
    }
  };

  public handleSpeakerChange = (event: any) => {
    this.onChange('speakers', event.value, 0);
    this.onChange('speakerId', event.value.id);
  };

  public disableCheck = (initialMeetupData: IMeetupDetails, currentMeetup: IMeetupDetails) => {
    return (
      !this.props.currentMeetup.subject ||
      this.props.currentMeetup.author.id === '' ||
      JSON.stringify(initialMeetupData) === JSON.stringify(currentMeetup)
    );
  };

  public searchSpeaker = (query: string) => {
    this.props.searchSpeaker(query);
  };

  private setDefaultImage = () => {
    this.onChange('image', this.props.currentMeetup.defaultImages[0]);
    this.onChange('tmpImage', this.props.currentMeetup.defaultImages[0]);
    this.onChange('picId', this.props.currentMeetup.defaultImageIds[0]);
  };

  public openDialog = () => {
    this.setState({ isDialogVisible: true });
  };

  public closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  public render() {
    const {
      author,
      subject,
      start,
      finish,
      place,
      speakers,
      availableSpeakers,
      description,
      image,
      picId,
      defaultImageIds,
    } = this.props.currentMeetup;
    const { isFileUpload, imageInfo } = this.state;
    const speaker = speakers[0] || author;
    const selectedSpeaker = {
      label: `${speaker.name} ${speaker.surname}`,
      value: {
        id: speaker.id,
        name: speaker.name,
        surname: speaker.surname,
      },
    };

    const input = () => ({
      alignItems: 'center',
      display: 'flex',
      boxShadow: 'none',
      fontSize: '0.875rem',
    });

    const customStyles = {
      option: (provided: any) => ({
        ...provided,
        textAlign: 'left',
        color: '#5b6887',
        fontSize: '0.875rem',
      }),
      noOptionsMessage: () => ({
        fontSize: '0.875rem',
        color: '#5b6887',
        padding: '7px',
      }),
      control: (base: any, state: any) => ({
        ...input(),
        ...base,
        background: 'rgba(244, 246, 252, 0.4)',
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? '#8065ec' : base.borderColor,
        paddingLeft: '7px',
        ':hover': {
          borderColor: '#8065ec',
        },
      }),
      singleValue: () => ({
        color: '#5b6887',
      }),
    };
    const meetupId = this.props.match.params.id;
    const { intl, user, initialMeetupData, currentMeetup } = this.props;
    const { roles } = user;
    const { isPreviewMode, isDialogVisible } = this.state;
    const datePickerValue = {
      start: start ? new Date(start) : undefined,
      finish: finish ? new Date(finish) : undefined,
    };

    return isPreviewMode ? (
      <MeetupEditPreview disablePreviewMode={this.disablePreviewMode} currentMeetup={this.props.currentMeetup} />
    ) : (
      <div className="page-content meetup-edit">
        <h1 className="page-heading">
          <FormattedMessage id="meetup.edit.title" />
        </h1>
        <Card className="card card_form">
          <CardContent>
            <form>
              <DateTimePicker
                setStartDate={this.setStartDate}
                setEndDate={this.setEndDate}
                value={datePickerValue}
                intl={intl}
                minDateStart={getMinDateStart()}
                maxDateStart={getMaxDateStart()}
                minTimeStart={getMinTimeStart(start!)}
                maxTimeStart={getMaxTimeStart()}
                minTimeFinish={getMinTimeFinish(start!, finish!)}
                maxTimeFinish={getMaxTimeFinish()}
                minDateFinish={getMinDateFinish(start!)}
                maxDateFinish={getMaxDateFinish()}
                selectedStartDateAndTime={getSelectedStartDate(start!)}
                selectedFinishDateAndTime={getSelectedFinishDate(start!, finish!)}
                disabled={!isFieldEditable(fieldNames.start, currentMeetup, user.id, roles)}
                isOver={currentMeetup.isOver}
              />
              <CustomInput
                intl={intl}
                maxLength={placeLimit}
                label={intl.formatMessage({
                  id: 'meetupList.meetupCreate.labelPlace',
                })}
                className="theme"
                disabled={!isFieldEditable(fieldNames.place, currentMeetup, user.id, roles)}
                multiline={false}
                onChange={this.onChangeValue}
                rows={1}
                name="place"
                value={place}
              />
              <div className="input-label">
                {intl.formatMessage({
                  id: 'meetupList.meetupCreate.labelSpeaker',
                })}
              </div>
              <Select
                options={availableSpeakers.map(speaker => ({
                  label: `${speaker.name} ${speaker.surname}`,
                  value: speaker,
                }))}
                onChange={this.handleSpeakerChange}
                styles={customStyles}
                labelField={intl.formatMessage({
                  id: 'meetupList.meetupCreate.labelSpeaker',
                })}
                placeholder={intl.formatMessage({
                  id: 'meetupList.meetupCreate.placeholderSpeaker',
                })}
                className="speaker"
                value={selectedSpeaker}
                noOptionsMessage={() =>
                  intl.formatMessage({
                    id: 'meetupList.meetupCreate.speaker.not.found',
                  })
                }
                isDisabled={!isFieldEditable(fieldNames.speaker, currentMeetup, user.id, roles)}
                isSearchable={true}
                onInputChange={this.searchSpeaker}
                name="speaker"
              />
              <CustomInput
                intl={intl}
                maxLength={themeLimit}
                label={intl.formatMessage({
                  id: 'meetupList.meetupCreate.labelName',
                })}
                className="theme"
                disabled={!isFieldEditable(fieldNames.subject, currentMeetup, user.id, roles)}
                multiline={false}
                onChange={this.onChangeValue}
                rows={1}
                name="subject"
                value={subject}
              />

              {description && description.blocks && description.blocks.length > 0 ? (
                <RichEditor
                  intl={intl}
                  value={description!}
                  readonly={!isFieldEditable(fieldNames.description, currentMeetup, user.id, roles)}
                  onChange={this.onChange}
                  maxLength={descriptionLimit}
                />
              ) : null}
              {!isFileUpload ? (
                <>
                  <div className="input-label input-label__description">
                    {intl.formatMessage({
                      id: 'image.text',
                    })}
                  </div>
                  <div className="meetup-edit__dropzone-wrapper">
                    {image ? (
                      <div className="meetup-edit__container">
                        <img className="meetup-edit__preview__topic-img" src={image!} alt="meetup-example" />
                        {picId !== defaultImageIds[0] &&
                          isFieldEditable(fieldNames.image, currentMeetup, user.id, roles) && (
                            <div className="meetup-edit__button-container" onClick={() => this.setDefaultImage()}>
                              <TrashBinIcon width={50} height={50} colour={'white'} />
                            </div>
                          )}
                      </div>
                    ) : (
                      <GooeyLoader isLoading={true} />
                    )}

                    <ImageDropzone
                      onDrop={this.fileDropHandler}
                      edit={true}
                      disabled={!isFieldEditable(fieldNames.image, currentMeetup, user.id, roles)}
                    />
                  </div>
                </>
              ) : (
                <UploadedImage clearSelectedImage={this.clearSelectedImage} imageInfo={imageInfo} />
              )}
            </form>
          </CardContent>
        </Card>
        <Card className="card card_controls card_controls-form">
          <CardActions className="card__actions">
            <CustomButton
              className="button go-back-button"
              variant="outlined"
              link={replaceUrlParams(RouteUrls.PreviewEvent, { id: meetupId })}
              label={intl.formatMessage({ id: 'meetup.edit.button.cancel' })}
            />
            <div>
              <CustomButton
                className="button button_outlined-violet"
                variant="outlined"
                onClick={this.enablePreviewMode}
                disabled={this.disableCheck(initialMeetupData, currentMeetup)}
                label={intl.formatMessage({ id: 'meetup.edit.button.preview' })}
              />
              <CustomButton
                className="button button_contained-violet margin-left-10"
                variant="contained"
                onClick={() => (currentMeetup.subject.length > 500 ? this.openDialog() : this.editMeetup())}
                disabled={
                  this.disableCheck(initialMeetupData, currentMeetup) ||
                  !isNoEmptyDescription(currentMeetup.description)
                }
                label={intl.formatMessage({ id: 'meetup.edit.button.save' })}
              />
            </div>
          </CardActions>
        </Card>
        <DialogModal
          handleConfirm={() => {}}
          handleClose={this.closeDialog}
          titleTextId="editor.subjectValidation.warningTitle"
          textId="editor.subjectValidation.warningText"
          open={isDialogVisible}
          showButtons={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    currentMeetup: state.currentMeetup,
    initialMeetupData: state.initialMeetupData,
    user: state.userInfo.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchMeetup: bindActionCreators(fetchMeetup, dispatch),
    editMeetup: bindActionCreators(editMeetup, dispatch),
    updateMeetupData: bindActionCreators(updateMeetupData, dispatch),
    resetMeetupData: bindActionCreators(resetMeetupData, dispatch),
    searchSpeaker: bindActionCreators(searchUser, dispatch),
    getDefaultImages: bindActionCreators(getDefaultImagesMeetup, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MeetupEdit),
);
