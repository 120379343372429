import {
  EditorState,
  ContentBlock,
  RawDraftContentState,
  convertToRaw,
  convertFromRaw,
  RawDraftContentBlock,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { List } from 'immutable';
import { NotificationManager } from 'react-notifications';
import { stateFromHTML } from 'draft-js-import-html';
import DOMPurify from 'dompurify';

/**
 * Function returns collection of currently selected blocks.
 */
export function getSelectedBlocksMap(editorState: EditorState): any {
  const selectionState = editorState.getSelection();
  const stateContent = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = stateContent.getBlockMap();
  const selectedBlocksMap = blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
  return selectedBlocksMap;
}

/**
 * Function returns collection of currently selected blocks.
 */
export function getSelectedBlocksList(editorState: EditorState): List<ContentBlock> {
  return getSelectedBlocksMap(editorState).toList();
}

export const getLengthWarning = (maxLength: number): string =>
  `Отформатированный текст превышает ${maxLength} символов!
  Возможна потеря данных!`;
/**
 * Function returns string with replaced html 5 tags because of synchronization with Android client
 * @param {string} html
 * @returns {string}
 */
const fixHTMLTags = (html: string): string => {
  if (!html || html.length === 0) return html;
  return html
    .replace('<strong>', '<b>')
    .replace('</strong>', '</b>')
    .replace('<em>', '<i>')
    .replace('</em>', '</i>')
    .replace('<u>', '<ins>')
    .replace('</u>', '</ins>');
};

/**
 * Function returns fixed description from RawDraftContentState with Notification
 * if its length  was more than descrition limit.
 */
export const fixDescription = (description: RawDraftContentState | undefined, descriptionLimit: number) => {
  if (!description) return description;
  let fixedDescription = fixHTMLTags(stateToHTML(convertFromRaw(description)));

  if (fixedDescription.length > descriptionLimit) {
    fixedDescription = fixedDescription.substr(0, descriptionLimit);
    NotificationManager.warning(getLengthWarning(descriptionLimit));
  }
  return fixedDescription;
};

/**
 * Function returns state with max length ensured.
 */
export const getStateWithMaxLength = (oldState: EditorState, newState: EditorState, maxLength: number): EditorState => {
  const currentContent = convertToRaw(newState.getCurrentContent());
  const formatedContent = stateToHTML(convertFromRaw(currentContent));
  const stateContent = newState.getCurrentContent();
  const oldContent = oldState.getCurrentContent();

  if (stateContent !== oldContent && formatedContent.length > maxLength) {
    NotificationManager.warning(getLengthWarning(maxLength));
  }
  return newState;
};

/**
 *  Function convert string to RawDraftContentState
 */
export const convertStringToRichText = (description: string): RawDraftContentState =>
  convertToRaw(stateFromHTML(DOMPurify.sanitize(description)));

export const isNoEmptyDescription = (description: RawDraftContentState | undefined) =>
  description &&
  description.blocks &&
  description.blocks.length > 0 &&
  description.blocks.some((block: RawDraftContentBlock) => block.text && block.text.length > 0);

/**
 *  Function convert RawDraftContentState to string
 */
export const fromRawDraftContentStateToString = (raw: RawDraftContentState): string[] | string => {
  if (raw && raw.blocks) {
    return raw.blocks.map((block: RawDraftContentBlock) => block.text);
  } else {
    return '';
  }
};

/**
 *  Function checks is State empty or not
 */
export const isRawStateEmpty = (rawState: RawDraftContentState): boolean => {
  const contentState = convertFromRaw(rawState);
  return contentState.hasText() && contentState.getPlainText() !== '';
};
