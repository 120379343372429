import { meetupStatus } from 'constants/meetupStatus';
import { meetupFilter } from 'constants/meetupFilter';
import { UserRoles } from 'constants/UserRoles';
import { RouteUrls } from '../constants/RouteUrls';
import history from './history';

export const getMeetupSelector = (userRole?: UserRoles[]) => {
  const prevPath = history.location.pathname;
  const isChief = userRole && userRole!.includes(UserRoles.CHIEF);
  return prevPath === RouteUrls.DraftMeetup
    ? isChief
      ? { status: meetupStatus.Draft, filter: meetupFilter.None }
      : { status: meetupStatus.Draft, filter: meetupFilter.MY }
    : prevPath === RouteUrls.FutureMeetup
    ? { status: meetupStatus.Confirmed, filter: meetupFilter.COMING }
    : prevPath === RouteUrls.PastMeetup
    ? { status: meetupStatus.Confirmed, filter: meetupFilter.PAST }
    : { status: meetupStatus.Request, filter: meetupFilter.None };
};
