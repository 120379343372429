import React, { memo } from 'react';

const ActiveSecondIcon = ({ color, fontColor }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 11</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="2" transform="translate(-715.000000, -84.000000)">
        <g id="Group-7" transform="translate(408.000000, 84.000000)">
          <g id="Group-3-Copy" transform="translate(307.000000, 0.000000)">
            <g id="Group-11">
              <circle id="Oval" fill={color} cx="12" cy="12" r="12" />
              <path
                d="M15.0175781,16 L9.16992188,16 L9.16992188,14.8398438 L11.9296875,11.8984375 C12.3085956,11.4843729 12.5888663,11.1230484 12.7705078,10.8144531 C12.9521493,10.5058578 13.0429688,10.212892 13.0429688,9.93554688 C13.0429688,9.55663873 12.9472666,9.25879015 12.7558594,9.04199219 C12.5644522,8.82519423 12.2910174,8.71679688 11.9355469,8.71679688 C11.5527325,8.71679688 11.2509777,8.84863149 11.0302734,9.11230469 C10.8095692,9.37597788 10.6992188,9.7226541 10.6992188,10.1523438 L9,10.1523438 C9,9.6328099 9.1240222,9.15820527 9.37207031,8.72851562 C9.62011843,8.29882598 9.97070086,7.96191528 10.4238281,7.71777344 C10.8769554,7.47363159 11.3906221,7.3515625 11.9648438,7.3515625 C12.8437544,7.3515625 13.5263648,7.56249789 14.0126953,7.984375 C14.4990259,8.40625211 14.7421875,9.00194928 14.7421875,9.77148438 C14.7421875,10.1933615 14.6328136,10.6230447 14.4140625,11.0605469 C14.1953114,11.4980491 13.8203152,12.0078096 13.2890625,12.5898438 L11.3496094,14.6347656 L15.0175781,14.6347656 L15.0175781,16 Z"
                id="2"
                fill={fontColor}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default memo(ActiveSecondIcon);
