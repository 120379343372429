import * as React from 'react';

interface IMeetupCardContentProps {
  subject: string;
  excerpt: string;
}
class MeetupCardContent extends React.Component<IMeetupCardContentProps, {}> {
  public state = {
    subjectRef: React.createRef<HTMLDivElement>(),
    excerptRef: React.createRef<HTMLDivElement>(),
    subject: this.props.subject,
    excerpt: this.props.excerpt,
  };

  componentDidMount(): void {
    const subjectEl = this.state.subjectRef.current;
    const excerptEl = this.state.excerptRef.current;

    if (subjectEl && subjectEl.scrollHeight > subjectEl.offsetHeight) {
      const wordArray = subjectEl.innerHTML.split(' ');
      while (subjectEl.scrollHeight > subjectEl.offsetHeight) {
        wordArray.pop();
        subjectEl.innerHTML = wordArray.join(' ') + '...';
      }
      this.setState({ subject: subjectEl.innerHTML });
    }

    if (excerptEl && excerptEl.scrollHeight > excerptEl.offsetHeight) {
      const wordArray = excerptEl.innerHTML.split(' ');
      while (excerptEl.scrollHeight > excerptEl.offsetHeight) {
        wordArray.pop();
        excerptEl.innerHTML = wordArray.join(' ') + '...';
      }
      this.setState({ excerpt: excerptEl.innerHTML });
    }
  }

  public render() {
    const { excerpt, subject, excerptRef, subjectRef } = this.state;
    return (
      <div className="meetup-card__content">
        <div ref={subjectRef} className="meetup-card__content__heading">
          {subject}
        </div>
        <div ref={excerptRef} className="meetup-card__content__text">
          {excerpt}
        </div>
      </div>
    );
  }
}

export default MeetupCardContent;
