import {
  FETCH_MEETUP,
  FETCH_MEETUP_SUCCESS,
  FETCH_MEETUP_ERROR,
  PUBLISH_MEETUP,
  PUBLISH_MEETUP_SUCCESS,
  PUBLISH_MEETUP_ERROR,
  BACK_TO_MODERATE,
  BACK_TO_MODERATE_SUCCESS,
  BACK_TO_MODERATE_ERROR,
  UPLOAD_MEETUP_FILE,
  UPLOAD_MEETUP_FILE_ERROR,
  UPLOAD_MEETUP_FILE_STATUS,
  RATE_MEETUP,
  UNRATE_MEETUP,
} from 'constants/actionConstants';
import IMeetupDetails from 'models/IMeetupDetails';
import { IPublishParams } from './MeetupPreview';

export function fetchMeetup(id: string) {
  return { type: FETCH_MEETUP, payload: id };
}

export function fetchMeetupSuccess(meetup: IMeetupDetails) {
  return { type: FETCH_MEETUP_SUCCESS, payload: meetup };
}

export function fetchMeetupError(error: {}) {
  return {
    type: FETCH_MEETUP_ERROR,
    payload: error,
  };
}

export function publishMeetup(publishParams: IPublishParams) {
  return { type: PUBLISH_MEETUP, payload: publishParams };
}

export function publishMeetupSuccess() {
  return { type: PUBLISH_MEETUP_SUCCESS };
}

export function publishMeetupError(publishError: {}) {
  return { type: PUBLISH_MEETUP_ERROR, payload: publishError };
}

export function backToModeration(id: string) {
  return { type: BACK_TO_MODERATE, payload: id };
}

export function backToModerationSuccess() {
  return { type: BACK_TO_MODERATE_SUCCESS };
}

export function backToModerationError(publishError: {}) {
  return { type: BACK_TO_MODERATE_ERROR, payload: publishError };
}

export function uploadMeetupFile(meetupId: string, files: File[]) {
  return { type: UPLOAD_MEETUP_FILE, payload: { meetupId, files } };
}

export function uploadMeetupFileStatus(isUploaded: boolean) {
  return { type: UPLOAD_MEETUP_FILE_STATUS, payload: { isUploaded } };
}

export function uploadMeetupFileError(publishError: {}) {
  return { type: UPLOAD_MEETUP_FILE_ERROR, payload: publishError };
}

export function rateMeetup(meetupId: string, rate: number) {
  return { type: RATE_MEETUP, payload: { meetupId, rate } };
}

export function unrateMeetup(meetupId: string) {
  return { type: UNRATE_MEETUP, payload: { meetupId } };
}
