import {
  FETCH_ACCESS_ROUTE,
  FETCH_ACCESS_DENIED,
  RESET_ACCESS_ROUTE,
  FETCH_ACCESS_GRANTED,
} from 'constants/actionConstants';

export function fetchAccessRoute(path: string, id?: string) {
  return { type: FETCH_ACCESS_ROUTE, payload: { path, id } };
}

export function fetchAccessGrunted() {
  return { type: FETCH_ACCESS_GRANTED };
}

export function fetchAccessDenied() {
  return { type: FETCH_ACCESS_DENIED };
}

export function resetAccessRoute() {
  return { type: RESET_ACCESS_ROUTE };
}
