import * as React from 'react';
import { connect } from 'react-redux';
import Avatar, { ReactAvatarProps } from 'react-avatar';

import IUser from 'models/IUser';
import { IStore } from 'store/rootReducer';
import { IUserPhoto } from './UserAvatar.reducer';

interface IUserAvatarProps extends ReactAvatarProps {
  user: IUser;
  usersPhoto: IUserPhoto[];
}

class UserAvatar extends React.Component<IUserAvatarProps> {
  render() {
    const { user, usersPhoto } = this.props;
    const photo = usersPhoto.find((photo: IUserPhoto) => photo && photo[user.id] !== undefined);
    return <Avatar {...(photo && { src: photo[user.id] })} name={`${user.name} ${user.surname}`} {...this.props} />;
  }
}

function mapSateToProps(state: IStore) {
  return {
    usersPhoto: state.usersPhoto,
  };
}

export default connect(
  mapSateToProps,
  null,
)(UserAvatar);
