import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router';
import Auth from 'containers/Auth/Auth';
import history from 'utils/history';
import Header from 'components/Header/Header';
import MeetupCreate from 'containers/Meetup/MeetupCreate/MeetupCreate';
import MeetupEdit from 'containers/Meetup/MeetupEdit/MeetupEdit';
import MeetupPreview from 'containers/Meetup/MeetupPreview/MeetupPreview';
import NewsList from 'containers/NewsList/NewsList';
import NewsEdit from 'containers/News/NewsEdit/NewsEdit';
import NewsCreate from 'containers/News/NewsCreate/NewsCreate';
import NewsPreview from 'containers/News/NewsPreview/NewsPreview';
import PageNotFound from 'containers/PageNotFound/PageNotFound';
import RestrictedPage from 'containers/RestrictedPage/RestrictedPage';
import UnauthorizedPage from 'containers/UnauthorizedPage/UnauthorizedPage';
import PrivateRoute from 'containers/PrivateRoute/PrivateRoute';
import ThemePreview from 'containers/Theme/ThemePreview/ThemePreview';
import ThemeCreate from 'containers/Theme/ThemeCreate/ThemeCreate';
import ThemeEdit from 'containers/Theme/ThemeEdit/ThemeEdit';
import { IStore } from 'store/rootReducer';
import { RouteUrls } from 'constants/RouteUrls';
import { NotificationContainer } from 'react-notifications';
import MeetupList from './containers/MeetupList/MeetupList';
import AdminPanel from './containers/AdminPanel/AdminPanel';
import MemoPreview from './containers/Memo/MemoPreview/MemoPreview';
import MemoEdit from './containers/Memo/MemoEdit/MemoEdit';

type AppProps = {
  isAccountError: boolean;
  isUserAuthorized: boolean;
};
class App extends React.Component<AppProps> {
  static get content() {
    return (
      <div className="App">
        <Router history={history}>
          <Header />
          <div className="App_main">
            <Switch>
              <Route path={RouteUrls.NewsList} exact component={NewsList} />
              <PrivateRoute path={RouteUrls.CreateMeetup} component={MeetupCreate} />
              <PrivateRoute path={RouteUrls.EditMeetup} component={MeetupEdit} />
              <PrivateRoute path={RouteUrls.EditNews} component={NewsEdit} />
              <PrivateRoute path={RouteUrls.CreateNews} component={NewsCreate} />
              <PrivateRoute path={RouteUrls.EditTheme} component={ThemeEdit} />
              <Route path={RouteUrls.CreateTheme} component={ThemeCreate} />
              <Route path={RouteUrls.PreviewEvent} component={MeetupPreview} />
              <Route path={RouteUrls.PreviewTheme} component={ThemePreview} />
              <Route path={RouteUrls.PreviewNews} component={NewsPreview} />
              <Route path={RouteUrls.PageNotFound} component={PageNotFound} />
              <Route path={RouteUrls.UnauthorizedPage} component={UnauthorizedPage} />
              <Route path={RouteUrls.RestrictedPage} component={RestrictedPage} />
              <Route path={RouteUrls.MeetupList} component={MeetupList} />
              <Route path={RouteUrls.AdminPanel} component={AdminPanel} />
              <Route path={RouteUrls.MemoManagement} component={MemoPreview} />
              <Route path={RouteUrls.EditMemo} component={MemoEdit} />

              <Redirect to={RouteUrls.Themes} />
            </Switch>
          </div>
        </Router>
        <NotificationContainer />
      </div>
    );
  }

  public render() {
    if (this.props.isAccountError) {
      return <UnauthorizedPage />;
    }

    return this.props.isUserAuthorized ? App.content : <Auth />;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    isUserAuthorized: state.userInfo.isAuthorized,
    isAccountError: state.userInfo.isAccountError,
  };
};

export default connect(mapStateToProps)(App);
