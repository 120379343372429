export default {
  baseURL: '',
  idToken: '',
  endPoints: {
    auth: '/api/v1.5/users/authenticate',
    getMeetups: '/api/v1.5/meetups',
    postMeetup: '/api/v1.5/meetups',
    meetupImageUpload: '/api/v1.5/meetups/',
    meetupFileUpload: '/api/v1.5/meetup/',
    newsImageUpload: '/api/v1.5/news/',
    defaultImages: '/api/v1.5/picture/defaults',
    publishMeetUp: '/api/v1.5/meetups/',
    getImage: `/api/v1.5/files/`,
    getPicture: `/api/v1.5/picture/`,
    searchSpeaker: '/api/v1.5/persons?query=',
    getFileById: '/api/v1.5/files/',
    getNews: '/api/v1.5/news',
    postNews: '/api/v1.5/news',
    users: '/api/v1.5/users',
    likeTheme: '/api/v1.5/meetups/',
    goToMeetup: '/api/v1.5/meetups/',
    rateMeetup: '/api/v1.5/meetups/',
    toModerate: '/api/v1.5/meetups/',
    getNotifications: '/api/v1.5/notifications',
    readNotifications: '/api/v1.5/notifications/read',
    file: '/api/v1.5/meetup/file/',
    getFileArchive: '/api/v1.5/meetup/',
    memo: '/api/v1.5/memo',
  },
};
