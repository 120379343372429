import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IntlShape, injectIntl, FormattedMessage } from 'react-intl';
import { RawDraftContentState } from 'draft-js';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import { IStore } from 'store/rootReducer';
import { replaceUrlParams } from 'utils/routeUrls';

import IThemeDetails from 'models/IThemeDetails';

import RichEditor from 'components/Editor/RichEditor';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomButton from 'components/CustomButton/CustomButton';

import { descriptionLimit, themeLimit } from 'constants/inputValueLimits';
import { fieldNames } from 'constants/fieldNames';
import { RouteUrls } from 'constants/RouteUrls';

import { fetchTheme, resetThemeData } from 'containers/Theme/Theme.actions';
import { editTheme } from 'containers/Theme/ThemeEdit/ThemeEdit.actions';
import DialogModal from '../../../components/Modal/DialogModal';

interface IThemeEditProps {
  intl: IntlShape;
  currentTheme: IThemeDetails;
  fetchTheme: (id: string) => void;
  editTheme: (theme: IThemeDetails) => void;
  resetThemeData: () => void;
  match: {
    params: {
      id: string;
    };
  };
}

interface IThemeEditSate {
  currentTheme: IThemeDetails;
  isDialogVisible: boolean;
}

class ThemeEdit extends React.Component<IThemeEditProps, IThemeEditSate> {
  public state: IThemeEditSate = {
    currentTheme: {} as IThemeDetails,
    isDialogVisible: false,
  };

  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.props.fetchTheme(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: IThemeEditProps): void {
    if (this.props.currentTheme!.id !== prevProps.currentTheme!.id) {
      this.setState({ currentTheme: this.props.currentTheme });
    }
  }
  componentWillUnmount(): void {
    this.props.resetThemeData();
  }

  public openDialog = () => {
    this.setState({ isDialogVisible: true });
  };

  public closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  disableCheck = () => !this.state.currentTheme.subject;

  render() {
    const { id, subject, description } = this.state.currentTheme;
    const { intl } = this.props;

    return id ? (
      <div className="page-content meetup-edit">
        <h1 className="page-heading">
          <FormattedMessage id="theme.edit.title" />
        </h1>
        <Card className="card card_form">
          <CardContent>
            <form>
              <CustomInput
                intl={intl}
                maxLength={themeLimit}
                label={intl.formatMessage({
                  id: 'themeList.themeCreate.placeholderTheme',
                })}
                className="theme"
                multiline={false}
                onChange={event => {
                  this.setState({ currentTheme: { ...this.state.currentTheme, subject: event.target.value } });
                }}
                rows={1}
                name={fieldNames.subject}
                value={subject}
              />
              <RichEditor
                intl={intl}
                readonly={false}
                onChange={(name: string, value: RawDraftContentState) => {
                  this.setState({ currentTheme: { ...this.state.currentTheme, description: value } });
                }}
                maxLength={descriptionLimit}
                value={description}
              />
            </form>
          </CardContent>
        </Card>
        <Card className="card card_controls card_controls-form">
          <CardActions className="card__actions">
            <CustomButton
              className="button button_outlined"
              variant="outlined"
              link={replaceUrlParams(RouteUrls.PreviewTheme, { id })}
              label={intl.formatMessage({
                id: 'theme.edit.button.cancel',
              })}
            />
            <CustomButton
              className="button button_contained-violet margin-left-10"
              variant="contained"
              disabled={this.disableCheck()}
              onClick={() =>
                this.state.currentTheme.subject.length > 500
                  ? this.openDialog()
                  : this.props.editTheme(this.state.currentTheme)
              }
              label={intl.formatMessage({
                id: 'theme.edit.button.save',
              })}
            />
          </CardActions>
        </Card>
        <DialogModal
          handleConfirm={() => {}}
          handleClose={this.closeDialog}
          titleTextId="editor.subjectValidation.warningTitle"
          textId="editor.subjectValidation.warningText"
          open={this.state.isDialogVisible}
          showButtons={false}
        />
      </div>
    ) : (
      <></>
    );
  }
}

function mapStateToProps(store: IStore) {
  return {
    currentTheme: store.currentTheme,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchTheme: bindActionCreators(fetchTheme, dispatch),
    editTheme: bindActionCreators(editTheme, dispatch),
    resetThemeData: bindActionCreators(resetThemeData, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ThemeEdit),
);
