import ApiService from 'services/ApiService';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CANCEL_GO_TO_MEETUP, GO_TO_MEETUP } from '../../constants/actionConstants';
import { getMeetupSelector } from '../../utils/meetupSelector';
import history from '../../utils/history';
import { fetchMeetupsList } from './MeetupList.actions';
import { fetchMeetup, fetchMeetupError } from '../Meetup/MeetupPreview/MeetupPreview.actions';
import { RouteUrls } from '../../constants/RouteUrls';

function* goMeetupAsync({ payload }: any) {
  const filter = getMeetupSelector();
  try {
    yield call(async () => {
      return await ApiService.meetUps().goToMeetup(payload);
    });
    if (history.location.pathname === RouteUrls.FutureMeetup) {
      yield put(fetchMeetupsList(filter));
    } else if (history.location.pathname.includes('previewEvent')) {
      yield put(fetchMeetup(payload));
    }
  } catch (error) {
    yield put(fetchMeetupError(error));
  }
}

function* cancelGoToMeetup({ payload }: any) {
  const filter = getMeetupSelector();
  try {
    yield call(async () => {
      return await ApiService.meetUps().cancelGoToMeetup(payload);
    });
    if (history.location.pathname === RouteUrls.FutureMeetup) {
      yield put(fetchMeetupsList(filter));
    } else if (history.location.pathname.includes('previewEvent')) {
      yield put(fetchMeetup(payload));
    }
  } catch (error) {
    yield put(fetchMeetupError(error));
  }
}

export const goMeetupSagas = [takeEvery(GO_TO_MEETUP, goMeetupAsync), takeEvery(CANCEL_GO_TO_MEETUP, cancelGoToMeetup)];
