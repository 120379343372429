export enum richEditorStyles {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  STRIKETHROUGH = 'STRIKETHROUGH',
  ORDERED_LIST_ITEM = 'ordered-list-item',
  UNORDERED_LIST_ITEM = 'unordered-list-item',
  QUOTE = 'blockquote',
  LINK = 'link',
}

export enum richEditorLabels {
  BOLD = 'B',
  ITALIC = 'I',
  UNDERLINE = 'U',
  STRIKETHROUGH = 'S',
  ORDERED_LIST_ITEM = 'OL',
  UNORDERED_LIST_ITEM = 'UL',
  QUOTE = '"',
  LINK = 'L',
  CLEAN = 'CLEAN',
}
