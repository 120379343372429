import { call, put, takeEvery } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import {
  FETCH_MEETUP,
  PUBLISH_MEETUP,
  BACK_TO_MODERATE,
  UPLOAD_MEETUP_FILE,
  RATE_MEETUP,
  UNRATE_MEETUP,
} from 'constants/actionConstants';
import {
  fetchMeetupSuccess,
  fetchMeetupError,
  publishMeetupError,
  publishMeetupSuccess,
  backToModerationError,
  backToModerationSuccess,
  uploadMeetupFileError,
  uploadMeetupFileStatus,
} from './MeetupPreview.actions';
import IMeetupDetails from 'models/IMeetupDetails';
import { fetchUsersPhoto } from 'containers/UserAvatar/UserAvatar.actions';
import history from 'utils/history';
import { RouteUrls } from 'constants/RouteUrls';
import { fieldNames } from 'constants/fieldNames';
import { mediaTypes } from 'constants/mediaTypes';

function* fetchMeetup(id: string) {
  const meetupsData = yield call(async () => {
    return await ApiService.meetUps().getById(id);
  });
  const meetupImage = yield call(async () => {
    return await ApiService.getMeetupImage(meetupsData.data.picId, {});
  });
  return { ...meetupsData.data, image: meetupImage.data };
}

function* fetchMeetupAsync({ payload }: any) {
  try {
    const meetup: IMeetupDetails = yield fetchMeetup(payload);
    yield put(fetchUsersPhoto(meetup.speakers));
    yield put(fetchMeetupSuccess(meetup));
  } catch (error) {
    yield put(fetchMeetupError(error));
  }
}

function* publishMeetupAsync({ payload }: any) {
  try {
    yield call(async () => {
      return await ApiService.meetUps().publish(payload.id);
    });
    yield put(publishMeetupSuccess());
    history.push(payload.path);
    NotificationManager.success(TranslateService.translate('meetupList.meetupPublish.publishSuccess'));
  } catch (e) {
    yield put(publishMeetupError(e));
  }
}

function* backToModerationAsync({ payload }: any) {
  try {
    yield call(async () => {
      return await ApiService.meetUps().backToModerate(payload);
    });
    yield put(backToModerationSuccess());
    history.push(RouteUrls.FutureMeetup);
    NotificationManager.success(TranslateService.translate('meetupList.meetupBackToDraft.publishSuccess'));
  } catch (e) {
    yield put(backToModerationError(e));
  }
}

function* rateMeetupAsync({ payload }: any) {
  const { meetupId, rate } = payload;
  try {
    yield call(async () => {
      return await ApiService.meetUps().rateMeetup(meetupId, rate);
    });
    const meetup: IMeetupDetails = yield fetchMeetup(meetupId);
    yield put(fetchMeetupSuccess(meetup));
  } catch (e) {
    yield put(fetchMeetupError(e));
  }
}

function* unrateMeetupAsync({ payload }: any) {
  const { meetupId } = payload;
  try {
    yield call(async () => {
      return await ApiService.meetUps().unrateMeetup(meetupId);
    });
    const meetup: IMeetupDetails = yield fetchMeetup(meetupId);
    yield put(fetchMeetupSuccess(meetup));
  } catch (e) {
    yield put(fetchMeetupError(e));
  }
}

function* uploadMeetupFileAsync({ payload }: any) {
  const { meetupId, files = [] } = payload;
  if (files.length > 0) {
    for (let file of files) {
      try {
        yield put(uploadMeetupFileStatus(false));
        yield call(async () => {
          const formData = new FormData();
          formData.append(fieldNames.file, file);
          await ApiService.fileUpload(mediaTypes.Meetup).meetupFileUpload(meetupId, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        });
        const meetup: IMeetupDetails = yield fetchMeetup(meetupId);
        yield put(fetchMeetupSuccess(meetup));
        yield put(uploadMeetupFileStatus(true));
      } catch (e) {
        yield put(uploadMeetupFileError(e));
        yield put(uploadMeetupFileStatus(true));
      }
    }
  }
}

export const meetupSagas = [
  takeEvery(FETCH_MEETUP, fetchMeetupAsync),
  takeEvery(PUBLISH_MEETUP, publishMeetupAsync),
  takeEvery(BACK_TO_MODERATE, backToModerationAsync),
  takeEvery(UPLOAD_MEETUP_FILE, uploadMeetupFileAsync),
  takeEvery(RATE_MEETUP, rateMeetupAsync),
  takeEvery(UNRATE_MEETUP, unrateMeetupAsync),
];
