import { takeEvery, call, put } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';

import { EDIT_THEME } from 'constants/actionConstants';
import { descriptionLimit } from 'constants/inputValueLimits';
import { RouteUrls } from 'constants/RouteUrls';

import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';

import { fixDescription } from 'utils/draft.utils';
import { replaceUrlParams } from 'utils/routeUrls';
import history from 'utils/history';

import { editThemeError, editThemeSuccess } from './ThemeEdit.actions';

function* editThemeAsyns({ payload }: any) {
  try {
    yield call(async () => {
      return await ApiService.themes().edit(payload.id, {
        ...payload,
        description: fixDescription(payload.description, descriptionLimit),
      });
    });
    yield put(editThemeSuccess());
    NotificationManager.success(TranslateService.translate('theme.edit.success'));
    yield history.push(replaceUrlParams(RouteUrls.PreviewTheme, { id: payload.id }));
  } catch (error) {
    yield put(editThemeError(error));
    NotificationManager.error(TranslateService.translate('theme.edit.error'));
  }
}

export const themeEditSagas = [takeEvery(EDIT_THEME, editThemeAsyns)];
