export function replaceUrlParams(url: string, params: object = {}): string {
  return url.replace(/:(\w+)/gi, (str, bracket) => {
    if (bracket in params) {
      return params[bracket];
    } else {
      return '';
    }
  });
}

export function isPathBelongRoute(pathname: string, route: string) {
  return pathname.startsWith(route);
}
