import * as React from 'react';
import { compose, bindActionCreators, Dispatch } from 'redux';

import { connect } from 'react-redux';
import HeaderLinks from './HeaderLinks/HeaderLinks';
import IAppUser from 'models/IAppUser';
import logo from 'assets/images/logo.png';
import LogoutButton from 'containers/Auth/LogoutButton';
import { IStore } from 'store/rootReducer';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteUrls } from 'constants/RouteUrls';
import UserAvatar from 'containers/UserAvatar/UserAvatar';
import { fetchUsersPhoto } from 'containers/UserAvatar/UserAvatar.actions';
import iconBell from 'assets/images/icons/iconBell.svg';
import { IconButton, Popover } from '@material-ui/core';
import { fetchNotificationList, readNotifications } from 'containers/NotificationList/NotificationList.actions';
import INotificationDetails from 'models/INotificationDetails';
import NotificationList from 'containers/NotificationList/NotificationList';
import { notificationUpdateInterval } from '../../constants/notificationsConstants';
import { createRef } from 'react';
import { isChief } from '../../utils/userRules';

interface IHeaderProps extends RouteComponentProps<any, any> {
  user: IAppUser;
  fetchUsersPhoto: (users: IAppUser[]) => void;
  getNotificationList: () => void;
  readNotifications: (ids: (number | null)[]) => void;
  notifications: INotificationDetails[];
}

interface IHeaderState {
  notifications: INotificationDetails[];
  showNotifications: boolean;
  timer: NodeJS.Timeout | null;
  isEmpty: boolean;
  popoverAnchor: Element | null;
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
  public state = {
    notifications: [],
    showNotifications: false,
    timer: null,
    isEmpty: false,
    popoverAnchor: null,
  };

  private anchorRef = createRef<HTMLDivElement>();

  startTimer() {
    const timer = setInterval(this.props.getNotificationList, notificationUpdateInterval);
    this.setState({ timer: timer });
  }

  componentDidMount() {
    this.props.fetchUsersPhoto([this.props.user]);
    if (this.props.location.pathname !== RouteUrls.UnauthorizedPage) {
      this.props.getNotificationList();
    }
    this.startTimer();
  }

  componentWillUnmount(): void {
    clearInterval(this.state.timer!);
  }

  componentDidUpdate(prevProps: Readonly<IHeaderProps>): void {
    this.props.location !== prevProps.location &&
      this.props.location.pathname !== RouteUrls.UnauthorizedPage &&
      this.props.getNotificationList();
  }

  notificationsOnClick = () => {
    this.setState(state => ({
      showNotifications: !state.showNotifications,
      popoverAnchor: this.anchorRef.current,
    }));
  };

  onClosePopover = () => {
    const unreadNotifications = this.props.notifications.filter(notification => !notification.read);
    const unreadIds = unreadNotifications.map(notification => notification.id);
    if (unreadIds.length > 0) {
      this.props.readNotifications(unreadIds);
    }
    this.setState({ popoverAnchor: null });
  };

  public render() {
    const { user, notifications, location } = this.props;
    const { isEmpty } = this.state;
    const { name, surname } = user;
    const { pathname } = location;
    const isUserChief = isChief(user);
    const open = this.state.popoverAnchor !== null;
    const id = open ? 'simple-popover' : undefined;
    const unreadNotifications = notifications.filter(notification => !notification.read);
    return (
      <div className="App-header">
        <div className={pathname === RouteUrls.UnauthorizedPage ? 'hidden' : 'App-header__logo-container'}>
          <Link to={RouteUrls.Themes}>
            <img src={logo} alt="Logo" className="App-header__logo" />
          </Link>
        </div>
        <HeaderLinks location={location} isUserChief={isUserChief} />
        <div className="avatar">
          <div aria-describedby={id} className="notifications" ref={this.anchorRef}>
            <IconButton onClick={this.notificationsOnClick}>
              <img className="notification-list__icon" src={iconBell} alt={'icon-bell'} />
            </IconButton>
            {unreadNotifications.length > 0 && (
              <div className="notifications__unread">{unreadNotifications.length}</div>
            )}
            <Popover
              id={id}
              anchorEl={this.state.popoverAnchor}
              open={open}
              onClose={this.onClosePopover}
              className="notificationsPopover"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className="notifications-list">
                <NotificationList notifications={notifications} isEmpty={isEmpty} onClose={this.onClosePopover} />
              </div>
            </Popover>
          </div>

          <span className="avatar__name">{name}</span>
          <span className="avatar__surname">{surname}</span>
          <UserAvatar className="avatar__image" user={user} round={true} size="40" />
          <LogoutButton />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    user: state.userInfo.user,
    notifications: state.notifications.notifications,
    isEmpty: state.notifications.isEmpty,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchUsersPhoto: bindActionCreators(fetchUsersPhoto, dispatch),
    getNotificationList: bindActionCreators(fetchNotificationList, dispatch),
    readNotifications: bindActionCreators(readNotifications, dispatch),
  };
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(Header) as any;
