import { takeEvery, put, select } from 'redux-saga/effects';
import { FETCH_USER_PHOTO } from 'constants/actionConstants';
import ApiService from 'services/ApiService';
import { allSettled } from 'q';
import IUser from 'models/IUser';
import { fetchUserPhotoError, fetchUserPhotoSuccess } from './UserAvatar.actions';
import { getUsersPhoto } from '../../selectors/storeSelectors';
import { IUserPhoto } from './UserAvatar.reducer';

function getUniqueUsersId(array: IUser[]): Array<string> {
  return array
    .map((user: IUser) => user.id)
    .filter((value: string, index: number, self: Array<string>) => self.indexOf(value) === index);
}

function* fetchUserPhotoAsync({ payload }: any) {
  try {
    const usersPhoto: IUserPhoto[] = yield select(getUsersPhoto);
    const uniqueIds = getUniqueUsersId(payload);
    const notExists = uniqueIds.filter(id => {
      return !usersPhoto.some((value: IUserPhoto) => value[id] !== undefined);
    });
    const promises = notExists.map((id: string) =>
      (async () => {
        const photo = await ApiService.getUserPhoto(id, {});
        return { [id]: photo.data };
      })(),
    );

    const photos = yield allSettled(promises);
    yield put(fetchUserPhotoSuccess(photos.map((photo: any) => photo.value)));
  } catch (error) {
    yield put(fetchUserPhotoError());
  }
}

export const userPhotoSagas = [takeEvery(FETCH_USER_PHOTO, fetchUserPhotoAsync)];
