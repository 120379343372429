import ApiService from 'services/ApiService';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { fetchMeetup } from '../../MeetupPreview/MeetupPreview.actions';
import { deleteFileSuccess, deleteFileError } from './FileDelete.actions';
import { DELETE_FILE } from '../../../../constants/actionConstants';

function* deleteFileAsync({ payload }: any) {
  const meetupId = yield select(state => state.currentMeetup.id);
  try {
    yield call(async () => {
      return await ApiService.meetUps().deleteFile(payload);
    });
    yield put(deleteFileSuccess());
    yield put(fetchMeetup(meetupId));
  } catch (error) {
    yield put(deleteFileError(error));
  }
}

export const deleteFileSagas = [takeEvery(DELETE_FILE, deleteFileAsync)];
