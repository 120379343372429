import { addMinutes, addYears, getDate, getMonth, getYear, parseISO, setHours, setMinutes } from 'date-fns';
import {
  meetupFirstHourForBooking,
  meetupFirstMinuteForBooking,
  meetupLatestHourForBookingFinish,
  meetupLatestHourForBookingStart,
  meetupLatestMinuteForBookingFinish,
  meetupLatestMinuteForBookingStart,
  meetupPlanningDistanceInYears,
  meetupTimeInterval,
} from 'constants/meetupLengthAndTime';

export const getMinDateStart = () => {
  return setHours(setMinutes(new Date(), meetupFirstMinuteForBooking), meetupFirstHourForBooking);
};

export const getMaxDateStart = () => {
  return addYears(new Date(), meetupPlanningDistanceInYears);
};

export const getMinDateFinish = (meetupStartDate: Date | undefined) => {
  return meetupStartDate ? parseISO(meetupStartDate.toString()) : undefined;
};

export const getMaxDateFinish = () => {
  return addYears(new Date(), meetupPlanningDistanceInYears);
};

export const getMinTimeStartToday = () => {
  return new Date();
};

export const getMinTimeStartInFuture = (meetupStartTime: Date) => {
  return setHours(
    setMinutes(parseISO(meetupStartTime.toString()), meetupFirstMinuteForBooking),
    meetupFirstHourForBooking,
  );
};

export const getMinTimeFinishToday = (meetupStartTime: Date) => {
  return addMinutes(parseISO(meetupStartTime.toString()), meetupTimeInterval);
};

export const getMinTimeFinishInTheFuture = (meetupStartTime: Date) => {
  return setHours(
    setMinutes(parseISO(meetupStartTime.toString()), meetupFirstMinuteForBooking),
    meetupFirstHourForBooking,
  );
};

export const getMinTimeFinish = (meetupStartTime: Date, meetupEndTime: Date) => {
  return meetupStartTime
    ? checkIfDayIsToday(meetupEndTime)
      ? getMinTimeFinishToday(meetupStartTime)
      : checkStartDayIsEndDay(meetupStartTime, meetupEndTime) || !checkDateIsSet(meetupEndTime)
      ? getMinTimeFinishToday(meetupStartTime)
      : getMinTimeFinishInTheFuture(meetupStartTime)
    : undefined;
};

export const getMinTimeStart = (meetupStartDate: Date) => {
  return !checkDateIsSet(meetupStartDate) || checkIfDayIsToday(meetupStartDate)
    ? getMinTimeStartToday()
    : getMinTimeStartInFuture(meetupStartDate);
};

export const getMaxTimeStart = () => {
  return setHours(setMinutes(new Date(), meetupLatestMinuteForBookingStart), meetupLatestHourForBookingStart);
};

export const getMaxTimeFinish = () => {
  return setHours(setMinutes(new Date(), meetupLatestMinuteForBookingFinish), meetupLatestHourForBookingFinish);
};

export const checkStartDayIsEndDay = (meetupStartDate: Date, meetupEndDate: Date) => {
  const startDate = parseISO(meetupStartDate.toString());
  const endDate = meetupEndDate ? parseISO(meetupEndDate.toString()) : parseISO(meetupEndDate);
  return (
    getDate(startDate) === getDate(endDate) &&
    getMonth(startDate) === getMonth(endDate) &&
    getYear(startDate) === getYear(endDate)
  );
};

export const checkDateIsSet = (meetupDate: Date) => {
  return meetupDate !== undefined && meetupDate.toString() !== '';
};

export const checkIfDayIsToday = (day: Date) => {
  if (!day) return false;
  const meetupDay = parseISO(day.toString());
  const currentDate = new Date();
  return checkDateIsSet(day)
    ? getDate(meetupDay) === getDate(currentDate) &&
        getMonth(meetupDay) === getMonth(currentDate) &&
        getYear(meetupDay) === getYear(currentDate)
    : false;
};

export const getSelectedStartDate = (meetupStartDate: Date) => {
  return meetupStartDate ? parseISO(meetupStartDate.toString()) : undefined;
};

export const getSelectedFinishDate = (meetupStartDate: Date, meetupEndDate: Date) => {
  return meetupStartDate && meetupEndDate && meetupEndDate.toString() !== '' && meetupEndDate > meetupStartDate
    ? parseISO(meetupEndDate.toString())
    : undefined;
};
