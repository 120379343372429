import React, { ComponentType } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { RouteUrls } from 'constants/RouteUrls';
import { IStore } from 'store/rootReducer';
import history from 'utils/history';

import { IAccessRoute } from './PrivateRoute.reducer';
import { fetchAccessRoute, resetAccessRoute } from './PrivateRoute.actions';

interface PrivateRouterProps {
  component: ComponentType<any>;
  path: string;
  [name: string]: unknown;
  accessRoute: IAccessRoute;
  fetchAccessRoute: (path: string, id?: string) => void;
  resetAccessRoute: () => void;
  computedMatch: {
    params: {
      id: string;
    };
  };
}

class PrivateRouter extends React.Component<PrivateRouterProps> {
  public componentDidMount() {
    this.props.fetchAccessRoute(this.props.path, this.props.computedMatch.params.id);
  }

  public componentWillUnmount(): void {
    this.props.resetAccessRoute();
  }

  render() {
    const { component: Component, accessRoute, ...rest } = this.props;
    const { restricted, redirect } = accessRoute;
    if (restricted && redirect) {
      history.push(RouteUrls.RestrictedPage);
    }
    return <Route {...rest} render={props => (restricted ? <></> : <Component {...props} />)} />;
  }
}

function mapStateToProps(state: IStore) {
  return {
    accessRoute: state.accessRoute,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchAccessRoute: bindActionCreators(fetchAccessRoute, dispatch),
    resetAccessRoute: bindActionCreators(resetAccessRoute, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRouter);
