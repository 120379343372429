export enum mediaTypes {
  Meetup = 'meetups',
  Theme = 'theme',
  News = 'news',
  File = 'file',
  Memo = 'memo',
}

export enum mediaImageTypes {
  Meetup = 'MEETUP_PIC',
  News = 'NEWS_PIC',
}
