import * as React from 'react';
import { clearDeletedMeetup, clearSelectedMeetup } from '../MeetupList.actions';
import { injectIntl, IntlShape } from 'react-intl';
import { fetchThemesList } from '../../Theme/ThemeList.actions';
import { IStore } from '../../../store/rootReducer';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import IMeetupShort from '../../../models/IMeetupShort';
import ThemeCard from '../../Theme/ThemeCard/ThemeCard';
import { TabContainer } from '../MeetupList';
import MeetupListCounter from '../MeetupListCounter';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized';
import PacmanLoader from '../../../components/PacmanLoader/PacmanLoader';

interface IThemesProps {
  fetchThemesList: () => {};
  themes: IMeetupShort[];
  intl: IntlShape;
  selectedMeetup: string;
  restoreScroll: (meetup: IMeetupShort[], selectedMeetup: string) => void;
  idForDelete: string;
  clearDeletedMeetup: () => {};
  clearSeletedMeetup: () => {};
}

const cache = new CellMeasurerCache({
  fixedWidth: false,
  defaultHeight: 200,
});

class Themes extends React.Component<IThemesProps, {}> {
  public componentDidMount(): void {
    this.props.fetchThemesList();
  }

  componentDidUpdate(): void {
    const { themes, selectedMeetup, idForDelete } = this.props;
    if (idForDelete) {
      this.props.restoreScroll(themes, idForDelete);
    } else if (selectedMeetup) {
      this.props.restoreScroll(themes, selectedMeetup);
    }
  }

  public componentWillUnmount(): void {
    this.props.clearDeletedMeetup();
    this.props.clearSeletedMeetup();
  }

  renderRow = ({ index, style, key, parent }: any) => {
    const theme = this.props.themes[index];
    return (
      <>
        <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
          <div style={style} key={key}>
            <TabContainer>
              <ThemeCard key={theme.id} meetup={theme} intl={this.props.intl} />
            </TabContainer>
          </div>
        </CellMeasurer>
      </>
    );
  };

  render() {
    const { themes } = this.props;
    return (
      <>
        {themes.length ? (
          <div>
            <MeetupListCounter count={themes.length} label={'meetupList.counterAll'} />
            <div style={{ width: '100%', height: '100vh' }}>
              <WindowScroller>
                {({ height, scrollTop, isScrolling, onChildScroll }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <List
                        autoHeight
                        width={width}
                        height={height}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                        rowHeight={cache.rowHeight}
                        deferredMeasurementCache={cache}
                        rowCount={themes.length}
                        rowRenderer={this.renderRow}
                      />
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>
            </div>
          </div>
        ) : (
          <PacmanLoader />
        )}
      </>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    themes: state.meetups.themes,
    selectedMeetup: state.meetups.selectedMeetup,
    idForDelete: state.meetups.idForDelete,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchThemesList: bindActionCreators(fetchThemesList, dispatch),
    clearDeletedMeetup: bindActionCreators(clearDeletedMeetup, dispatch),
    clearSeletedMeetup: bindActionCreators(clearSelectedMeetup, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Themes),
);
