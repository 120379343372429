import {
  FETCH_NOTIFICATION_LIST,
  FETCH_NOTIFICATION_LIST_ERROR,
  FETCH_NOTIFICATION_LIST_SUCCESS,
  READ_NOTIFICATIONS,
  READ_NOTIFICATIONS_ERROR,
  READ_NOTIFICATIONS_SUCCESS,
} from 'constants/actionConstants';
import INotificationDetails from 'models/INotificationDetails';

export function fetchNotificationList() {
  return { type: FETCH_NOTIFICATION_LIST };
}

export function fetchNotificationListSuccess(notifications: INotificationDetails[]) {
  return { type: FETCH_NOTIFICATION_LIST_SUCCESS, payload: notifications };
}

export function fetchNotificationListError(error: {}) {
  return { type: FETCH_NOTIFICATION_LIST_ERROR, payload: error };
}

export function readNotifications(ids: number[]) {
  return { type: READ_NOTIFICATIONS, payload: ids };
}

export function readNotificationsSuccess(notifications: INotificationDetails[]) {
  return { type: READ_NOTIFICATIONS_SUCCESS, payload: notifications };
}

export function readNotificationsListError(error: {}) {
  return { type: READ_NOTIFICATIONS_ERROR, payload: error };
}
