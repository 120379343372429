import * as React from 'react';
import * as AuthActions from './Auth.actions';
import IAppUser from 'models/IAppUser';
import NoAccess from 'components/NoAccess/NoAccess';
import { AzureAD } from 'react-aad-msal';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IStore } from 'store/rootReducer';
import { store } from '../../store/configureStore';
import { authProvider } from './Auth.provider';

interface IAuthProps {
  AuthActions?: any;
  user: IAppUser;
}

class Auth extends React.Component<IAuthProps, {}> {
  public render() {
    return (
      <AzureAD
        provider={authProvider}
        accountInfoCallback={this.props.AuthActions.getAccountInfo}
        unauthenticatedFunction={login => <NoAccess login={login} />}
        reduxStore={store}
      />
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    user: state.userInfo.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Auth);
