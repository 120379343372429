import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ReactDropzone from 'react-dropzone';
import { NotificationManager } from 'react-notifications';
import TranslateService from 'services/TranslateService';
import { acceptedFileTypes, maxImageSizeInBytes } from 'constants/imageConstants';
import uploadImage from 'assets/images/upload.png';

interface IImageDropzoneProps {
  onDrop: (files: File[]) => void;
  disabled?: boolean;
  edit?: boolean;
}

class ImageDropzone extends React.Component<IImageDropzoneProps, {}> {
  public handleDropRejected = (files: File[]) => {
    const file = files[0];

    if (!acceptedFileTypes.includes(file.type)) {
      NotificationManager.error(TranslateService.translate('meetupList.meetupCreate.imageUpload.imageFormats'));
      return;
    }

    if (file.size > maxImageSizeInBytes) {
      NotificationManager.error(TranslateService.translate('meetupList.meetupCreate.imageUpload.caution'));
    }
  };

  public render() {
    const { onDrop, disabled = false, edit = false } = this.props;

    return (
      <ReactDropzone
        onDrop={onDrop}
        accept={acceptedFileTypes}
        maxSize={maxImageSizeInBytes}
        onDropRejected={this.handleDropRejected}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={classNames('image-dropzone', { 'image-dropzone--edit': edit })}>
            <div {...getRootProps()} className="image-dropzone__upload-container">
              <input {...getInputProps()} />
              <img src={uploadImage} alt="upload" className="image-dropzone__upload-image" />
              <p className="image-dropzone__explanation">
                <FormattedMessage id="meetupList.meetupCreate.imageUpload.text" />
                <span>
                  <FormattedMessage id="meetupList.meetupCreate.imageUpload.uploadText" />
                </span>
              </p>
              <p className="image-dropzone__warning">
                <FormattedMessage id="meetupList.meetupCreate.imageUpload.imageFormats" />
              </p>
              <p className="image-dropzone__warning">
                <FormattedMessage id="meetupList.meetupCreate.imageUpload.caution" />
              </p>
            </div>
          </section>
        )}
      </ReactDropzone>
    );
  }
}

export default ImageDropzone;
