import {
  FETCH_NEWS_LIST,
  FETCHED_NEWS_LIST_SUCCESS,
  FETCHED_NEWS_LIST_ERROR,
  CHANGE_SELECTED_NEWS,
  DELETE_SELECTED_NEWS,
} from 'constants/actionConstants';
import INewsDetails from 'models/INewsDetails';

export function fetchNewsList() {
  return { type: FETCH_NEWS_LIST };
}

export function fetchedNewsListSuccess(news: INewsDetails[]) {
  return { type: FETCHED_NEWS_LIST_SUCCESS, payload: news };
}

export function fetchedNewsListError(error: {}) {
  return { type: FETCHED_NEWS_LIST_ERROR, payload: error };
}

export function changeSelectedNews(newsId: string) {
  return { type: CHANGE_SELECTED_NEWS, payload: newsId };
}

export function deleteSelectedNews() {
  return { type: DELETE_SELECTED_NEWS, payload: '' };
}
