import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import { IStore } from 'store/rootReducer';
import IMeetupDetails from 'models/IMeetupDetails';
import IImageInfo, { InitImageInfo } from 'models/IImageInfo';
import { LOCALE } from 'constants/localization';
import { meetupStatus } from 'constants/meetupStatus';
import { MeetupFieldsValuesType } from 'constants/customTypes';
import { isNoEmptyDescription } from 'utils/draft.utils';
import ActiveFirstIcon from 'assets/images/icons/ActiveFirstIcon';
import ActiveSecondIcon from 'assets/images/icons/ActiveSecondIcon';
import CustomButton from 'components/CustomButton/CustomButton';
import MeetupCreateAdditionalForm from './MeetupCreateAdditionalForm/MeetupCreateAdditionalForm';
import {
  createMeetup,
  getDefaultImagesMeetup,
  resetMeetupData,
  searchUser,
  updateMeetupData,
} from './MeetupCreate.actions';
import { MeetupCreateRequiredForm } from './MeetupCreateRequiredForm/MeetupCreateRequiredForm';
import history from 'utils/history';
import { RouteUrls } from 'constants/RouteUrls';
import DialogModal from 'components/Modal/DialogModal';

interface ITabContainerProps {
  children?: React.ReactNode;
}

interface IMeetupCreateProps {
  intl: IntlShape;
  createMeetup: () => {};
  resetMeetupData: () => {};
  updateMeetupData: (payload: { name: string; value: MeetupFieldsValuesType }) => void;
  searchSpeaker: (query: string) => void;
  getDefaultImages: () => {};
  currentMeetup: IMeetupDetails;
  defaultImages: string[];
  defaultImageIds: string[];
}

interface IMeetupCreateState {
  currentTab: number;
  locale: string;
  formInfo: IMeetupDetails;
  imageInfo: IImageInfo;
  isDialogVisible: boolean;
}

function TabContainer(props: ITabContainerProps) {
  return (
    <Typography className="meetup-create__content" component="div">
      {props.children}
    </Typography>
  );
}

class MeetupCreate extends React.Component<IMeetupCreateProps, IMeetupCreateState> {
  public state = {
    currentTab: 0,
    locale: LOCALE.RU,
    formInfo: {
      id: '',
      subject: '',
      start: undefined,
      finish: undefined,
      description: undefined,
      place: '',
      author: {
        id: '',
        name: '',
        surname: '',
      },
      picId: null,
      image: null,
      status: meetupStatus.Draft,
      tmpImage: null,
      tmpImageOrig: null,
      availableSpeakers: [],
      speakerId: '',
      defaultImages: [],
      defaultImageIds: [],
      isOver: false,
      participants: [],
      speakers: [],
      go: false,
      liked: false,
      likesCount: null,
      attachedFiles: [],
      fileUploaded: true,
      myRate: 0,
      rateCount: 0,
      averageRate: 0,
    },
    imageInfo: new InitImageInfo(),
    isDialogVisible: false,
  };

  public goToPreviousPath = () => {
    history.goBack();
  };

  public handleTabChange = (event: React.ChangeEvent<{}>, currentTab: number) => {
    this.setState({ currentTab });
  };

  public searchSpeaker = (query: string) => {
    this.props.searchSpeaker(query);
  };

  public componentDidMount(): void {
    this.props.resetMeetupData();
    this.props.getDefaultImages();
  }

  public componentWillUnmount(): void {
    this.props.resetMeetupData();
    this.props.getDefaultImages();
  }

  public onChange = (name: string, value: MeetupFieldsValuesType) => {
    this.setState(prevState => ({
      formInfo: {
        ...prevState.formInfo,
        [name]: value,
      },
    }));
    this.props.updateMeetupData({ name, value });
  };

  public changeImageInfo = (imageInfo: IImageInfo) => {
    this.setState({ imageInfo: imageInfo });
  };

  public createMeetup = () => {
    this.props.createMeetup();
  };

  public disableCheck = () => {
    return (
      !this.state.formInfo.subject ||
      !(this.state.formInfo.author.id !== '') ||
      !isNoEmptyDescription(this.state.formInfo.description)
    );
  };

  public handleGoBack = () => {
    this.props.resetMeetupData();
    this.setState({ currentTab: 0 });
    this.goToPreviousPath();
  };

  public openDialog = () => {
    this.setState({ isDialogVisible: true });
  };

  public closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  public render() {
    const { intl, defaultImages, defaultImageIds } = this.props;
    const { currentTab, imageInfo, isDialogVisible } = this.state;
    const formInfo = this.props.currentMeetup;
    return (
      <div className="meetup-create">
        <div className="meetup-create__wrapper">
          <Tabs
            value={currentTab}
            onChange={this.handleTabChange}
            variant="fullWidth"
            indicatorColor="primary"
            centered
          >
            <Tab
              className="meetup-create__first-tab"
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {<ActiveFirstIcon />}
                  <span className="meetup-create__tab-text">
                    <FormattedMessage id="meetupList.meetupCreate.requiredFields" />
                  </span>
                </div>
              }
            />
            <Tab
              className="meetup-create__second-tab"
              disabled={this.disableCheck()}
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ActiveSecondIcon
                    color={currentTab === 0 && this.disableCheck() ? '#979797' : '#314ad7'}
                    fontColor={currentTab === 0 && this.disableCheck() ? '#000000' : '#FFFFFF'}
                  />
                  <span className="meetup-create__tab-text">
                    <FormattedMessage id="meetupList.meetupCreate.additionalFields" />
                  </span>
                </div>
              }
            />
          </Tabs>
          <h1 className="meetup-create__page-heading">
            <FormattedMessage id="meetupList.meetupCreate.title" />
          </h1>
          <div className="meetup-create__description">
            <FormattedMessage id="meetupList.meetupCreate.description" />
          </div>
          {currentTab === 0 && (
            <TabContainer>
              <MeetupCreateRequiredForm
                intl={intl}
                onChange={this.onChange}
                formInfo={formInfo}
                searchSpeaker={this.searchSpeaker}
              />
              <Card className="meetup-create__actions card card_controls card_controls-form">
                <CardActions className="card__actions">
                  <Link to={RouteUrls.MeetupList}>
                    <CustomButton
                      className="button button_outlined"
                      variant="outlined"
                      onClick={this.handleGoBack}
                      label={intl.formatMessage({
                        id: 'meetupList.meetupCreate.button.goBack',
                      })}
                    />
                  </Link>
                  <CustomButton
                    className="button button_contained-violet  margin-left-10"
                    variant="contained"
                    onClick={() =>
                      formInfo.subject.length > 500 ? this.openDialog() : this.setState({ currentTab: 1 })
                    }
                    disabled={this.disableCheck()}
                    label={intl.formatMessage({
                      id: 'meetupList.meetupCreate.button.goNext',
                    })}
                  />
                </CardActions>
              </Card>
            </TabContainer>
          )}
          {currentTab === 1 && (
            <TabContainer>
              <MeetupCreateAdditionalForm
                intl={intl}
                onChange={this.onChange}
                changeImageInfo={this.changeImageInfo}
                formInfo={formInfo}
                defaultImages={defaultImages}
                defaultImageIds={defaultImageIds}
                imageInfo={imageInfo}
              />
              <Card className="meetup-create__actions card card_controls card_controls-form">
                <CardActions className="card__actions">
                  <CustomButton
                    className="button button_outlined"
                    variant="outlined"
                    onClick={() => this.setState({ currentTab: 0 })}
                    label={intl.formatMessage({
                      id: 'meetupList.meetupCreate.button.goBack',
                    })}
                  />
                  <CustomButton
                    className="button button_contained-violet margin-left-10"
                    variant="contained"
                    disabled={this.disableCheck()}
                    onClick={this.createMeetup}
                    label={intl.formatMessage({
                      id: 'meetupList.meetupCreate.button.create',
                    })}
                  />
                </CardActions>
              </Card>
            </TabContainer>
          )}
        </div>
        <DialogModal
          handleConfirm={() => {}}
          handleClose={this.closeDialog}
          titleTextId="editor.subjectValidation.warningTitle"
          textId="editor.subjectValidation.warningText"
          open={isDialogVisible}
          showButtons={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    currentMeetup: state.currentMeetup,
    defaultImages: state.currentMeetup.defaultImages,
    defaultImageIds: state.currentMeetup.defaultImageIds,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createMeetup: bindActionCreators(createMeetup, dispatch),
    updateMeetupData: bindActionCreators(updateMeetupData, dispatch),
    resetMeetupData: bindActionCreators(resetMeetupData, dispatch),
    searchSpeaker: bindActionCreators(searchUser, dispatch),
    getDefaultImages: bindActionCreators(getDefaultImagesMeetup, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MeetupCreate),
);
