import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchUnauthorized } from 'containers/Auth/Auth.actions';
import LogoutButton from '../Auth/LogoutButton';
import { getAADAccount } from '../../selectors/storeSelectors';

interface IUnauthorizedProps {
  fetchUnauthorized: () => void;
  aad?: any;
}

class UnauthorizedPage extends React.Component<IUnauthorizedProps> {
  public timer: number | undefined;

  public render() {
    console.log('render', this.props.aad);
    return (
      <div className="page-not-found__wrapper">
        <h1>
          <FormattedMessage id="unauthorizedPage.title" />
        </h1>
        <h2>
          <FormattedMessage id="unauthorizedPage.loginFormat" />
        </h2>
        {this.props.aad && (
          <h2>
            <FormattedMessage id="unauthorizedPage.yourLogin" values={{ currentLogin: this.props.aad.userName }} />
          </h2>
        )}

        <LogoutButton />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchUnauthorized: bindActionCreators(fetchUnauthorized, dispatch),
  };
}

const mapStateToProps = (state: any) => ({
  aad: getAADAccount(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnauthorizedPage);
