import {
  CREATE_MEETUP,
  CREATE_MEETUP_SUCCESS,
  CREATE_MEETUP_ERROR,
  UPDATE_MEETUP_DATA,
  RESET_CURRENT_MEETUP,
  SEARCH_USER,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_ERROR,
  GET_DEFAULT_IMAGES_MEETUP,
  GET_DEFAULT_IMAGES_MEETUP_ERROR,
  GET_DEFAULT_IMAGES_MEETUP_SUCCESS,
} from 'constants/actionConstants';
import IMeetupAuthor from 'models/IMeetupAuthor';
import { MeetupFieldsValuesType } from 'constants/customTypes';

export function createMeetup() {
  return { type: CREATE_MEETUP };
}

export function createMeetupSuccess(meetupId: string) {
  return { type: CREATE_MEETUP_SUCCESS, payload: meetupId };
}

export function createMeetupError(error: {}) {
  return { type: CREATE_MEETUP_ERROR, payload: error };
}

export function updateMeetupData(payload: { name: string; value: MeetupFieldsValuesType; index?: number }) {
  return { type: UPDATE_MEETUP_DATA, payload };
}

export function resetMeetupData() {
  return { type: RESET_CURRENT_MEETUP };
}

export function searchUser(query: string) {
  return { type: SEARCH_USER, payload: query };
}

export function searchUserSuccess(searchResults: IMeetupAuthor[]) {
  return { type: SEARCH_USER_SUCCESS, payload: searchResults };
}

export function searchUserError(error: {}) {
  return { type: SEARCH_USER_ERROR, payload: error };
}

export function getDefaultImagesMeetup() {
  return { type: GET_DEFAULT_IMAGES_MEETUP };
}

export function getDefaultImagesMeetupSuccess(meetupData: any) {
  return { type: GET_DEFAULT_IMAGES_MEETUP_SUCCESS, payload: meetupData };
}

export function getDefaultImagesMeetupError(error: {}) {
  return { type: GET_DEFAULT_IMAGES_MEETUP_ERROR, payload: error };
}
