import { call, put, takeEvery } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import { DELETE_THEME } from 'constants/actionConstants';
import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import { fetchThemesList } from '../ThemeList.actions';

function* deleteThemeAsync({ payload }: any) {
  try {
    yield call(async () => {
      return await ApiService.themes().delete(payload.id);
    });
    NotificationManager.success(TranslateService.translate('themeList.themeDelete.deleteSuccess'));
    yield put(fetchThemesList());
  } catch (error) {
    NotificationManager.error(TranslateService.translate('themeList.themeDelete.deleteError'));
  }
}

export const deleteThemeSagas = [takeEvery(DELETE_THEME, deleteThemeAsync)];
