import * as React from 'react';
import { saveMeetabLinkUrl, fetchMeetupsList, clearDeletedMeetup, clearSelectedMeetup } from '../MeetupList.actions';
import { injectIntl, IntlShape } from 'react-intl';
import { IStore } from '../../../store/rootReducer';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import IMeetupShort from '../../../models/IMeetupShort';
import MeetupCard from 'containers/MeetupList/MeetupCard/MeetupCard';
import { TabContainer } from '../MeetupList';
import MeetupListCounter from '../MeetupListCounter';
import { getMeetupSelector } from '../../../utils/meetupSelector';
import history from '../../../utils/history';
import PacmanLoader from '../../../components/PacmanLoader/PacmanLoader';
import { WindowScroller, CellMeasurer, CellMeasurerCache, List, AutoSizer } from 'react-virtualized';

interface IPastMeetupProps {
  fetchMeetupsList: (filter: { status: string; filter: string }) => {};
  intl: IntlShape;
  isOverMeetups: IMeetupShort[];
  goBackUrl: string;
  saveMeetabLinkUrl: (goBackUrl: string) => {};
  selectedMeetup: string;
  restoreScroll: (meetup: IMeetupShort[], selectedMeetup: string) => void;
  idForDelete: string;
  clearDeletedMeetup: () => {};
  clearSeletedMeetup: () => {};
}

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 200,
});

class PastMeetup extends React.Component<IPastMeetupProps, {}> {
  public componentDidMount(): void {
    const listParams = getMeetupSelector();
    this.props.fetchMeetupsList(listParams);
    this.props.saveMeetabLinkUrl(history.location.pathname);
  }

  public componentDidUpdate(): void {
    const { selectedMeetup, isOverMeetups, idForDelete } = this.props;
    if (idForDelete) {
      this.props.restoreScroll(isOverMeetups, idForDelete);
    } else if (selectedMeetup) {
      this.props.restoreScroll(isOverMeetups, selectedMeetup);
    } else {
      window.scrollTo(0, 0);
    }
  }

  public componentWillUnmount(): void {
    this.props.clearDeletedMeetup();
    this.props.clearSeletedMeetup();
  }

  renderRow = ({ index, style, key, parent }: any) => {
    const pastMeetup = this.props.isOverMeetups[index];
    return (
      <>
        <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
          <div style={style} key={key}>
            <TabContainer>
              <MeetupCard key={pastMeetup.id} meetup={pastMeetup} intl={this.props.intl} />
            </TabContainer>
          </div>
        </CellMeasurer>
      </>
    );
  };

  render() {
    const { isOverMeetups } = this.props;
    return (
      <>
        {isOverMeetups.length ? (
          <div>
            <MeetupListCounter count={isOverMeetups.length} label={'meetupList.counterIsOver'} />
            <div style={{ width: '100%', height: '100vh' }}>
              <WindowScroller>
                {({ height, scrollTop, isScrolling, onChildScroll }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <List
                        autoHeight
                        height={height}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                        width={width}
                        rowRenderer={this.renderRow}
                        rowCount={isOverMeetups.length}
                        deferredMeasurementCache={cache}
                        rowHeight={cache.rowHeight}
                      />
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>
            </div>
          </div>
        ) : (
          <PacmanLoader />
        )}
      </>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    isOverMeetups: state.meetups.isOverMeetups,
    selectedMeetup: state.meetups.selectedMeetup,
    goBackUrl: state.meetups.goBackUrl,
    idForDelete: state.meetups.idForDelete,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchMeetupsList: bindActionCreators(fetchMeetupsList, dispatch),
    saveMeetabLinkUrl: bindActionCreators(saveMeetabLinkUrl, dispatch),
    clearDeletedMeetup: bindActionCreators(clearDeletedMeetup, dispatch),
    clearSeletedMeetup: bindActionCreators(clearSelectedMeetup, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PastMeetup),
);
