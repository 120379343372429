import {
  CREATE_NEWS,
  CREATE_NEWS_SUCCESS,
  CREATE_NEWS_ERROR,
  UPDATE_NEWS_DATA,
  RESET_CURRENT_NEWS,
  GET_DEFAULT_IMAGES_NEWS,
  GET_DEFAULT_IMAGES_NEWS_SUCCESS,
  GET_DEFAULT_IMAGES_NEWS_ERROR,
} from 'constants/actionConstants';
import { NewsFieldsValuesType } from 'constants/customTypes';

export function createNews() {
  return { type: CREATE_NEWS };
}

export function createNewsSuccess(newsId: string) {
  return { type: CREATE_NEWS_SUCCESS, payload: newsId };
}

export function createNewsError(error: {}) {
  return { type: CREATE_NEWS_ERROR, payload: error };
}

export function updateNewsData(payload: { name: string; value: NewsFieldsValuesType }) {
  return { type: UPDATE_NEWS_DATA, payload };
}

export function resetNewsData() {
  return { type: RESET_CURRENT_NEWS };
}

export function getDefaultImagesNews() {
  return { type: GET_DEFAULT_IMAGES_NEWS };
}

export function getDefaultImagesNewsSuccess(meetupData: any) {
  return { type: GET_DEFAULT_IMAGES_NEWS_SUCCESS, payload: meetupData };
}

export function getDefaultImagesNewsError(error: {}) {
  return { type: GET_DEFAULT_IMAGES_NEWS_ERROR, payload: error };
}
