import { takeEvery, put, select, call } from 'redux-saga/effects';

import { FETCH_ACCESS_ROUTE } from 'constants/actionConstants';
import { RouteUrls } from 'constants/RouteUrls';

import IAppUser from 'models/IAppUser';

import ApiService from 'services/ApiService';
import { getCurrentUser } from 'selectors/storeSelectors';

import { fetchAccessDenied, fetchAccessGrunted } from './PrivateRoute.actions';
import { isRoleEnough } from './PrivateRoute.rules';
import { canEditMeetup, isAuthor } from 'utils/userRules';
import IMeetupShort from 'models/IMeetupShort';

function* fetchMeetup(id: string) {
  const meetup = yield call(async () => {
    return await ApiService.meetUps().getById(id);
  });
  return meetup.data;
}

function* fetchTheme(id: string) {
  const theme = yield call(async () => {
    return await ApiService.themes().getById(id);
  });
  return theme.data;
}

function* fetchRouteAccessAsync({ payload }: any) {
  const { path, id } = payload;
  const user: IAppUser = yield select(getCurrentUser);

  let access = isRoleEnough(path, user.roles);

  if (path === RouteUrls.EditMeetup) {
    const meetup: IMeetupShort = yield fetchMeetup(id);
    const { author, speakers, status, isOver } = meetup;
    const speaker = speakers[0] || author;
    access = canEditMeetup(speaker, author, status, isOver, user);
  }

  if (path === RouteUrls.EditTheme) {
    const theme: IMeetupShort = yield fetchTheme(id);
    access = isRoleEnough(path, user.roles) || isAuthor(theme.author, user.id);
  }

  access ? yield put(fetchAccessGrunted()) : yield put(fetchAccessDenied());
}

export const privateRouteSagas = [takeEvery(FETCH_ACCESS_ROUTE, fetchRouteAccessAsync)];
