import { FETCH_USER_PHOTO_ERROR, FETCH_USER_PHOTO_SUCCESS } from 'constants/actionConstants';

export interface IUserPhoto {
  [userId: string]: any;
}

export default function userPhotoReducer(
  state: IUserPhoto[] = [],
  action: { type: string; payload: IUserPhoto[] },
): IUserPhoto {
  switch (action.type) {
    case FETCH_USER_PHOTO_SUCCESS: {
      return [ ...state, ...action.payload ];
    }
    case FETCH_USER_PHOTO_ERROR: {
      return [ ...state, ...action.payload ];
    }
    default:
      return state;
  }
}
