import * as React from 'react';
import IMemo from '../../../models/IMemo';
import { descriptionLimit, themeLimit } from '../../../constants/inputValueLimits';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CustomInput from '../../../components/CustomInput/CustomInput';
import RichEditor from '../../../components/Editor/RichEditor';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { deleteMemo, updateMemoData } from './MemoEdit.actions';
import { MemoFieldsValuesType } from '../../../constants/customTypes';
import arrow from 'assets/images/icons/arrow.png';
import { triggerTypes } from '../../../constants/triggerTypes';
import { mediaTypes } from '../../../constants/mediaTypes';
import DeleteModal from '../../../components/Modal/DeleteModal';
import { fromRawDraftContentStateToString } from '../../../utils/draft.utils';

interface MemoEditItemProps {
  memoInfo: IMemo;
  intl: IntlShape;
  updateMemoData: (payload: { name: string; value: MemoFieldsValuesType; id?: number }) => void;
  id: number;
  deleteMemo: (id: number) => void;
  isDraggable: boolean;
}

interface MemoEditItemState {
  isDeletion: boolean;
}

class MemoEditItem extends React.Component<MemoEditItemProps, MemoEditItemState> {
  state = {
    isDeletion: false,
  };

  public handleOnChange = (name: string, value: MemoFieldsValuesType, id?: number) => {
    this.props.updateMemoData({ name, value, id: this.props.id });
  };

  public changeSubjectValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.handleOnChange(name, value);
  };

  public handleDelete = () => {
    this.setState({ isDeletion: true });
    this.props.deleteMemo(this.props.id);
    setTimeout(() => this.setState({ isDeletion: false }), 0);
  };

  public checkIsBodyEmpty = (): boolean => {
    const modifiedBody = fromRawDraftContentStateToString(this.props.memoInfo.body!);
    return (modifiedBody && modifiedBody.includes('')) || modifiedBody === '';
  };

  canDragRichText = (): boolean => {
    const { isDraggable } = this.props;
    const { isDeletion } = this.state;
    return isDraggable || isDeletion;
  };

  public render() {
    const { intl } = this.props;
    const { body, subject } = this.props.memoInfo;
    return (
      <>
        <Card className="card card_memo-form">
          <div className="memo-main-actions theme-card__header__buttons">
            <span>
              <DeleteModal
                handleDelete={this.handleDelete}
                mediaType={mediaTypes.Memo}
                triggerType={triggerTypes.Icon}
                isConfirmationNeeded={!!subject || !this.checkIsBodyEmpty()}
              />
            </span>
            <div>
              <img src={arrow} alt="arrow" className="img-arrow" />
            </div>
          </div>

          <CardContent>
            <form>
              <CustomInput
                intl={intl}
                maxLength={themeLimit}
                label={intl.formatMessage({
                  id: 'memo.memoEdit.subject',
                })}
                className="theme"
                multiline={false}
                onChange={this.changeSubjectValue}
                rows={1}
                name={'subject'}
                value={subject!}
              />
              <RichEditor
                intl={intl}
                readonly={false}
                onChange={this.handleOnChange}
                maxLength={descriptionLimit}
                value={body}
                isDraggable={this.canDragRichText()}
              />
            </form>
          </CardContent>
        </Card>
      </>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateMemoData: bindActionCreators(updateMemoData, dispatch),
    deleteMemo: bindActionCreators(deleteMemo, dispatch),
  };
}

export default injectIntl(
  connect(
    null,
    mapDispatchToProps,
  )(MemoEditItem),
);
