import * as React from 'react';
import classNames from 'classnames';
import IMeetupShort from 'models/IMeetupShort';
import MeetupCardContent from './MeetupCardContent/MeetupCardContent';
import MeetupCardFooter from './MeetupCardFooter/MeetupCardFooter';
import MeetupCardHeader from './MeetupCardHeader/MeetupCardHeader';
import { Link } from 'react-router-dom';
import { meetupStatus } from 'constants/meetupStatus';
import { IntlShape } from 'react-intl';
import { RouteUrls } from 'constants/RouteUrls';
import { replaceUrlParams } from 'utils/routeUrls';

interface IMeetupCardInterfaceProps {
  meetup: IMeetupShort;
  intl?: IntlShape;
}

const MeetupCard = ({ meetup, intl }: IMeetupCardInterfaceProps) => {
  const { subject, excerpt, author, speakers, id, status, go } = meetup;
  const speaker = speakers ? speakers[0] : author;
  return (
    <div id={id} className={classNames('meetup-card', { 'green-border': status === meetupStatus.Draft })}>
      <MeetupCardHeader meetup={meetup} intl={intl} go={go} />
      <Link to={replaceUrlParams(RouteUrls.PreviewEvent, { id })}>
        <MeetupCardContent subject={subject} excerpt={excerpt.replace(/&nbsp;/g, '')} />
      </Link>
      <MeetupCardFooter user={speaker} />
    </div>
  );
};

export default MeetupCard;
