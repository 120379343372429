import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import { call, put, takeEvery } from 'redux-saga/effects';
import { APPROVE_THEME } from 'constants/actionConstants';
import { NotificationManager } from 'react-notifications';
import history from 'utils/history';
import { RouteUrls } from 'constants/RouteUrls';
import { approveThemeError, approveThemeSuccess } from './ThemeApprove.actions';
import { fetchThemesList } from '../ThemeList.actions';

function* approveThemeAsync({ payload }: any) {
  try {
    yield call(async () => {
      return await ApiService.meetUps().approve(payload);
    });
    yield put(approveThemeSuccess());
    NotificationManager.success(TranslateService.translate('meetupList.themeApprove.approveSuccess'));
    if (history.location.pathname !== RouteUrls.Themes) {
      history.push(RouteUrls.Themes);
    }
    yield put(fetchThemesList());
  } catch (error) {
    yield put(approveThemeError(error));
  }
}

export const approveThemeSagas = [takeEvery(APPROVE_THEME, approveThemeAsync)];
