import * as React from 'react';

class PacmanLoader extends React.Component {
  public render() {
    return (
      <div className="la-pacman la-3x">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
}

export default PacmanLoader;
