import React, { memo } from 'react';

const CloseIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.586 7L.293 1.707A1 1 0 011.707.293L7 5.586 12.293.293a1 1 0 111.414 1.414L8.414 7l5.293 5.293a1 1 0 01-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 01-1.414-1.414L5.586 7z"
      fill="#C2CBDA"
    />
  </svg>
);

export default memo(CloseIcon);
