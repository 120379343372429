import classNames from 'classnames';
import React from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  RawDraftContentState,
  convertToRaw,
  convertFromRaw,
  SelectionState,
  CompositeDecorator,
  Modifier,
} from 'draft-js';
import { IntlShape } from 'react-intl';
import LinkDecorator from './LinkDecorator';
import { fieldNames } from 'constants/fieldNames';
import RichToolbar from './RichToolbar';
import clearFormatting from './clearFormatting';
import { getStateWithMaxLength } from 'utils/draft.utils';
import history from 'utils/history';

interface IRichEditorProps {
  readonly: boolean;
  intl: IntlShape;
  value?: RawDraftContentState;
  maxLength?: number;
  onChange?: (name: string, value: RawDraftContentState) => void;
  isDraggable?: boolean;
}

interface IRichEditorState {
  editorState: EditorState;
  isFocused: boolean;
  location?: string;
}

const decorator = new CompositeDecorator([LinkDecorator]);

export default class RichEditor extends React.Component<IRichEditorProps, IRichEditorState> {
  public state = {
    editorState: this.props.value
      ? EditorState.createWithContent(convertFromRaw(this.props.value), decorator)
      : EditorState.createEmpty(decorator),
    isSelection: false,
    isFocused: false,
    location: window.location.pathname,
  };

  public editorRef = React.createRef<Editor>();

  public setLink = (link: { text: string; value: string }) => {
    let { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: link.value });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const selection = editorState.getSelection();

    let newEditorState = EditorState.push(editorState, contentStateWithEntity, 'apply-entity');
    newEditorState = RichUtils.toggleLink(editorState, selection, entityKey);
    newEditorState = EditorState.acceptSelection(editorState, selection);
    newEditorState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);

    const contentStateWithModifiedText = Modifier.replaceText(
      newEditorState.getCurrentContent(),
      newEditorState.getSelection(),
      link.text.length > 0 ? link.text : link.value,
      newEditorState.getCurrentInlineStyle(),
      entityKey,
    );
    newEditorState = EditorState.set(newEditorState, {
      currentContent: contentStateWithModifiedText,
    });
    this.onChange(newEditorState);
  };

  public onChange = (editorState: EditorState) => {
    const { maxLength } = this.props;
    this.setState(state => {
      const fixedEditorState = !maxLength
        ? editorState
        : getStateWithMaxLength(state.editorState, editorState, maxLength);
      this.props.onChange!(fieldNames.description, convertToRaw(fixedEditorState.getCurrentContent()));
      this.props.onChange!(fieldNames.body, convertToRaw(fixedEditorState.getCurrentContent()));
      const isSelectionCollapsed = fixedEditorState.getSelection().isCollapsed();

      return {
        editorState: fixedEditorState,
        isSelection: !isSelectionCollapsed,
        isFocused: true,
      };
    });
  };

  public handleKeyCommand(command: any, editorState: EditorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  public onStyleChange = (event: React.MouseEvent<HTMLButtonElement>, style: string) => {
    event.preventDefault();
    const { editorState } = this.state;
    const newEditorState = RichUtils.toggleInlineStyle(editorState, style);
    this.onChange(newEditorState);
  };

  public onStyleTypeChange = (event: React.MouseEvent<HTMLButtonElement>, style: string) => {
    event.preventDefault();
    const { editorState } = this.state;
    const editorStateFocused = EditorState.forceSelection(editorState, editorState.getSelection());
    this.onChange(RichUtils.toggleBlockType(editorStateFocused, style));
  };

  public selectAllEditorState() {
    const { editorState } = this.state;
    const currentContent = editorState.getCurrentContent();
    const firstBlock = currentContent.getBlockMap().first();
    const lastBlock = currentContent.getBlockMap().last();
    const firstBlockKey = firstBlock.getKey();
    const lastBlockKey = lastBlock.getKey();
    const lengthOfLastBlock = lastBlock.getLength();

    const selection = new SelectionState({
      anchorKey: firstBlockKey,
      anchorOffset: 0,
      focusKey: lastBlockKey,
      focusOffset: lengthOfLastBlock,
    });

    return EditorState.acceptSelection(editorState, selection);
  }

  public cleanFormat = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { isSelection, editorState } = this.state;
    const newEditorState = isSelection
      ? clearFormatting(editorState, true)
      : clearFormatting(this.selectAllEditorState(), true);
    const editorStateFocused = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    this.onChange(editorStateFocused);
  };

  public getLocation = (): string => {
    const { location } = this.state;
    let id;
    location.includes('News')
      ? (id = 'newsList.newsCreate.labelDescription')
      : location.includes('Meetup')
      ? (id = 'meetupList.meetupCreate.placeholderDescription')
      : location.includes('Theme')
      ? (id = 'themeList.themeCreate.placeholderDescription')
      : (id = 'memo.memoEdit.placeholderDescription');
    return id;
  };

  componentDidUpdate(prevProps: IRichEditorProps) {
    if (this.props.isDraggable && prevProps.value !== this.props.value) {
      this.setState(() => ({
        editorState: this.props.value
          ? EditorState.createWithContent(convertFromRaw(this.props.value), decorator)
          : EditorState.createEmpty(decorator),
      }));
    }
  }

  componentWillReceiveProps(nextProps: IRichEditorProps) {
    if (window.location.pathname !== this.state.location) {
      this.setState(() => ({
        editorState: nextProps.value
          ? EditorState.createWithContent(convertFromRaw(nextProps.value), decorator)
          : EditorState.createEmpty(decorator),
      }));
    }
  }

  public render() {
    const { intl, readonly } = this.props;
    const { isSelection, editorState, isFocused, location } = this.state;
    return (
      <>
        {!readonly && (
          <div className="input-label">
            {intl.formatMessage({
              id: location.toLowerCase().includes('editmemo')
                ? 'memo.memoEdit.body'
                : 'meetupList.meetupCreate.labelDescription',
            })}
          </div>
        )}
        <div
          tabIndex={0}
          className={
            !history.location.pathname.includes('create') && !history.location.pathname.includes('edit')
              ? classNames('DraftEditor__container', { edit: !readonly })
              : classNames('DraftEditor__container container-height', { edit: !readonly })
          }
          onFocus={() => this.setState({ isFocused: !isFocused })}
          onBlur={() => this.setState({ isFocused: !isFocused })}
        >
          <RichToolbar
            readonly={readonly}
            onStyleChange={this.onStyleChange}
            onStyleTypeChange={this.onStyleTypeChange}
            cleanFormat={this.cleanFormat}
            setLink={this.setLink}
            editorState={editorState}
            onChange={this.onChange}
            isSelection={isSelection}
          />
          <div className={classNames('DraftEditor__editor', { edit: !readonly })}>
            <Editor
              editorState={editorState}
              placeholder={
                readonly
                  ? ''
                  : intl.formatMessage({
                      id: this.getLocation(),
                    })
              }
              readOnly={readonly}
              onChange={this.onChange}
              ref={this.editorRef}
              handleKeyCommand={this.handleKeyCommand}
              stripPastedStyles={true}
            />
          </div>
        </div>
      </>
    );
  }
}
