import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import CustomButton from 'components/CustomButton/CustomButton';
import { Link } from 'react-router-dom';
import IAdminOption from 'models/IAdminPanel';
import { adminOptions } from 'constants/adminConstants';
import { IStore } from 'store/rootReducer';
import { connect } from 'react-redux';
import IAppUser from 'models/IAppUser';
import { isChief } from 'utils/userRules';

interface IAdminPanelProps {
  intl: IntlShape;
  user: IAppUser;
}

class AdminPanel extends React.Component<IAdminPanelProps> {
  public render() {
    const { intl, user } = this.props;
    const isUserChief = isChief(user);
    return (
      <div className="admin-panel__wrapper">
        <div className="admin-panel__header">
          <span className="admin-panel__header__heading">
            <FormattedMessage id="adminPanel.title" />
          </span>
        </div>
        {isUserChief && (
          <ul className="admin-panel__list">
            {adminOptions.map((option: IAdminOption) => {
              return (
                <li className="admin-panel__list-item" key={option.title}>
                  <Link to={option.route}>
                    <CustomButton
                      className="button admin-button button_outlined-violet"
                      variant="outlined"
                      disabled={option.disabled}
                      label={intl.formatMessage({ id: option.title })}
                      tooltipText={option.tooltip}
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
function mapStateToProps(state: IStore) {
  return {
    user: state.userInfo.user,
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    null,
  )(AdminPanel),
);
