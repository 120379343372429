import {
  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_ERROR
} from 'constants/actionConstants';

export function deleteNews(newsId: string) {
  return { type: DELETE_NEWS, payload: newsId };
}

export function deleteNewsSuccess() {
  return { type: DELETE_NEWS_SUCCESS };
}

export function deleteNewsError(error: {}) {
  return {
    type: DELETE_NEWS_ERROR,
    payload: error
  };
}
