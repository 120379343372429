import { RouteUrls } from 'constants/RouteUrls';
import { UserRoles } from 'constants/UserRoles';

const ROUTE_ROLES = {
  [RouteUrls.CreateMeetup]: [UserRoles.CHIEF],
  [RouteUrls.CreateNews]: [UserRoles.NEWS_PUBLISHER],
  [RouteUrls.EditMeetup]: [UserRoles.CHIEF],
  [RouteUrls.EditNews]: [UserRoles.NEWS_PUBLISHER],
  [RouteUrls.EditTheme]: [UserRoles.CHIEF],
};

export function isRoleEnough(path: RouteUrls, userRoles: UserRoles[]): boolean {
  return ROUTE_ROLES[path].every((role: UserRoles) => userRoles.includes(role));
}
