import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import IMemo from '../../../models/IMemo';
import { connect } from 'react-redux';
import { IStore } from '../../../store/rootReducer';
import { getMemo } from '../MemoPreview/Memo.actions';
import { bindActionCreators, Dispatch } from 'redux';
import { addMemo, editMemo, moveMemoItem, resetMemoData } from './MemoEdit.actions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MemoEditItem from './MemoEditItem';
import { RouteUrls } from '../../../constants/RouteUrls';
import { Link } from 'react-router-dom';

interface IMemoEditProps {
  intl: IntlShape;
  memoInfo: IMemo[];
  getMemo: () => void;
  editMemo: (currentMemo: IMemo[]) => {};
  addMemo: () => void;
  moveMemoItem: (sourceIndex: number, destinationIndex: number) => void;
  resetMemoData: () => {};
}

interface IMemoEditState {
  isDraggable: boolean;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  cursor: 'inherit',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : '',
});

class MemoEdit extends React.Component<IMemoEditProps, IMemoEditState> {
  state = {
    isDraggable: false,
  };

  componentDidMount() {
    this.props.getMemo();
  }

  componentWillUnmount() {
    this.props.resetMemoData();
  }

  editMemo = () => {
    this.props.editMemo(this.props.memoInfo);
  };

  addMemo = () => {
    this.props.addMemo();
  };

  setDragAndDrop = (value: boolean) => {
    this.setState({ isDraggable: value });
  };

  onDragEnd = (result: any) => {
    this.setDragAndDrop(true);
    if (!result.destination) {
      return;
    }
    this.props.moveMemoItem(result.source.index, result.destination.index);
    setTimeout(this.setDragAndDrop.bind(null, false), 0);
  };

  render() {
    const memoInfo = this.props.memoInfo;

    return (
      <div className="page-content meetup-edit">
        <h1 className="page-heading">
          <FormattedMessage id="memo.memoPreview.topicTitle" />
        </h1>
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="roppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {memoInfo.map((memoInfo: IMemo, index: number) => (
                    <Draggable key={memoInfo.id} draggableId={memoInfo.id || index.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <MemoEditItem memoInfo={memoInfo} id={index} isDraggable={this.state.isDraggable} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <span onClick={this.addMemo} className="add-memo-button plus"></span>
        <div className="card card__controls card__actions">
          <Link to={RouteUrls.MemoManagement}>
            <button className="button button_outlined-violet">
              <FormattedMessage id="memo.memoEdit.button.cancel" />
            </button>
          </Link>
          <div className="card__main-actions">
            <button className="button xl_button publish-button" onClick={this.editMemo}>
              <FormattedMessage id="memo.memoEdit.button.save" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    memoInfo: state.memoInfo.memoInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getMemo: bindActionCreators(getMemo, dispatch),
    editMemo: bindActionCreators(editMemo, dispatch),
    addMemo: bindActionCreators(addMemo, dispatch),
    moveMemoItem: bindActionCreators(moveMemoItem, dispatch),
    resetMemoData: bindActionCreators(resetMemoData, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MemoEdit),
);
