import { call, put, takeEvery } from 'redux-saga/effects';
import { DISLIKE_THEME, LIKE_THEME } from 'constants/actionConstants';
import ApiService from 'services/ApiService';
import { fetchTheme } from '../Theme.actions';
import { editThemeError } from '../ThemeEdit/ThemeEdit.actions';
import { NotificationManager } from 'react-notifications';
import TranslateService from 'services/TranslateService';
import history from '../../../utils/history';
import { RouteUrls } from '../../../constants/RouteUrls';
import { fetchThemesList } from '../ThemeList.actions';

function* likeThemeAsync({ payload }: any) {
  try {
    yield call(async () => {
      return await ApiService.themes().like(payload.id);
    });
    if (history.location.pathname === RouteUrls.Themes) {
      yield put(fetchThemesList());
    } else if (history.location.pathname.includes('previewTheme')) {
      yield put(fetchTheme(payload.id));
    }
  } catch (error) {
    yield put(editThemeError(error));
    NotificationManager.error(TranslateService.translate('theme.edit.error'));
  }
}
function* dislikeThemeAsync({ payload }: any) {
  try {
    yield call(async () => {
      return await ApiService.themes().dislike(payload.id);
    });
    if (history.location.pathname === RouteUrls.Themes) {
      yield put(fetchThemesList());
    } else if (history.location.pathname.includes('previewTheme')) {
      yield put(fetchTheme(payload.id));
    }
  } catch (error) {
    yield put(editThemeError(error));
    NotificationManager.error(TranslateService.translate('theme.edit.error'));
  }
}

export const likeThemeSagas = [takeEvery(LIKE_THEME, likeThemeAsync), takeEvery(DISLIKE_THEME, dislikeThemeAsync)];
