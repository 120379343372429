import * as IntlComponentWrapActions from './IntlWrapComponent.actions';
import * as React from 'react';
import ruTranslationMessages from 'translations/ru.json';
import { IntlProvider } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IStore } from 'store/rootReducer';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';

interface ILocaleProps {
  currentLocale: string;
  IntlComponentWrapActions: any;
  ru: object;
}

class IntlComponentWrap extends React.Component<ILocaleProps, {}> {
  public render() {
    const messages = this.props[this.props.currentLocale];
    return (
      <IntlProvider locale={this.props.currentLocale} messages={messages} key={this.props.currentLocale}>
        {this.props.children}
      </IntlProvider>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    currentLocale: state.locale.currentLocale,
    ru: ruTranslationMessages,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    IntlComponentWrapActions: bindActionCreators(IntlComponentWrapActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntlComponentWrap);
