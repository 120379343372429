import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { EDIT_NEWS } from 'constants/actionConstants';
import { editNewsError, editNewsSuccess } from './NewsEdit.actions';
import { getCurrentNews } from 'selectors/storeSelectors';
import { NotificationManager } from 'react-notifications';
import { mediaTypes } from 'constants/mediaTypes';
import { fieldNames } from 'constants/fieldNames';
import { RouteUrls } from 'constants/RouteUrls';
import { descriptionLimit } from 'constants/inputValueLimits';
import { fixDescription } from 'utils/draft.utils';
import { replaceUrlParams } from 'utils/routeUrls';
import history from 'utils/history';

function* editNewsAsync() {
  try {
    const payload = yield select(getCurrentNews);
    const { id, subject, tmpImageOrig, picId } = payload;
    const description = fixDescription(payload.description, descriptionLimit);

    yield call(async () => {
      return await ApiService.news().edit(payload.id, { subject, description, picId });
    });
    if (tmpImageOrig) {
      yield call(async () => {
        const formData = new FormData();
        formData.append(fieldNames.file, tmpImageOrig);
        return await ApiService.fileUpload(mediaTypes.News).meetupImageUpload(id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      });
    }
    yield put(editNewsSuccess());
    NotificationManager.success(TranslateService.translate('newsList.newsUpdate.updateSuccess'));
    yield history.push(replaceUrlParams(RouteUrls.PreviewNews, { id: payload.id }));
  } catch (error) {
    yield put(editNewsError(error));
  }
}

export const editNewsSagas = [takeEvery(EDIT_NEWS, editNewsAsync)];
