import {
  FETCH_NEWS_ERROR,
  FETCH_NEWS_SUCCESS,
  GET_DEFAULT_IMAGES_NEWS_ERROR,
  GET_DEFAULT_IMAGES_NEWS_SUCCESS,
  RESET_CURRENT_NEWS,
  UPDATE_NEWS_DATA,
} from 'constants/actionConstants';
import DOMPurify from 'dompurify';
import INewsDetails from 'models/INewsDetails';
import { stateFromHTML } from 'draft-js-import-html';
import { convertToRaw } from 'draft-js';

const initState: INewsDetails = {
  id: null,
  subject: '',
  excerpt: '',
  description: { blocks: [], entityMap: {} },
  postedDate: new Date(),
  image: null,
  tmpImageOrig: null,
  picId: null,
  defaultImages: [],
  defaultImageIds: [],
  isLikedByUser: false,
  likes: null,
};

export default function newsReducer(
  state: INewsDetails = initState,
  action: { type: string; name?: string; payload: any },
) {
  switch (action.type) {
    case FETCH_NEWS_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        image: action.payload.image,
        description: convertToRaw(stateFromHTML(DOMPurify.sanitize(action.payload.data.description))),
      };
    case FETCH_NEWS_ERROR:
      return { ...state };
    case RESET_CURRENT_NEWS:
      return { ...initState };
    case UPDATE_NEWS_DATA:
      return { ...state, [action.payload.name]: action.payload.value };
    case GET_DEFAULT_IMAGES_NEWS_SUCCESS:
      return {
        ...state,
        defaultImages: action.payload.defaultImages,
        defaultImageIds: action.payload.defaultImageIds,
      };
    case GET_DEFAULT_IMAGES_NEWS_ERROR:
      return { ...state };
    default:
      return state;
  }
}
