import { accountSagas } from 'containers/Auth/Auth.saga';
import { all } from 'redux-saga/effects';
import { createMeetupSagas } from 'containers/Meetup/MeetupCreate/MeetupCreate.saga';
import { editMeetupSagas } from 'containers/Meetup/MeetupEdit/MeetupEdit.saga';
import { intlSagas } from 'containers/IntlWrapComponent/IntlWrapComponent.saga';
import { meetupSagas } from 'containers/Meetup/MeetupPreview/MeetupPreview.saga';
import { meetupsSagas, themesSagas } from 'containers/MeetupList/MeetupList.saga';
import { deleteMeetupSagas } from 'containers/Meetup/MeetupDelete/MeetupDelete.saga';
import { newsListSagas } from 'containers/NewsList/NewsList.saga';
import { newsSagas } from 'containers/News/NewsPreview/NewsPreview.saga';
import { deleteNewsSagas } from 'containers/News/NewsDelete/NewsDelete.saga';
import { editNewsSagas } from 'containers/News/NewsEdit/NewsEdit.saga';
import { createNewsSagas } from 'containers/News/NewsCreate/NewsCreate.saga';
import { approveThemeSagas } from 'containers/Theme/ThemeApprove/ThemeApprove.saga';
import { deleteThemeSagas } from 'containers/Theme/ThemeDelete/ThemeDelete.saga';
import { themePreviewSagas } from 'containers/Theme/ThemePreview/ThemePreview.saga';
import { privateRouteSagas } from 'containers/PrivateRoute/PrivateRoute.saga';
import { themeCreateSagas } from 'containers/Theme/ThemeCreate/ThemeCreate.saga';
import { themeEditSagas } from 'containers/Theme/ThemeEdit/ThemeEdit.saga';
import { userPhotoSagas } from 'containers/UserAvatar/UserAvatar.saga';
import { goMeetupSagas } from 'containers/MeetupList/GoToMeetup.saga';
import { likeThemeSagas } from 'containers/Theme/ThemeLike/ThemeLike.saga';
import { notificationListSagas } from 'containers/NotificationList/NotificationList.saga';
import { likeNewsSagas } from 'containers/News/NewsLike/NewsLike.saga';
import { deleteFileSagas } from '../containers/Meetup/MeetupFile/FileDelete/FileDelete.saga';
import { downloadFileSagas } from '../containers/Meetup/MeetupFile/FileDonwload/FileDownload.saga';
import { downloadFileArchieveSagas } from '../containers/Meetup/MeetupFile/FileArchieveDownload/FileArchieveDownload.saga';
import { memoSagas } from '../containers/Memo/MemoPreview/Memo.saga';
import { memoEditSagas } from '../containers/Memo/MemoEdit/MemoEdit.saga';

export default function* rootSaga() {
  yield all([
    ...intlSagas,
    ...accountSagas,
    ...themesSagas,
    ...meetupsSagas,
    ...meetupSagas,
    ...createMeetupSagas,
    ...editMeetupSagas,
    ...deleteMeetupSagas,
    ...deleteThemeSagas,
    ...newsListSagas,
    ...newsSagas,
    ...deleteNewsSagas,
    ...editNewsSagas,
    ...createNewsSagas,
    ...approveThemeSagas,
    ...themePreviewSagas,
    ...privateRouteSagas,
    ...themeCreateSagas,
    ...themeEditSagas,
    ...userPhotoSagas,
    ...likeThemeSagas,
    ...goMeetupSagas,
    ...notificationListSagas,
    ...likeNewsSagas,
    ...deleteFileSagas,
    ...downloadFileSagas,
    ...downloadFileArchieveSagas,
    ...memoSagas,
    ...memoEditSagas,
  ]);
}
