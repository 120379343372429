import { LOCALE } from 'constants/localization';

import ruTranslationMessages from 'translations/ru.json';

class TranslateService {
  private locale = LOCALE.RU;

  public translate(key: string): string {
    let messages;
    switch (this.locale) {
      case LOCALE.RU:
        messages = ruTranslationMessages;
        break;
      default:
        messages = ruTranslationMessages;
    }
    return messages[key];
  }

  public setLocale(locale: string) {
    this.locale = locale;
  }
}

export default new TranslateService();
