import React, { memo } from 'react';

const EditIcon = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4 11.128V15.4C15.4 16.2836 14.6837 17 13.8 17H2.6C1.71634 17 1 16.2836 1 15.4V4.19998C1 3.31632 1.71634 2.59998 2.6 2.59998H6.872"
      stroke="#C2CBDA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7998 1L16.9998 4.2L8.9998 12.2H5.7998V9L13.7998 1Z"
      stroke="#C2CBDA"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(EditIcon);
