import { GET_MEMO, GET_MEMO_ERROR, GET_MEMO_SUCCESS } from '../../../constants/actionConstants';
import IMemo from '../../../models/IMemo';

export function getMemo() {
  return { type: GET_MEMO };
}

export function getMemoSuccess(memoInfo: IMemo[]) {
  return { type: GET_MEMO_SUCCESS, payload: memoInfo };
}

export function getMemoError(error: Error) {
  return { type: GET_MEMO_ERROR, payload: error };
}
