import * as React from 'react';
import CustomUserAvatar from 'components/CustomUserAvatar/CustomUserAvatar';
import IUser from 'models/IUser';

interface IMeetupCardFooterProps {
    user: IUser
}

const MeetupCardFooter = ({ user }: IMeetupCardFooterProps) => {
  return (
    <div className="meetup-card__footer">
      <CustomUserAvatar user={user} />
    </div>
  );
};

export default MeetupCardFooter;
