import * as React from 'react';
import IFile from 'models/IFile';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { deleteFile } from '../../MeetupFile/FileDelete/FileDelete.actions';
import { mediaTypes } from '../../../../constants/mediaTypes';
import { triggerTypes } from '../../../../constants/triggerTypes';
import DeleteModal from '../../../../components/Modal/DeleteModal';

interface IFileCard {
  file: IFile;
  deleteFile: (fileId: string) => {};
}

class FileCard extends React.Component<IFileCard, {}> {
  public deleteFile = () => {
    this.props.deleteFile(this.props.file.id);
  };

  public render() {
    const { id, name, size } = this.props.file;
    return (
      <li className="file-card" key={String(id)}>
        <div className="file-card__container">
          <div className="file-card__title">{name}</div>
          <div className="file-card__size">
            {size}
            <FormattedMessage id="loadedFile.mb" />
          </div>
          <DeleteModal
            handleDelete={this.deleteFile}
            mediaType={mediaTypes.File}
            triggerType={triggerTypes.Icon}
            isConfirmationNeeded={true}
          />
        </div>
      </li>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    deleteFile: bindActionCreators(deleteFile, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(FileCard);
