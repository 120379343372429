import { select, put, takeEvery } from 'redux-saga/effects';
import { DISLIKE_NEWS, LIKE_NEWS } from 'constants/actionConstants';
import ApiService from 'services/ApiService';
import { NotificationManager } from 'react-notifications';
import TranslateService from 'services/TranslateService';
import history from 'utils/history';
import { RouteUrls } from 'constants/RouteUrls';
import { fetchedNewsListSuccess } from '../../NewsList/NewsList.actions';
import { fetchNews } from '../NewsPreview/NewsPreview.actions';
import { editNewsError } from '../NewsEdit/NewsEdit.actions';
import INewsDetails from 'models/INewsDetails';
import { getAllNews } from 'selectors/storeSelectors';

const getUpdatedNews = (newsData: INewsDetails[], existingNews: INewsDetails[], id: string) => {
  const updatedNews = newsData.find((el: INewsDetails) => el.id && el.id.toString() === id);
  const copyExistingNews = [...existingNews];
  const newsIndexToChange = copyExistingNews.findIndex(el => el.id && el.id.toString() === id);
  const newsToChange = copyExistingNews[newsIndexToChange];
  const newElement = { ...newsToChange, ...updatedNews };
  copyExistingNews.splice(newsIndexToChange, 1, newElement);
  return copyExistingNews;
};

function* likeNewsAsync({ payload }: any) {
  try {
    yield ApiService.news().like(payload.id);
    if (history.location.pathname === RouteUrls.NewsList) {
      const newsData = yield ApiService.getAllNews();
      const existingNews: INewsDetails[] = yield select(getAllNews);
      yield put(fetchedNewsListSuccess(getUpdatedNews(newsData.data, existingNews, payload.id)));
    } else if (history.location.pathname.includes('previewNews')) {
      yield put(fetchNews(payload.id));
    }
  } catch (error) {
    yield put(editNewsError(error));
    NotificationManager.error(TranslateService.translate('news.edit.error'));
  }
}

function* dislikeNewsAsync({ payload }: any) {
  try {
    yield ApiService.news().dislike(payload.id);
    if (history.location.pathname === RouteUrls.NewsList) {
      const newsData = yield ApiService.getAllNews();
      const existingNews: INewsDetails[] = yield select(getAllNews);
      yield put(fetchedNewsListSuccess(getUpdatedNews(newsData.data, existingNews, payload.id)));
    } else if (history.location.pathname.includes('previewNews')) {
      yield put(fetchNews(payload.id));
    }
  } catch (error) {
    yield put(editNewsError(error));
    NotificationManager.error(TranslateService.translate('news.edit.error'));
  }
}

export const likeNewsSagas = [takeEvery(LIKE_NEWS, likeNewsAsync), takeEvery(DISLIKE_NEWS, dislikeNewsAsync)];
