import IAdminOption from 'models/IAdminPanel';
import { RouteUrls } from './RouteUrls';

export const adminOptions: IAdminOption[] = [
  {
    route: RouteUrls.AdminPanel,
    title: 'adminPanel.roleManagement.title',
    disabled: true,
    tooltip: 'adminPanel.button.tolltip',
  },
  {
    route: RouteUrls.MemoManagement,
    title: 'adminPanel.memoManagement.title',
    disabled: false,
  },
  {
    route: RouteUrls.AdminPanel,
    title: 'adminPanel.statistics.title',
    disabled: true,
    tooltip: 'adminPanel.button.tolltip',
  },
];
