import { call, put, takeEvery } from 'redux-saga/effects';
import { getMemoError, getMemoSuccess } from './Memo.actions';
import ApiService from '../../../services/ApiService';
import { GET_MEMO } from '../../../constants/actionConstants';
import { mappingMemoData } from '../Memo.utils';

function* getMemo() {
  try {
    const memoData = yield call(ApiService.memo().getMemo);
    const newData = mappingMemoData(memoData.data);

    yield put(getMemoSuccess(newData));
  } catch (error) {
    yield put(getMemoError(error));
  }
}

export const memoSagas = [takeEvery(GET_MEMO, getMemo)];
