import * as React from 'react';
import INewsDetails from 'models/INewsDetails';
import NewsCardContent from './NewsCardContent/NewsCardContent';

interface INewsCard {
  news: INewsDetails;
}

export default class NewsCard extends React.Component<INewsCard, {}> {
  public render() {
    const { subject, excerpt, id, postedDate, image, isLikedByUser, likes } = this.props.news;
    return (
      <li className="news-card" id={String(id)}>
        <NewsCardContent
          id={id}
          subject={subject}
          excerpt={excerpt.replace(/&nbsp;/g, '')}
          postedDate={postedDate}
          image={image}
          isLikedByUser={isLikedByUser}
          likes={likes}
        />
      </li>
    );
  }
}
