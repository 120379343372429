import * as React from 'react';
import { FormattedDate } from 'react-intl';

interface ICustomTimeDisplayProps {
  start: Date | undefined;
  finish: Date | undefined;
}

const CustomTimeDisplay = ({ start, finish }: ICustomTimeDisplayProps) => {
  return start || finish ? (
    <>
      {start ? <FormattedDate value={start} hour="numeric" minute="numeric" /> : null}
      {finish ? (
        <>
          <span className="preview__time-n-place-time-separator">-</span>
          <FormattedDate value={finish} hour="numeric" minute="numeric" />
        </>
      ) : null}
    </>
  ) : (
    <span>-</span>
  );
};

export default CustomTimeDisplay;
