import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { FormattedDate, FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';
import { IStore } from 'store/rootReducer';
import IMeetupDetails from 'models/IMeetupDetails';
import { UserRoles } from 'constants/UserRoles';
import { RouteUrls } from 'constants/RouteUrls';
import { mediaTypes } from 'constants/mediaTypes';
import { triggerTypes } from 'constants/triggerTypes';
import { meetupStatus } from 'constants/meetupStatus';
import { replaceUrlParams } from 'utils/routeUrls';
import { isNoEmptyDescription } from 'utils/draft.utils';
import { deleteMeetup } from 'containers/Meetup/MeetupDelete/MeetupDelete.actions';
import {
  backToModeration,
  fetchMeetup,
  publishMeetup,
  rateMeetup,
  unrateMeetup,
  uploadMeetupFile,
} from 'containers/Meetup/MeetupPreview/MeetupPreview.actions';
import { resetMeetupData } from 'containers/Meetup/MeetupCreate/MeetupCreate.actions';
import { cancelGoToMeetup, changeSelectedMeetup, goToMeetup } from 'containers/MeetupList/MeetupList.actions';
import {
  canAddFiles,
  canDeleteMeetup,
  canEditMeetup,
  canPublishDraftMeetup,
  isAuthor,
  isChief,
  isSpeaker,
} from 'utils/userRules';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomTimeDisplay from 'components/CustomTimeDisplay/CustomTimeDisplay';
import GooeyLoader from 'components/GooeyLoader/GooeyLoader';
import RichEditor from 'components/Editor/RichEditor';
import DeleteModal from 'components/Modal/DeleteModal';
import UserAvatar from 'containers/UserAvatar/UserAvatar';
import ClockIcon from 'assets/images/icons/ClockIcon';
import CalendarIcon from 'assets/images/icons/CalendarIcon';
import PointIcon from 'assets/images/icons/PointIcon';
import history from 'utils/history';
import IAppUser from 'models/IAppUser';
import MeetupAddFileDialog from './MeetupAddFileDialog/MeetupAddFileDialog';
import MeetupFileList from './MeetupFileList/MeetupFileList';
import CloseIcon from 'assets/images/icons/CloseIcon';
import { starsValues } from '../../../constants/rateConstants';

export interface IPublishParams {
  id: string;
  path: string;
}

interface IMeetupPreviewProps {
  currentMeetup: IMeetupDetails;
  match: {
    params: {
      id: string;
    };
  };
  intl: IntlShape;
  fetchMeetup: (id: string) => {};
  resetMeetupData: () => {};
  publishMeetup: (id: IPublishParams) => {};
  user: IAppUser;
  userRole: UserRoles[];
  changeSelectedMeetup: (id: string) => {};
  deleteMeetup: (id: string, userRoles: UserRoles[]) => {};
  goBackUrl: string;
  goToMeetup: (id: string) => {};
  cancelGoToMeetup: (id: string) => {};
  backToModeration: (id: string) => {};
  uploadMeetupFile: (id: string, files: File[]) => {};
  rateMeetup: (id: string, rate: number) => {};
  unrateMeetup: (id: string) => {};
}

interface IMeetupPreviewState {
  isAddFileDialogOpen: boolean;
  saveSelectedMeetup: boolean;
}

class MeetupPreview extends React.Component<IMeetupPreviewProps, IMeetupPreviewState> {
  state = {
    isAddFileDialogOpen: false,
    saveSelectedMeetup: false,
  };

  public componentDidMount(): void {
    this.props.fetchMeetup(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: Readonly<IMeetupPreviewProps>): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.fetchMeetup(this.props.match.params.id);
    }
  }

  public componentWillUnmount(): void {
    this.props.resetMeetupData();
    this.state.saveSelectedMeetup && this.props.changeSelectedMeetup(this.props.match.params.id);
  }

  setSaveSelectedMeetup = (save: boolean) => {
    this.setState({ saveSelectedMeetup: save });
  };

  public goBack = () => {
    this.setSaveSelectedMeetup(true);
    this.goToPrevUrl();
  };

  public goToPrevUrl = () => {
    const { status, isOver } = this.props.currentMeetup;
    if (status === meetupStatus.Draft) {
      history.push(RouteUrls.DraftMeetup);
    }
    if (status === meetupStatus.Confirmed && !isOver) {
      history.push(RouteUrls.FutureMeetup);
    }
    if (status === meetupStatus.Confirmed && isOver) {
      history.push(RouteUrls.PastMeetup);
    }
  };

  public publishMeetup = () => {
    this.props.publishMeetup({ id: this.props.match.params.id, path: this.props.goBackUrl });
    this.setSaveSelectedMeetup(false);
  };

  public deleteMeetup = () => {
    this.props.deleteMeetup(this.props.match.params.id, this.props.userRole);
    this.setSaveSelectedMeetup(false);
  };

  public goToMeetup = () => {
    this.props.currentMeetup.go
      ? this.props.cancelGoToMeetup(this.props.match.params.id)
      : this.props.goToMeetup(this.props.match.params.id);
  };

  public backToModeration = () => {
    this.props.backToModeration(this.props.match.params.id);
  };

  public checkIsLink = (): boolean => {
    const { place } = this.props.currentMeetup;
    return place.startsWith('http://') || place.startsWith('https://') || place.startsWith('ftp://');
  };

  public handleOpenDialog = (): void => {
    this.setState({ isAddFileDialogOpen: true });
  };

  public handleCloseDialog = (): void => {
    this.setState({ isAddFileDialogOpen: false });
  };

  public render() {
    const {
      subject,
      start,
      place,
      author,
      speakers,
      description,
      image,
      status,
      finish,
      isOver,
      id,
      attachedFiles,
      fileUploaded,
      averageRate,
      rateCount,
      participants,
      myRate,
    } = this.props.currentMeetup;
    const { user, intl, rateMeetup, unrateMeetup } = this.props;
    const speaker = speakers[0] || author;
    const isUserChief = isChief(user);
    const canPublish = !isOver && isNoEmptyDescription(description);
    const userCanAddFiles = canAddFiles(speaker, author, user, status);
    const isParticipant = !!participants.find(element => element.id === user.id);
    const isRated = !!(myRate && myRate > 0);
    const canRate = isAuthor(author, user.id) || isSpeaker(speakers[0], user.id) || isParticipant;

    return (
      <div className="meetup-list__wrapper">
        {id ? (
          <div className="meetup-list">
            <h1 className="meetup-list__header__heading margin-bottom-50 text-align-center">
              <FormattedMessage id={`meetupList.meetupPreview.meetupTitle`} />
            </h1>
            <div className="card card__no-padding">
              <div className="preview__topic-img-wrapper">
                <img className="preview__topic-img" src={image!} alt="meetup-example" />
              </div>
              <h2 className="preview__topic-name preview__pb30 word-wrap">{subject}</h2>
            </div>
            {status === meetupStatus.Confirmed && isOver && (
              <>
                <h3 className="card__heading">
                  <FormattedMessage id={`meetupList.meetupPreview.rate`} />
                </h3>
                <div className="card card__rate">
                  <div className="preview__rating">
                    <div className="preview__rating__stars-wrapper">
                      {starsValues.map(value => {
                        const starDecorator = isRated
                          ? value <= myRate
                            ? 'full-rated-star'
                            : 'empty-rated-star'
                          : value <= averageRate
                          ? 'full-unrated-star'
                          : 'empty-unrated-star';
                        return (
                          <button
                            className={'preview__rating__stars-wrapper__star ' + starDecorator}
                            onClick={() => rateMeetup(id, value)}
                            disabled={isRated || !canRate}
                          />
                        );
                      })}
                    </div>
                    {canRate && rateCount === 0 ? (
                      <div className="preview__rating__first">
                        <FormattedMessage id="meetupList.meetupPreview.rate.beFirst" />
                      </div>
                    ) : (
                      <div className="preview__rating__numbers">
                        <span title={intl.formatMessage({ id: 'meetupList.meetupPreview.rate.averageRate' })}>
                          {averageRate}
                        </span>
                        <span title={intl.formatMessage({ id: 'meetupList.meetupPreview.rate.rateCount' })}>
                          ({rateCount})
                        </span>
                      </div>
                    )}
                    {isRated && (
                      <button
                        title={intl.formatMessage({ id: 'meetupList.meetupPreview.rate.close' })}
                        className="preview__rating__close"
                        onClick={() => unrateMeetup(id)}
                      >
                        <CloseIcon />
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
            <h3 className="card__heading">
              <FormattedMessage id="meetupList.meetupPreview.timeAndPlace" />
            </h3>
            <ul className="card card__time-n-place">
              <li
                className={classNames('preview__time-n-place-item', {
                  red: isOver && status === meetupStatus.Draft,
                })}
              >
                <CalendarIcon />
                {start ? <FormattedDate weekday="long" value={start} year="numeric" day="numeric" month="long" /> : '-'}
              </li>
              <li
                className={classNames('preview__time-n-place-item', {
                  red: isOver && status === meetupStatus.Draft,
                })}
              >
                <ClockIcon />
                <CustomTimeDisplay start={start} finish={finish} />
              </li>
              <li className="preview__time-n-place-item">
                <PointIcon />
                {this.checkIsLink() ? (
                  <a
                    href={place ? place : '-'}
                    title={place ? place : '-'}
                    className={'inline-link preview__time-n-place-item__place'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {place ? place : '-'}
                  </a>
                ) : (
                  <span className="preview__time-n-place-item__place">{place ? place : '-'}</span>
                )}
              </li>
            </ul>
            <h3 className="card__heading">
              <FormattedMessage id={`meetupList.meetupPreview.speaker`} />
            </h3>
            <div className="card card__speaker">
              <UserAvatar user={speaker} round={true} size="40" />
              <p className="preview__speaker-name">{`${speaker.name}
            ${speaker.surname}`}</p>
            </div>
            <h3 className="card__heading">
              <FormattedMessage id="meetupList.meetupPreview.description" />
            </h3>
            <div className="card card__description">
              <RichEditor intl={intl} readonly value={description} />
            </div>
            <h3 className="card__heading">
              <FormattedMessage id="meetupList.meetupPreview.files" />
            </h3>
            <div className="card">
              <MeetupFileList
                meetupId={id}
                existingFiles={attachedFiles}
                userCanAddFiles={userCanAddFiles}
                handleOpenDialog={this.handleOpenDialog}
                intl={intl}
              />
            </div>
            <div className="card card__controls card__actions">
              <button className="button go-back-button" onClick={() => this.goBack()}>
                <FormattedMessage id="meetupList.meetupPreview.button.goBack" />
              </button>
              <div className="card__main-actions">
                {canDeleteMeetup(user) && (
                  <DeleteModal
                    handleDelete={this.deleteMeetup}
                    mediaType={mediaTypes.Meetup}
                    triggerType={triggerTypes.Button}
                    isConfirmationNeeded={true}
                  />
                )}
                {status !== meetupStatus.Request && canEditMeetup(speaker, author, status, isOver, user) && (
                  <Link className="button__no-outline" to={replaceUrlParams(RouteUrls.EditMeetup, { id })}>
                    <CustomButton
                      className={
                        status === meetupStatus.Draft && (speaker || author) && !isUserChief
                          ? 'button edit-button button_contained-violet margin-left-5'
                          : 'button edit-button button_outlined-violet margin-left-5'
                      }
                      label={intl.formatMessage({ id: 'meetupList.meetupPreview.button.edit' })}
                      variant="outlined"
                    />
                  </Link>
                )}
                {status === meetupStatus.Draft && canPublishDraftMeetup(user) && (
                  <CustomButton
                    className={
                      isUserChief
                        ? 'button publish-button button_contained-violet margin-left-5'
                        : 'button button_outlined-violet margin-left-5'
                    }
                    onClick={this.publishMeetup}
                    label={intl.formatMessage({ id: 'meetupList.meetupPreview.button.publish' })}
                    disabled={!canPublish}
                    tooltipText={
                      isOver
                        ? 'meetupList.meetupPreview.button.publish.error.tooltip'
                        : !isNoEmptyDescription(description)
                        ? 'meetupList.meetupPreview.button.publish.error.tooltip.emptyDescription'
                        : ''
                    }
                    variant={'contained'}
                  />
                )}
                {isUserChief && status === meetupStatus.Confirmed && !isOver && (
                  <CustomButton
                    className={'button draft-button button_outlined-violet margin-left-10'}
                    label={intl.formatMessage({ id: 'meetupList.meetupPreview.button.backToDraftMeetup' })}
                    variant="outlined"
                    onClick={this.backToModeration}
                  />
                )}
                {status === meetupStatus.Confirmed && !isOver && (
                  <CustomButton
                    className={
                      this.props.currentMeetup.go
                        ? 'button xs_button joined-to-meetup-preview'
                        : 'button xs_button go-to-meetup-preview'
                    }
                    label={
                      this.props.currentMeetup.go
                        ? intl.formatMessage({ id: 'meetupList.meetupPreview.button.joinedToMeetup' })
                        : intl.formatMessage({ id: 'meetupList.meetupPreview.button.goToMeetup' })
                    }
                    variant={'contained'}
                    onClick={this.goToMeetup}
                  />
                )}
              </div>
            </div>
            <MeetupAddFileDialog
              meetupId={id}
              isOpen={this.state.isAddFileDialogOpen}
              onClose={this.handleCloseDialog}
              uploadMeetupFile={this.props.uploadMeetupFile}
              existingFiles={attachedFiles}
              fileUploaded={fileUploaded}
            />
          </div>
        ) : (
          <div className="loader-container">
            <GooeyLoader isLoading={true} />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    currentMeetup: state.currentMeetup,
    user: state.userInfo.user,
    userRole: state.userInfo.user.roles,
    goBackUrl: state.meetups.goBackUrl,
    isFileUploaded: state.currentMeetup.fileUploaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchMeetup: bindActionCreators(fetchMeetup, dispatch),
    resetMeetupData: bindActionCreators(resetMeetupData, dispatch),
    publishMeetup: bindActionCreators(publishMeetup, dispatch),
    changeSelectedMeetup: bindActionCreators(changeSelectedMeetup, dispatch),
    deleteMeetup: bindActionCreators(deleteMeetup, dispatch),
    goToMeetup: bindActionCreators(goToMeetup, dispatch),
    cancelGoToMeetup: bindActionCreators(cancelGoToMeetup, dispatch),
    backToModeration: bindActionCreators(backToModeration, dispatch),
    uploadMeetupFile: bindActionCreators(uploadMeetupFile, dispatch),
    rateMeetup: bindActionCreators(rateMeetup, dispatch),
    unrateMeetup: bindActionCreators(unrateMeetup, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MeetupPreview),
);
