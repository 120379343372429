import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import INotificationDetails from 'models/INotificationDetails';
import NotificationCard from './NotificationCard';

interface INotificationListProps {
  notifications: INotificationDetails[];
  isEmpty: boolean;
  onClose: () => void;
}

export default class NotificationList extends React.Component<INotificationListProps, {}> {
  public render() {
    const { notifications, isEmpty } = this.props;
    const unreadNotifications = notifications.filter(notification => !notification.read);
    return (
      <div className="notification-list__wrapper">
        <div className="notification-list__header">
          <div>
            <FormattedMessage id="notificationList.title" />
          </div>
          <div className="notification-list__header__new">
            {unreadNotifications.length}
            <FormattedMessage id="notificationList.new" />
          </div>
        </div>
        {isEmpty ? (
          <FormattedMessage id="notificationList.empty" />
        ) : (
          <ul className="notification-list__list">
            {notifications.map((notification: INotificationDetails) => {
              return (
                <NotificationCard
                  key={String(notification.id)}
                  notification={notification}
                  onClose={this.props.onClose}
                />
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
