import * as React from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import { authenticationParameters, config } from 'msalConfig';
import { AzureAD, MsalAuthProviderFactory } from 'react-aad-msal';
import { IntlShape, injectIntl } from 'react-intl';
import { RouteUrls } from 'constants/RouteUrls';

interface ILogoutButtonProps {
  intl: IntlShape;
}

class LogoutButton extends React.Component<ILogoutButtonProps, {}> {
  public render() {
    return (
      <AzureAD
        provider={new MsalAuthProviderFactory(config() as any, authenticationParameters)}
        authenticatedFunction={this.authenticatedFunction}
      />
    );
  }

  public authenticatedFunction = (logout: LoginFunction) => {
    const { intl } = this.props;
    const { pathname } = window.location;

    return (
      <CustomButton
        className="button button_outlined-grey button_small"
        onClick={logout}
        variant="contained"
        label={
          pathname === RouteUrls.UnauthorizedPage
            ? intl.formatMessage({ id: 'generalUI.relog.button.label' })
            : intl.formatMessage({ id: 'generalUI.logout.button.label' })
        }
      />
    );
  };
}

type LoginFunction = () => void;

export default injectIntl(LogoutButton);
