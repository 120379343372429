import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { IntlShape, injectIntl } from 'react-intl';
import { keyTypes } from 'constants/keyTypes';
import CustomButton from '../CustomButton/CustomButton';

type InputPopoverProps = {
  intl: IntlShape;
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  onCancel: () => void;
  onSubmit: (link: { text: string; value: string }) => void;
  selectedText: string;
  selectedLink: string;
};

interface InputPopoverState {
  isLinkEmpty: boolean;
}

class InputPopover extends Component<InputPopoverProps, InputPopoverState> {
  state = {
    isLinkEmpty: true,
  };
  inputTextRef = React.createRef<HTMLInputElement>();
  inputLinkRef = React.createRef<HTMLInputElement>();

  onInputKeyPress = (event: React.KeyboardEvent<Element>) => {
    if (event.which === keyTypes.ENTER) {
      this.state.isLinkEmpty ? this.props.onCancel() : this.onSubmit();
    }
  };

  addDefaultPrefixIfNeeded = (link: string): string => {
    if (
      link &&
      link.length > 0 &&
      !link.startsWith('http://') &&
      !link.startsWith('https://') &&
      !link.startsWith('ftp://')
    ) {
      return `https://${link}`;
    }
    return link;
  };

  onSubmit = () => {
    const link = {
      text: this.inputTextRef.current ? this.inputTextRef.current.value.trim() : '',
      value: this.addDefaultPrefixIfNeeded(this.inputLinkRef.current ? this.inputLinkRef.current.value.trim() : ''),
    };
    this.props.onSubmit(link);
  };

  onDocumentClick = (event: MouseEvent) => {
    let rootNode = ReactDOM.findDOMNode(this);
    if (rootNode && !rootNode.contains(event.target as Node)) {
      this.props.onCancel();
    }
  };

  onDocumentKeydown = (event: KeyboardEvent) => {
    if (event.keyCode === keyTypes.ESC) {
      this.props.onCancel();
    }
  };

  handleInputChange = (): void => {
    const input = this.inputLinkRef.current;
    if (input) {
      this.setState({ isLinkEmpty: input.value.trim().length === 0 });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.onDocumentClick);
    document.addEventListener('keydown', this.onDocumentKeydown);
    const input = this.inputTextRef.current;
    if (input) {
      input.focus();
      input.selectionStart = 0;
    }
    this.setState({ isLinkEmpty: this.props.selectedLink.length === 0 });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onDocumentClick);
    document.removeEventListener('keydown', this.onDocumentKeydown);
  }

  render() {
    const { intl, onCancel, selectedText, selectedLink } = this.props;

    return (
      <div className="InputPopover__root">
        <div className="InputPopover__inner">
          <label htmlFor="InputPopover_linkText">{intl.formatMessage({ id: 'inputPopover.label.text' })}</label>
          <input
            id="InputPopover_linkText"
            ref={this.inputTextRef}
            type="text"
            defaultValue={selectedText || ''}
            className="InputPopover__input"
            onKeyPress={this.onInputKeyPress}
          />
        </div>
        <div className="InputPopover__inner">
          <label htmlFor="InputPopover_linkValue">{intl.formatMessage({ id: 'inputPopover.label.link' })}</label>
          <input
            id="InputPopover_linkValue"
            ref={this.inputLinkRef}
            type="text"
            defaultValue={selectedLink || ''}
            placeholder="https://example.com/"
            className="InputPopover__input"
            onKeyPress={this.onInputKeyPress}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="InputPopover__buttonGroup ">
          <CustomButton
            className="button "
            variant="outlined"
            onClick={onCancel}
            label={intl.formatMessage({ id: 'inputPopover.label.cancel' })}
          />
          <CustomButton
            className="button button_contained-violet margin-left-10"
            variant="contained"
            onClick={this.onSubmit}
            disabled={this.state.isLinkEmpty}
            label={intl.formatMessage({ id: 'inputPopover.label.save' })}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(InputPopover);
