import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TrashBinIcon from 'assets/images/icons/TrashBinIcon';
import { DialogContent, DialogContentText, IconButton } from '@material-ui/core';
import { mediaTypes } from 'constants/mediaTypes';
import { triggerTypes } from 'constants/triggerTypes';
import Transition from './Transition';
import CustomButton from '../CustomButton/CustomButton';

interface IDeleteModal {
  mediaType: string;
  handleDelete: any;
  triggerType: string;
  intl: IntlShape;
  isConfirmationNeeded: boolean;
}

function DeleteModal(props: IDeleteModal) {
  const [open, setOpen] = React.useState(false);
  const { intl, triggerType, mediaType, handleDelete, isConfirmationNeeded } = props;

  function handleClickOpen(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setOpen(true);
  }

  const entityDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleDelete();
    setOpen(false);
  };

  function handleClose(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setOpen(false);
  }

  return (
    <>
      {triggerType === triggerTypes.Icon ? (
        <IconButton onClick={isConfirmationNeeded ? handleClickOpen : handleDelete}>
          <TrashBinIcon colour={mediaType === mediaTypes.File ? '#848fa5' : '#C2CBDA'} />
        </IconButton>
      ) : (
        <CustomButton
          onClick={handleClickOpen}
          className="button delete-button button_outlined-violet"
          label={intl.formatMessage({ id: 'meetupList.meetupPreview.button.delete' })}
          variant="outlined"
        />
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <FormattedMessage
            id={
              mediaType === mediaTypes.Meetup
                ? 'modal.meetupDelete.dialog.text'
                : mediaType === mediaTypes.Theme
                ? 'modal.themeDelete.dialog.text'
                : mediaType === mediaTypes.File
                ? 'modal.fileDelete.dialog.text'
                : mediaType === mediaTypes.News
                ? 'modal.newsDelete.dialog.text'
                : 'modal.memoDelete.dialog.text'
            }
          />
        </DialogTitle>
        {mediaType === mediaTypes.File && (
          <DialogContent className={'text-center'}>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage id="modal.fileDelete.description" />
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <FormattedMessage
              id={
                mediaType === mediaTypes.File
                  ? 'modal.fileDelete.dialog.disagree'
                  : 'modal.meetupDelete.dialog.disagree'
              }
            />
          </Button>
          <Button onClick={entityDelete} color="primary">
            <FormattedMessage
              id={mediaType === mediaTypes.File ? 'modal.fileDelete.dialog.agree' : 'modal.meetupDelete.dialog.agree'}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default injectIntl(DeleteModal);
