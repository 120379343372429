import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { Card, CardActions, CardContent } from '@material-ui/core';
import { IStore } from 'store/rootReducer';
import INewsDetails from 'models/INewsDetails';
import IImageInfo, { InitImageInfo } from 'models/IImageInfo';
import { themeLimit, descriptionLimit } from 'constants/inputValueLimits';
import { RouteUrls } from 'constants/RouteUrls';
import { fieldNames } from 'constants/fieldNames';
import { NewsFieldsValuesType } from 'constants/customTypes';
import { replaceUrlParams } from 'utils/routeUrls';
import { isNoEmptyDescription } from 'utils/draft.utils';
import { getDefaultImagesNews, resetNewsData, updateNewsData } from 'containers/News/NewsCreate/NewsCreate.actions';
import { editNews } from 'containers/News/NewsEdit/NewsEdit.actions';
import { fetchNews } from 'containers/News/NewsPreview/NewsPreview.actions';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import GooeyLoader from 'components/GooeyLoader/GooeyLoader';
import RichEditor from 'components/Editor/RichEditor';
import ImageDropzone from 'components/ImageDropzone/ImageDropzone';
import UploadedImage from 'components/UploadedImage/UploadedImage';
import TrashBinIcon from 'assets/images/icons/TrashBinIcon';
import { fileToBase64 } from 'utils/convertToBase64';
import DialogModal from '../../../components/Modal/DialogModal';

interface INewsEditProps {
  intl: IntlShape;
  match: {
    params: {
      id: string;
    };
  };
  currentNews: INewsDetails;
  initialNews: INewsDetails;
  fetchNews: (id: string) => {};
  editNews: (id: string, currentNews: INewsDetails) => {};
  updateNewsData: (payload: { name: string; value: NewsFieldsValuesType }) => void;
  resetNewsData: () => {};
  getDefaultImages: () => {};
}

interface INewsEditState {
  image: File | null;
  tmpImage: string | null;
  imageInfo: IImageInfo;
  isFileUpload: boolean;
  dirtyFieldsArePresent: boolean;
  isDialogVisible: boolean;
}

class NewsEdit extends React.Component<INewsEditProps, INewsEditState> {
  public state = {
    isFileUpload: false,
    image: null,
    tmpImage: null,
    imageInfo: new InitImageInfo(),
    dirtyFieldsArePresent: false,
    isDialogVisible: false,
  };

  public componentDidMount(): void {
    const newsId = this.props.match.params.id;
    this.props.fetchNews(newsId);
    this.props.getDefaultImages();
  }

  public editNews = () => {
    this.props.editNews(this.props.currentNews.id + '', this.props.currentNews);
  };

  public clearSelectedImage = () => {
    this.onChange(fieldNames.tmpImage, '');
    this.onChange(fieldNames.tmpImageOrig, '');
    this.setState({ isFileUpload: false });
  };

  public componentWillUnmount(): void {
    this.props.resetNewsData();
  }

  public onChange = (name: string, value: NewsFieldsValuesType) => {
    this.setState({
      dirtyFieldsArePresent: true,
    });
    this.props.updateNewsData({ name, value });
  };

  public onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.onChange(name, value);
  };

  public fileDropHandler = (files: File[]) => {
    if (files && files.length > 0) {
      this.setState({
        isFileUpload: !this.state.isFileUpload,
        imageInfo: {
          imageTitle: files[0].name,
          imageSize: files[0].size,
        },
      });
      this.onChange(fieldNames.tmpImageOrig, files[0]);
      fileToBase64(files[0]).then((val: any) => this.onChange(fieldNames.tmpImage, val));
    }
  };

  public disableCheck = () => {
    return (
      !this.props.currentNews.subject ||
      !isNoEmptyDescription(this.props.currentNews.description) ||
      !this.state.dirtyFieldsArePresent
    );
  };

  private setDefaultImage = () => {
    this.onChange('image', this.props.currentNews.defaultImages[1]);
    this.onChange('tmpImage', this.props.currentNews.defaultImages[1]);
    this.onChange('picId', this.props.currentNews.defaultImageIds[1]);
  };

  public openDialog = () => {
    this.setState({ isDialogVisible: true });
  };

  public closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  public render() {
    const newsId = this.props.match.params.id;
    const { subject, description, image, picId, defaultImageIds } = this.props.currentNews;
    const { intl } = this.props;
    const { isFileUpload, imageInfo } = this.state;

    return (
      <div className="page-content meetup-edit">
        <h1 className="page-heading">
          <FormattedMessage id="news.edit.title" />
        </h1>
        <Card className="card card_form">
          <CardContent>
            <form>
              <CustomInput
                intl={intl}
                maxLength={themeLimit}
                label={intl.formatMessage({
                  id: 'newsList.newsCreate.labelTheme',
                })}
                className="theme"
                multiline={false}
                onChange={this.onChangeValue}
                rows={1}
                name="subject"
                value={subject}
              />
              {description.blocks && description.blocks.length > 0 ? (
                <RichEditor
                  intl={intl}
                  value={description!}
                  readonly={false}
                  onChange={this.onChange}
                  maxLength={descriptionLimit}
                />
              ) : null}
              {!isFileUpload ? (
                <>
                  <div className="input-label input-label__description">
                    {intl.formatMessage({
                      id: 'image.text',
                    })}
                  </div>
                  <div className="meetup-edit__dropzone-wrapper">
                    {image ? (
                      <div className="meetup-edit__container">
                        <img className="meetup-edit__preview__topic-img" src={image!} alt="meetup-example" />
                        {picId !== defaultImageIds[1] && (
                          <div className="meetup-edit__button-container" onClick={() => this.setDefaultImage()}>
                            <TrashBinIcon width={50} height={50} colour={'white'} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <GooeyLoader isLoading={true} />
                    )}
                    <ImageDropzone onDrop={this.fileDropHandler} edit={true} />
                  </div>
                </>
              ) : (
                <UploadedImage clearSelectedImage={this.clearSelectedImage} imageInfo={imageInfo} />
              )}
            </form>
          </CardContent>
        </Card>
        <Card className="card card_controls card_controls-form">
          <CardActions className="card__actions">
            <CustomButton
              className="button go-back-button"
              variant="outlined"
              label={intl.formatMessage({ id: 'meetup.edit.button.cancel' })}
              link={replaceUrlParams(RouteUrls.PreviewNews, { id: newsId })}
            />
            <div>
              <CustomButton
                className="button button_contained-violet margin-left-10"
                variant="contained"
                onClick={() => (subject.length > 500 ? this.openDialog() : this.editNews())}
                disabled={this.disableCheck()}
                label={intl.formatMessage({ id: 'meetup.edit.button.save' })}
              />
            </div>
          </CardActions>
        </Card>
        <DialogModal
          handleConfirm={() => {}}
          handleClose={this.closeDialog}
          titleTextId="editor.subjectValidation.warningTitle"
          textId="editor.subjectValidation.warningText"
          open={this.state.isDialogVisible}
          showButtons={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    currentNews: state.currentNews,
    initialNews: state.initialNewsData,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchNews: bindActionCreators(fetchNews, dispatch),
    editNews: bindActionCreators(editNews, dispatch),
    updateNewsData: bindActionCreators(updateNewsData, dispatch),
    resetNewsData: bindActionCreators(resetNewsData, dispatch),
    getDefaultImages: bindActionCreators(getDefaultImagesNews, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewsEdit),
);
