import { RawDraftContentState } from 'draft-js';
import { meetupStatus } from 'constants/meetupStatus';
import IMeetupAuthor from './IMeetupAuthor';
import IParticipant from './IParticipant';
import IMeetupSpeaker from './IMeetupSpeaker';
import IFile from './IFile';

export default interface IMeetupDetails {
  id: string;
  subject: string;
  start: Date | undefined;
  finish: Date | undefined;
  description?: RawDraftContentState;
  place: string;
  author: IMeetupAuthor;
  speakerId: string;
  status: meetupStatus;
  availableSpeakers: IMeetupAuthor[];
  picId: null;
  image: string | null;
  tmpImage: string | null;
  tmpImageOrig: File | null;
  defaultImages: string[];
  defaultImageIds: string[];
  isOver: boolean;
  participants: IParticipant[];
  speakers: IMeetupSpeaker[];
  likesCount: number | null;
  liked: boolean;
  go: boolean;
  attachedFiles: IFile[];
  fileUploaded: boolean;
  myRate: number;
  rateCount: number;
  averageRate: number;
}

export class InitMeetupDetailsState implements IMeetupDetails {
  id = '';
  subject = '';
  start = undefined;
  finish = undefined;
  description: undefined;
  place = '';
  author = {
    id: '',
    name: '',
    surname: '',
  };
  speakerId = '';
  status = meetupStatus.Request;
  availableSpeakers = [];
  picId = null;
  image = null;
  tmpImage = null;
  tmpImageOrig = null;
  defaultImages = [];
  defaultImageIds = [];
  isOver = false;
  participants = [];
  speakers = [];
  go = false;
  likesCount = null;
  liked = false;
  attachedFiles = [];
  fileUploaded = true;
  myRate = 0;
  rateCount = 0;
  averageRate = 0;
}
