import * as React from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import INewsDetails from 'models/INewsDetails';
import NewsCard from 'containers/NewsList/NewsCard/NewsCard';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { deleteSelectedNews, fetchNewsList } from 'containers/NewsList/NewsList.actions';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import { IStore } from 'store/rootReducer';
import { Link } from 'react-router-dom';
import { UserRoles } from 'constants/UserRoles';
import { RouteUrls } from 'constants/RouteUrls';
import GooeyLoader from 'components/GooeyLoader/GooeyLoader';

interface INewsListProps {
  intl: IntlShape;
  news: INewsDetails[];
  isEmpty: boolean;
  fetchNewsList: () => {};
  userRoles: UserRoles[];
  selectedNews: string;
  deleteSelectedNews: () => {};
}

interface INewsListState {
  news: INewsDetails[];
  isEmpty: boolean;
  selectedNews: string;
}

class NewsList extends React.Component<INewsListProps, INewsListState> {
  public state = {
    news: [],
    isEmpty: false,
    selectedNews: '',
  };

  public componentDidMount(): void {
    this.props.fetchNewsList();
    this.restoreScrollPositionIfNeeded(this.props.news, this.props.selectedNews);
  }

  public componentWillUnmount(): void {
    this.props.deleteSelectedNews();
  }

  private restoreScrollPositionIfNeeded(news: INewsDetails[], selectedNews: string) {
    const NewsNode: HTMLElement | null = document.getElementById(this.props.selectedNews)!;
    const coordinates = NewsNode ? NewsNode.offsetTop - window.innerHeight / 2.5 : 0;
    news.forEach(newsItem => {
      if (`${newsItem.id}` === selectedNews && selectedNews !== null) {
        window.scroll(0, coordinates);
      } else if (selectedNews === '') {
        window.scrollTo(0, 0);
      }
    });
  }

  public render() {
    const { intl, userRoles, news, isEmpty } = this.props;
    const sortedNews = news.sort((a, b) => new Date(b.postedDate).getTime() - new Date(a.postedDate).getTime());
    return (
      <div className="news-list__wrapper">
        <div className="news-list__header">
          <span className="news-list__header__heading">
            <FormattedMessage id="newsList.title" />
          </span>
          <Link to={RouteUrls.CreateNews}>
            {userRoles.includes(UserRoles.NEWS_PUBLISHER) ? (
              <CustomButton
                className="button button_outlined-violet"
                variant="outlined"
                label={intl.formatMessage({
                  id: 'newsList.button.createNews',
                })}
              />
            ) : null}
          </Link>
        </div>
        {isEmpty && <FormattedMessage id="newsList.empty" />}
        {!isEmpty &&
          (news.length === 0 ? (
            <p className="loader-wrap">
              <GooeyLoader isLoading={true} />
            </p>
          ) : (
            <ul className="news-list__list">
              {sortedNews.map((news: INewsDetails) => {
                return <NewsCard key={news.id!} news={news} />;
              })}
            </ul>
          ))}
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    news: state.news.news,
    isEmpty: state.news.isEmpty,
    userRoles: state.userInfo.user.roles,
    selectedNews: state.news.selectedNews,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchNewsList: bindActionCreators(fetchNewsList, dispatch),
    deleteSelectedNews: bindActionCreators(deleteSelectedNews, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewsList),
);
