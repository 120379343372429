import classNames from 'classnames';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { CharacterMetadata, ContentBlock, EditorState, RichUtils } from 'draft-js';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatClearIcon from '@material-ui/icons/FormatClear';
import LinkIcon from '@material-ui/icons/Link';

import { richEditorLabels, richEditorStyles } from 'constants/richEditorStyles';
import DialogModal from 'components/Modal/DialogModal';
import InputPopover from './InputPopover';
import { getSelectedBlocksList } from 'utils/draft.utils';

interface IRichToolbarProps {
  onStyleChange: (event: React.MouseEvent<HTMLButtonElement>, style: string) => void;
  readonly: boolean;
  editorState: EditorState;
  intl: IntlShape;
  isSelection: boolean;
  onStyleTypeChange: (event: React.MouseEvent<HTMLButtonElement>, style: string) => void;
  cleanFormat: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setLink: (link: { text: string; value: string }) => void;
  onChange: (editorState: EditorState) => void;
}

interface IRichToolbarState {
  isLinkInputVisible: boolean;
  isDialogVisible: boolean;
}

class RichToolbar extends React.Component<IRichToolbarProps, IRichToolbarState> {
  state = {
    isLinkInputVisible: false,
    isDialogVisible: false,
  };

  public handleLink = (link: { text: string; value: string }) => {
    this.setState(state => ({ isLinkInputVisible: !state.isLinkInputVisible }));
    this.props.setLink(link);
  };

  public hidePopover = () => {
    this.setState({ isLinkInputVisible: false });
    const { editorState } = this.props;
    const editorStateFocused = EditorState.forceSelection(editorState, editorState.getSelection());
    this.props.onChange(editorStateFocused);
  };

  public togglePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState(state => ({ isLinkInputVisible: !state.isLinkInputVisible }));
  };

  public handleClose = () => {
    this.setState({ isDialogVisible: false });
  };

  private changeElementClass = (element: HTMLButtonElement) => {
    element.className === 'selected' ? element.removeAttribute('class') : element.setAttribute('class', 'selected');
  };

  public handleStyleChange = (event: React.MouseEvent<HTMLButtonElement>, style: string) => {
    this.changeElementClass(event.currentTarget);
    this.props.onStyleChange(event, style);
  };

  public handleStyleTypeChange = (event: React.MouseEvent<HTMLButtonElement>, style: string) => {
    this.changeElementClass(event.currentTarget);
    this.props.onStyleTypeChange(event, style);
  };

  public handleClean = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { isSelection, cleanFormat } = this.props;
    const { isDialogVisible } = this.state;
    if (!isSelection && !isDialogVisible) {
      this.setState({ isDialogVisible: true });
      return;
    }

    cleanFormat(event);
  };

  public getSelectedText = (): string => {
    const selectionState = this.props.editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = this.props.editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    return currentContentBlock.getText().slice(start, end);
  };

  public getSelectedLink = (): string => {
    const { editorState } = this.props;
    let url = '';
    if (RichUtils.currentBlockContainsLink(editorState)) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
    }
    return url;
  };
  public getSelectedStyles = (): Set<string> => {
    const { isSelection, editorState } = this.props;
    const styles = new Set<string>();
    const currentSelection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    function getCharacterStyles(characterList: Immutable.Iterable<number, CharacterMetadata>) {
      characterList.forEach(character => {
        if (character) {
          if (character.hasStyle(richEditorStyles.BOLD)) {
            styles.add(richEditorStyles.BOLD);
          }
          if (character.hasStyle(richEditorStyles.ITALIC)) {
            styles.add(richEditorStyles.ITALIC);
          }
          if (character.hasStyle(richEditorStyles.UNDERLINE)) {
            styles.add(richEditorStyles.UNDERLINE);
          }
          if (character.hasStyle(richEditorStyles.STRIKETHROUGH)) {
            styles.add(richEditorStyles.STRIKETHROUGH);
          }
        }
      });
    }
    if (isSelection) {
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startOffset = currentSelection.getStartOffset();
      const endOffset = currentSelection.getEndOffset();
      const isSameBlock = startKey === endKey;

      const blocksList = getSelectedBlocksList(editorState);
      blocksList.forEach((block: ContentBlock | undefined) => {
        if (!block) return;
        const currentBlockKey = block.getKey();
        const startEntityKey = block.getEntityAt(startOffset);
        const endEntityKey = block.getEntityAt(endOffset);
        const isStartLink = startEntityKey !== null && contentState.getEntity(startEntityKey).getType() === 'LINK';
        const isEndLink = endEntityKey !== null && contentState.getEntity(endEntityKey).getType() === 'LINK';
        const isLink = isStartLink || isEndLink;
        let characterList;
        if (isSameBlock) {
          characterList = block.getCharacterList().slice(startOffset, endOffset);
        } else if (currentBlockKey === startKey) {
          characterList = block.getCharacterList().slice(startOffset);
        } else if (currentBlockKey === endKey) {
          characterList = block.getCharacterList().slice(0, endOffset);
        } else {
          characterList = block.getCharacterList();
        }
        getCharacterStyles(characterList);
        isLink && styles.add(richEditorStyles.LINK);
        styles.add(block.getType());
      });
    } else {
      const pointerOffset = currentSelection.getStartOffset();
      const blocksList = getSelectedBlocksList(editorState);
      blocksList.forEach((block: ContentBlock | undefined) => {
        if (!block) return;
        const entityKey = block.getEntityAt(pointerOffset - 1);
        const isLink = entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
        const start = pointerOffset === 0 ? 0 : pointerOffset - 1;
        let characterList = block.getCharacterList().slice(start, pointerOffset);
        getCharacterStyles(characterList);
        isLink && styles.add(richEditorStyles.LINK);
        styles.add(block.getType());
      });
    }
    return styles;
  };

  cancelMouseEvents = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  render() {
    const { readonly, intl, isSelection } = this.props;
    const { isLinkInputVisible, isDialogVisible } = this.state;
    const styles = this.getSelectedStyles();
    const title = isSelection
      ? intl.formatMessage({ id: 'editor.clean' })
      : intl.formatMessage({ id: 'editor.fullClean' });
    return (
      <div className={classNames('DraftEditor__toolbar', { edit: !readonly })}>
        <button
          onMouseDown={this.cancelMouseEvents}
          className={classNames({ selected: styles.has(richEditorStyles.BOLD) })}
          onClick={e => this.handleStyleChange(e, richEditorStyles.BOLD)}
        >
          <b>{richEditorLabels.BOLD}</b>
          <FormatBoldIcon />
        </button>
        <button
          onMouseDown={this.cancelMouseEvents}
          className={classNames({ selected: styles.has(richEditorStyles.ITALIC) })}
          onClick={e => this.handleStyleChange(e, richEditorStyles.ITALIC)}
        >
          <b>{richEditorLabels.ITALIC}</b>
          <FormatItalicIcon />
        </button>
        <button
          onMouseDown={this.cancelMouseEvents}
          className={classNames({ selected: styles.has(richEditorStyles.UNDERLINE) })}
          onClick={e => this.handleStyleChange(e, richEditorStyles.UNDERLINE)}
        >
          <b>{richEditorLabels.UNDERLINE}</b>
          <FormatUnderlinedIcon />
        </button>
        <button
          onMouseDown={this.cancelMouseEvents}
          className={classNames({ selected: styles.has(richEditorStyles.STRIKETHROUGH) })}
          onClick={e => this.handleStyleChange(e, richEditorStyles.STRIKETHROUGH)}
        >
          <b>{richEditorLabels.STRIKETHROUGH}</b>
          <FormatStrikethroughIcon />
        </button>
        <button
          className={classNames({ selected: styles.has(richEditorStyles.QUOTE) })}
          onClick={e => this.handleStyleTypeChange(e, richEditorStyles.QUOTE)}
        >
          <b>{richEditorLabels.QUOTE}</b>
          <FormatQuoteIcon />
        </button>
        <span className="change-link">
          <button className={classNames({ selected: styles.has(richEditorStyles.LINK) })} onClick={this.togglePopover}>
            <b>{richEditorLabels.LINK}</b>
            <LinkIcon />
          </button>
          {isLinkInputVisible && (
            <InputPopover
              onCancel={this.hidePopover}
              selectedText={this.getSelectedText()}
              selectedLink={this.getSelectedLink()}
              onSubmit={this.handleLink}
            />
          )}
        </span>
        <button
          className={classNames({ selected: styles.has(richEditorStyles.ORDERED_LIST_ITEM) })}
          onClick={e => this.handleStyleTypeChange(e, richEditorStyles.ORDERED_LIST_ITEM)}
        >
          <b>{richEditorLabels.ORDERED_LIST_ITEM}</b>
          <FormatListNumberedIcon />
        </button>
        <button
          className={classNames({ selected: styles.has(richEditorStyles.UNORDERED_LIST_ITEM) })}
          onClick={e => this.handleStyleTypeChange(e, richEditorStyles.UNORDERED_LIST_ITEM)}
        >
          <b>{richEditorLabels.UNORDERED_LIST_ITEM}</b>
          <FormatListBulletedIcon />
        </button>
        <div className="divider"></div>
        <button onClick={e => this.handleClean(e)} title={title}>
          <b>{richEditorLabels.CLEAN}</b>
          <FormatClearIcon />
        </button>

        <DialogModal
          handleConfirm={this.handleClean}
          handleClose={this.handleClose}
          titleTextId="editor.warningTitle"
          textId="editor.warningText"
          open={isDialogVisible}
          showButtons={true}
          isForRichEditor={true}
        />
      </div>
    );
  }
}

export default injectIntl(RichToolbar);
