export const convertTimeAndDate = (initialDate: Date | null | undefined): string => {
  return initialDate ?
    (new Date(
    initialDate.getTime() - initialDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .slice(0, -1)) :
  '';
};

export const addMinutes = (date: Date | null, minutes: number | null) => {
  return date && minutes ? new Date(date.getTime() + minutes * 60000) : undefined;
};
