import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import React from 'react';
import Calendar from '@material-ui/icons/DateRange';
import DatePicker from 'react-datepicker';
import { IntlShape } from 'react-intl';
import { TextField } from '@material-ui/core';
import { addMinutes } from 'utils/timeConverter';
import { meetupLengthInterval, timeDelay } from 'constants/timeDelay';
import { LOCALE } from 'constants/localization';
import { meetupCalendarDateFormat, meetupCalendarTimeFormat } from 'constants/meetupLengthAndTime';
import classNames from 'classnames';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import CloseIcon from 'assets/images/icons/CloseIcon';
import IconButton from '@material-ui/core/IconButton';

interface IDateTimePickerProps {
  intl: IntlShape;
  setStartDate: (date: Date | undefined) => void;
  setEndDate: (date: Date | undefined) => void;
  className?: string;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  onChange?: () => {};
  value: {
    start: Date | undefined;
    finish: Date | undefined;
  };
  minDateStart?: Date | undefined;
  maxDateStart?: Date | undefined;
  minDateFinish?: Date | undefined;
  maxDateFinish?: Date | undefined;
  maxTimeStart?: Date | undefined;
  minTimeStart?: Date | undefined;
  maxTimeFinish?: Date | undefined;
  minTimeFinish?: Date | undefined;
  selectedStartDateAndTime?: Date | undefined;
  selectedFinishDateAndTime?: Date | undefined;
  isOver: boolean;
}

export default class DateTimePicker extends React.Component<IDateTimePickerProps, {}> {
  public handleStartChange = (date: Date): void => {
    this.props.setStartDate(undefined);
    this.props.setEndDate(undefined);
    const currentMinutes = addMinutes(date, timeDelay);
    if (date >= new Date() || this.props.isOver) {
      this.props.setStartDate(date);
      this.props.setEndDate(currentMinutes);
    } else {
      this.props.setStartDate(undefined);
    }
  };

  public handleEndChange = (startDate: Date | undefined, endDate: Date | undefined): void => {
    this.props.setEndDate(undefined);
    if (endDate && startDate && endDate < startDate) {
      const tempMinutes = getMinutes(endDate);
      const tempHours = getHours(endDate);
      endDate = setHours(startDate, tempHours);
      endDate = setMinutes(endDate, tempMinutes);
    }

    this.props.setEndDate(endDate);
  };
  public clearDate = (value: Date | undefined) => {
    if (!this.props.disabled) {
      this.props.value.start === value && this.props.setStartDate(undefined);
      this.props.value.finish === value && this.props.setEndDate(undefined);
    }
  };

  public getCustomInput = (label: string, value: Date | undefined) => {
    const { multiline, rows, disabled, onChange, className } = this.props;

    return (
      <TextField
        className={'form__field' + (className ? ' ' + className : '')}
        InputLabelProps={{
          classes: {
            root: 'form__field-label',
            shrink: 'form__field-label_shrink',
            focused: 'form__field-label_focused',
            disabled: 'form__field-label_disabled',
          },
        }}
        InputProps={{
          classes: {
            root: 'form__field-input-wrapper',
            disabled: 'form__field-input-wrapper_disabled',
            focused: 'form__field-input-wrapper_focused',
            input: 'form__field-input',
          },
          endAdornment: (
            <div className="date-pickers__button-container">
              <IconButton
                className={classNames('date-pickers__clear-button', { hidden: !value || this.props.disabled })}
                onClick={() => this.clearDate(value)}
              >
                <CloseIcon />
              </IconButton>
              <div>
                <Calendar className={'iconCalendar'} style={{ width: 20, height: 19 }} />
              </div>
            </div>
          ),
        }}
        fullWidth
        variant="outlined"
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        onChange={onChange}
      />
    );
  };

  public render() {
    const {
      intl,
      value,
      minDateStart,
      maxDateStart,
      minTimeStart,
      maxTimeStart,
      minTimeFinish,
      maxTimeFinish,
      minDateFinish,
      maxDateFinish,
      selectedStartDateAndTime,
      selectedFinishDateAndTime,
    } = this.props;

    return (
      <>
        <div className="labels">
          <div className="input-label">{intl.formatMessage({ id: 'generalUI.calendar.label.from' })}</div>
          <div className={classNames('input-label', { hidden: !value.start })}>
            {intl.formatMessage({ id: 'generalUI.calendar.label.to' })}
          </div>
        </div>
        <div className="date-pickers">
          <div className="date-pickers__start-date">
            <DatePicker
              customInput={this.getCustomInput(
                intl.formatMessage({ id: 'generalUI.calendar.label.from' }),
                value.start,
              )}
              showTimeSelect
              disabled={this.props.disabled}
              timeFormat={meetupCalendarTimeFormat}
              minDate={minDateStart}
              maxDate={maxDateStart}
              minTime={minTimeStart}
              maxTime={maxTimeStart}
              locale={LOCALE.RU}
              timeIntervals={meetupLengthInterval}
              dateFormat={meetupCalendarDateFormat}
              timeCaption={intl.formatMessage({
                id: 'generalUI.calendar.label.time',
              })}
              selected={selectedStartDateAndTime}
              onChange={this.handleStartChange}
            />
          </div>
          <div className={classNames('date-pickers__end-date', { hidden: !value.start })}>
            <DatePicker
              customInput={this.getCustomInput(intl.formatMessage({ id: 'generalUI.calendar.label.to' }), value.finish)}
              showTimeSelect
              disabled={!value.start || this.props.disabled}
              timeFormat={meetupCalendarTimeFormat}
              minDate={minDateFinish}
              maxDate={maxDateFinish}
              minTime={minTimeFinish}
              maxTime={maxTimeFinish}
              locale={LOCALE.RU}
              timeIntervals={meetupLengthInterval}
              dateFormat={meetupCalendarDateFormat}
              timeCaption={intl.formatMessage({
                id: 'generalUI.calendar.label.time',
              })}
              selected={selectedFinishDateAndTime}
              onChange={endDate => this.handleEndChange(value.start!, endDate!)}
            />
          </div>
        </div>
      </>
    );
  }
}
