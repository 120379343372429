import { call, takeEvery } from 'redux-saga/effects';
import { SWITCH_LOCALE } from 'constants/actionConstants';
import TranslateService from 'services/TranslateService';

function* syncCurrentLocale(action: any) {
  yield call(
    TranslateService.setLocale.bind(TranslateService),
    action.payload.locale
  );
}

export const intlSagas = [takeEvery(SWITCH_LOCALE, syncCurrentLocale)];
