import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteUrls } from 'constants/RouteUrls';
import { Redirect } from 'react-router';
import { redirectDelay } from 'constants/timeDelay';
import PacmanLoader from 'components/PacmanLoader/PacmanLoader';

interface IRestrictedPageState {
  redirect: boolean;
}

class RestrictedPage extends React.Component<{}, IRestrictedPageState> {
  public state = {
    redirect: false,
  };

  public timer: number | undefined;

  public componentDidMount() {
    this.timer = window.setTimeout(() => {
      this.setState({ redirect: true });
    }, redirectDelay);
  }

  public componentWillUnmount() {
    clearTimeout(this.timer);
  }

  public render() {
    const { redirect } = this.state;
    return redirect ? (
      <Redirect to={RouteUrls.Themes} />
    ) : (
      <div className="page-not-found__wrapper">
        <h1>
          <FormattedMessage id="restrictedPage.title" />
        </h1>
        <PacmanLoader />
      </div>
    );
  }
}

export default RestrictedPage;
