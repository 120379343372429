export const authenticationParameters = {
  scopes: ['user.read'],
  loginHint: localStorage.getItem('userAccountHint') || undefined,
};

export const config = () => {
  const location = window.location.origin;
  const clientId =
    location.indexOf('meetup.sam-solutions.net') > -1
      ? '79b7ddff-660f-4cc5-946e-46afebfb3dbd'
      : '5c9c6985-eb1c-49b4-a13c-d8560eef6794';
  return {
    auth: {
      clientId,
      authority: 'https://login.microsoftonline.com/90e11593-043a-4569-abc9-7d748817700d',
      postLogoutRedirectUri: location,
      redirectUri: location,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  };
};
