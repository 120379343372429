import Button from '@material-ui/core/Button';
import React from 'react';
import TranslateService from 'services/TranslateService';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

interface ICustomButton {
  className: string;
  variant: 'text' | 'outlined' | 'contained';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  label: string;
  link?: string;
  tooltipText?: string;
  classes: {};
}

const styles = {
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    fontSize: '0.875rem',
    border: '1px solid grey',
  },
};

class CustomButton extends React.Component<ICustomButton, {}> {
  public render() {
    const { className, variant, onClick, disabled, label, link, tooltipText } = this.props;

    return link && !disabled ? (
      <Link to={link}>
        <Button
          className={className}
          classes={{
            label: 'button__label',
            disabled: 'button_disabled',
          }}
          focusVisibleClassName="focus_visible"
          variant={variant}
          TouchRippleProps={{
            classes: {
              root: 'button__ripple',
              child: 'button__ripple-child',
              rippleVisible: 'button__ripple-visible',
            },
          }}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </Button>
      </Link>
    ) : (
      <Tooltip
        classes={this.props.classes}
        placement={'top-start'}
        title={tooltipText ? TranslateService.translate(tooltipText) : ''}
      >
        <span>
          <Button
            className={className}
            focusVisibleClassName="focus_visible"
            classes={{
              label: 'button__label',
              disabled: 'button_disabled',
            }}
            variant={variant}
            TouchRippleProps={{
              classes: {
                root: 'button__ripple',
                child: 'button__ripple-child',
                rippleVisible: 'button__ripple-visible',
              },
            }}
            onClick={onClick}
            disabled={disabled}
          >
            {label}
          </Button>
        </span>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(CustomButton);
