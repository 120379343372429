import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IntlShape, injectIntl, FormattedMessage } from 'react-intl';
import { RawDraftContentState } from 'draft-js';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import history from 'utils/history';
import { descriptionLimit, themeLimit } from 'constants/inputValueLimits';
import { fieldNames } from 'constants/fieldNames';

import RichEditor from 'components/Editor/RichEditor';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomButton from 'components/CustomButton/CustomButton';

import { createTheme } from './ThemeCreate.actions';
import DialogModal from '../../../components/Modal/DialogModal';

interface IThemeCreateProps {
  intl: IntlShape;
  createNewTheme: (theme: IThemeCreateState) => void;
}

interface IThemeCreateState {
  subject: string;
  description: RawDraftContentState | undefined;
  isDialogVisible: boolean;
}

class ThemeCreate extends React.Component<IThemeCreateProps, IThemeCreateState> {
  public state: IThemeCreateState = {
    subject: '',
    description: undefined,
    isDialogVisible: false,
  };

  public openDialog = () => {
    this.setState({ isDialogVisible: true });
  };

  public closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  public disableCheck = () => {
    return !this.state.subject;
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="meetup-create">
        <div className="meetup-create__wrapper">
          <h1 className="meetup-create__page-heading">
            <FormattedMessage id="themeList.themeCreate.title" />
          </h1>
          <div className="meetup-create__description">
            <FormattedMessage id="themeList.themeCreate.description" />
          </div>
          <div className="required-form">
            <Card className="card card_form">
              <CardContent>
                <form>
                  <CustomInput
                    intl={intl}
                    maxLength={themeLimit}
                    label={intl.formatMessage({
                      id: 'themeList.themeCreate.placeholderTheme',
                    })}
                    className="theme"
                    multiline={false}
                    onChange={event => {
                      this.setState({ ...this.state, subject: event.target.value });
                    }}
                    rows={1}
                    name={fieldNames.subject}
                    value={this.state.subject}
                  />
                  <RichEditor
                    intl={intl}
                    readonly={false}
                    onChange={(name: string, value: RawDraftContentState) => {
                      this.setState({ ...this.state, description: value });
                    }}
                    maxLength={descriptionLimit}
                    value={this.state.description}
                  />
                </form>
              </CardContent>
            </Card>
            <Card className="theme-card__actions card card_controls card_controls-form">
              <CardActions className="card__actions">
                <CustomButton
                  className="button button_outlined"
                  variant="outlined"
                  onClick={history.goBack}
                  label={intl.formatMessage({
                    id: 'theme.create.button.cancel',
                  })}
                />
                <CustomButton
                  className="button button_contained-violet margin-left-10"
                  variant="contained"
                  disabled={this.disableCheck()}
                  onClick={() =>
                    this.state.subject.length > 500 ? this.openDialog() : this.props.createNewTheme(this.state)
                  }
                  label={intl.formatMessage({
                    id: 'theme.create.button.create',
                  })}
                />
              </CardActions>
            </Card>
          </div>
        </div>
        <DialogModal
          handleConfirm={() => {}}
          handleClose={this.closeDialog}
          titleTextId="editor.subjectValidation.warningTitle"
          textId="editor.subjectValidation.warningText"
          open={this.state.isDialogVisible}
          showButtons={false}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createNewTheme: bindActionCreators(createTheme, dispatch),
  };
}

export default injectIntl(
  connect(
    null,
    mapDispatchToProps,
  )(ThemeCreate),
);
