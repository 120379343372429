import * as React from 'react';
import INotificationDetails from 'models/INotificationDetails';
import { FormattedDate, FormattedMessage } from 'react-intl';
import meetupPublishedIcon from 'assets/images/icons/meetupPublishedIcon.png';
import oneHourIcon from 'assets/images/icons/oneHourIcon.png';
import fifteenMinIcon from 'assets/images/icons/fifteenMinIcon.png';
import meetupRemovedIcon from 'assets/images/icons/meetupRemovedIcon.png';
import meetupChangedIcon from 'assets/images/icons/meetupChangedIcon.png';
import themeApprovedIcon from 'assets/images/icons/themeApprovedIcon.png';
import themePublishedIcon from 'assets/images/icons/themePublishedIcon.svg';
import meetupToModerate from 'assets/images/icons/meetupToModerate.svg';
import themeRemovedIcon from 'assets/images/icons/themeRemovedIcon.png';
import newNewsPublishedIcon from 'assets/images/icons/newNewsPublishedIcon.png';
import fileIcon from 'assets/images/icons/fileIcon.png';
import classNames from 'classnames';
import { RouteUrls } from 'constants/RouteUrls';
import { replaceUrlParams } from 'utils/routeUrls';
import history from 'utils/history';

interface INotificationCardProps {
  notification: INotificationDetails;
  onClose: () => void;
}

export default class NotificationCard extends React.Component<INotificationCardProps, {}> {
  getIconByType() {
    let iconSource = '';
    switch (this.props.notification.type) {
      case 'theme-approved':
        iconSource = themeApprovedIcon;
        break;
      case 'theme-deleted':
        iconSource = themeRemovedIcon;
        break;
      case 'meetup-changed':
        iconSource = meetupChangedIcon;
        break;
      case 'meetup-deleted':
        iconSource = meetupRemovedIcon;
        break;
      case 'start-15-minutes':
        iconSource = fifteenMinIcon;
        break;
      case 'start-hour':
        iconSource = oneHourIcon;
        break;
      case 'news-added':
        iconSource = newNewsPublishedIcon;
        break;
      case 'theme-added':
        iconSource = themePublishedIcon;
        break;
      case 'meetup-published':
      case 'meetup-changed-published':
        iconSource = meetupPublishedIcon;
        break;
      case 'meetup-to-moderate':
        iconSource = meetupToModerate;
        break;
      case 'attached-file-meetup':
        iconSource = fileIcon;
        break;
      default:
        iconSource = meetupPublishedIcon;
    }
    return <img className="notification-list__icon" src={iconSource} alt={this.props.notification.type} />;
  }

  getLinkByType() {
    const { type, meetup, news } = this.props.notification;
    switch (type) {
      case 'theme-deleted':
      case 'meetup-deleted':
      case 'meetup-to-moderate':
        return '';
      case 'theme-approved':
      case 'meetup-published':
      case 'meetup-changed':
      case 'start-15-minutes':
      case 'start-hour':
      case 'meetup-changed-published':
      case 'attached-file-meetup':
        return replaceUrlParams(RouteUrls.PreviewEvent, { id: meetup.id });
      case 'news-added':
        return replaceUrlParams(RouteUrls.PreviewNews, { id: news.id });
      case 'theme-added':
        return replaceUrlParams(RouteUrls.PreviewTheme, { id: meetup.id });
      default:
        return '';
    }
  }

  redirect = () => {
    this.getLinkByType().length > 0 && history.push(this.getLinkByType());
  };

  public render() {
    const { id, type, meetup, news, read, created } = this.props.notification;
    const messageId = `notificationList.${type}`;
    const cantClick = this.getLinkByType().length === 0;

    return (
      <li
        className={classNames('notification-list__card', read && 'notification-list__read')}
        key={String(id)}
        id={String(id)}
      >
        <div>{this.getIconByType()}</div>
        <div className="notification-list__card__main-block">
          <div
            className={classNames(
              'notification-list__card__text-block',
              cantClick && 'notification-list__card__text-block__no-click',
            )}
            onClick={this.redirect}
          >
            <div className="notification-list__card__text-block__header">
              <FormattedMessage id={messageId} />
            </div>
            <div className="notification-list__card__text-block__subject">{meetup && meetup.subject}</div>
            <div className="notification-list__card__text-block__subject">{news && news.subject}</div>
          </div>
          <div>
            <div className="notification-list__card__date">
              <FormattedDate value={created} day="numeric" month="long" />
            </div>
          </div>
        </div>
      </li>
    );
  }
}
