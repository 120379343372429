import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileDropzone from 'components/FileDropzone/FileDropzone';
import { FormattedMessage } from 'react-intl';
import IFile from 'models/IFile';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'assets/images/icons/CloseIcon';
import FileCard from './FileCard';
import GooeyLoader from 'components/GooeyLoader/GooeyLoader';

interface IMeetupAddFileDialogProps {
  meetupId: string;
  isOpen: boolean;
  onClose: () => void;
  uploadMeetupFile: (id: string, files: File[]) => {};
  existingFiles: IFile[];
  fileUploaded: boolean;
}

export default class MeetupAddFileDialog extends React.Component<IMeetupAddFileDialogProps, {}> {
  public fileDropHandler = (files: File[]) => {
    if (files && files.length > 0) {
      this.props.fileUploaded && this.props.uploadMeetupFile(this.props.meetupId, files);
    }
  };

  public render() {
    const { isOpen, onClose, fileUploaded, existingFiles } = this.props;
    return (
      <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className="add-file">
        <DialogTitle id="form-dialog-title" className="add-file__dialog__header">
          <div className="add-file__dialog__header__title">
            <FormattedMessage id="meetupList.meetupPreview.form.title" />
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="add-file__dialog">
          <div className="add-file__dialog__content">
            <FileDropzone onDrop={this.fileDropHandler} files={this.props.existingFiles} />
            <div>
              <div className="add-file__dialog__content__list-title">
                <FormattedMessage id="meetupList.meetupPreview.fileUpload.list" />
              </div>
              <ul className="add-file__dialog__content__list">
                {existingFiles.map((file: IFile) => {
                  return <FileCard file={file} />;
                })}
                {!fileUploaded && (
                  <li key={String(existingFiles.length)} className="add-file__dialog__content__list__loader">
                    <GooeyLoader isLoading={true} />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
