import React, { memo } from 'react';

const ClockIcon = () => (
  <svg
    className="preview__time-n-place-icon"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.804 14.608C11.5617 14.608 14.608 11.5617 14.608 7.804C14.608 4.04625 11.5617 1 7.804 1C4.04625 1 1 4.04625 1 7.804C1 11.5617 4.04625 14.608 7.804 14.608Z"
      stroke="#AAB6CA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.80371 3.72156V7.80396L10.5253 9.16476" stroke="#AAB6CA" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default memo(ClockIcon);
