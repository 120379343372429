import * as React from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import GooeyLoader from 'components/GooeyLoader/GooeyLoader';
import INewsDetails from 'models/INewsDetails';
import RichEditor from 'components/Editor/RichEditor';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchNews } from 'containers/News/NewsPreview/NewsPreview.actions';
import { IStore } from 'store/rootReducer';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { resetNewsData } from 'containers/News/NewsPreview/NewsPreview.actions';
import { RouteUrls } from 'constants/RouteUrls';
import { UserRoles } from 'constants/UserRoles';
import { replaceUrlParams } from 'utils/routeUrls';
import { changeSelectedNews } from 'containers/NewsList/NewsList.actions';
import iconDislike from 'assets/images/icons/icon-dislike.svg';
import iconLike from 'assets/images/icons/icon-like.svg';
import classNames from 'classnames';
import { dislikeNews, likeNews } from '../NewsLike/NewsLike.actions';

interface INewsPreviewProps {
  currentNews: INewsDetails;
  match: {
    params: {
      id: string;
    };
  };
  changeSelectedNews: (id: string) => void;
  intl: IntlShape;
  fetchNews: (id: string) => {};
  resetNewsData: () => {};
  userRole: UserRoles[];
  likeNews: (newsId: string) => void;
  dislikeNews: (newsId: string) => void;
}

class NewsPreview extends React.Component<INewsPreviewProps, {}> {
  public componentDidMount(): void {
    this.props.fetchNews(this.props.match.params.id);
    this.props.changeSelectedNews(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: Readonly<INewsPreviewProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.fetchNews(this.props.match.params.id);
      this.props.changeSelectedNews(this.props.match.params.id);
    }
  }

  public handleLike = () => {
    const { id, isLikedByUser } = this.props.currentNews;
    this.props.changeSelectedNews(String(id));
    isLikedByUser ? this.props.dislikeNews(String(id)) : this.props.likeNews(String(id));
  };

  public componentWillUnmount(): void {
    this.props.resetNewsData();
  }

  public render() {
    const { subject, image, description, id, isLikedByUser, likes = 0 } = this.props.currentNews;
    const { intl, userRole } = this.props;
    const hasLikes = likes !== null && likes > 0;
    return (
      <div className="meetup-list__wrapper">
        <div className="meetup-list">
          <h1 className="meetup-list__header__heading margin-bottom-50 text-align-center">
            <FormattedMessage id="newsList.newsPreview.title" />
          </h1>
          <div className="card card__no-padding card__10-bottom-margin">
            <div className="news-preview__img-wrapper">
              {image ? (
                <img className="preview__topic-img" src={image!} alt="meetup-example" />
              ) : (
                <GooeyLoader isLoading={true} />
              )}
            </div>
          </div>
          <div className="card card__no-padding">
            <div className="news-preview__likes">
              <span className={classNames('news-preview__likes__count', { hidden: !hasLikes })}>{likes}</span>
              {isLikedByUser ? (
                <img src={iconLike} alt={'icon-like'} onClick={this.handleLike} />
              ) : (
                <img src={iconDislike} alt={'icon-dislike'} onClick={this.handleLike} />
              )}
            </div>
            <h2 className="news-preview__header">{subject}</h2>
            <div className="card__description news-preview__description">
              {description.blocks && description.blocks.length > 0 ? (
                <RichEditor intl={intl} readonly value={description} />
              ) : null}
            </div>
          </div>
          <div className="card card__controls card__actions">
            <Link to={RouteUrls.NewsList}>
              <button className="button go-back-button">
                <FormattedMessage id="meetupList.meetupPreview.button.goBack" />
              </button>
            </Link>
            {userRole.includes(UserRoles.NEWS_PUBLISHER) ? (
              <Link className="button__no-outline" to={replaceUrlParams(RouteUrls.EditNews, { id })}>
                <CustomButton
                  className="button button_outlined-violet"
                  label={intl.formatMessage({
                    id: 'meetupList.newsPreview.button.edit',
                  })}
                  variant="outlined"
                />
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    currentNews: state.currentNews,
    userRole: state.userInfo.user.roles,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchNews: bindActionCreators(fetchNews, dispatch),
    resetNewsData: bindActionCreators(resetNewsData, dispatch),
    changeSelectedNews: bindActionCreators(changeSelectedNews, dispatch),
    likeNews: bindActionCreators(likeNews, dispatch),
    dislikeNews: bindActionCreators(dislikeNews, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewsPreview),
);
