import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import IImageInfo from 'models/IImageInfo';
import { formatBytes } from 'utils/bytesFormatter';
import uploadedImage from 'assets/images/uploadedImage.png';

interface IUploadedImageProps {
  clearSelectedImage: () => void;
  imageInfo: IImageInfo;
}

class UploadedImage extends React.Component<IUploadedImageProps, {}> {
  public clearSelectedImage = () => {
    this.props.clearSelectedImage();
  };

  public render() {
    const {
      imageInfo: { imageTitle, imageSize, imagePreviewUrl },
    } = this.props;
    return (
      <div className="image-loaded">
        <p className="image-loaded__heading">
          <FormattedMessage id="loadedImage.text" />
        </p>
        <div
          className={classNames('image-loaded__container', {
            'image-loaded__container--loaded': !!imagePreviewUrl,
          })}
        >
          <img
            className={classNames('image-loaded__image', {
              'image-loaded__image--loaded': !!imagePreviewUrl,
            })}
            src={imagePreviewUrl || uploadedImage}
            alt="uploadedImage"
          />
          <div className={classNames('image-loaded__info', { 'image-loaded__info--loaded': !!imagePreviewUrl })}>
            <p className="image-loaded__image-title">{imageTitle}</p>
            <p className="image-loaded__image-size">
              <FormattedMessage id="loadedImage.fileSize" /> {formatBytes(imageSize)}
            </p>
          </div>
          <Close
            className={classNames('image-loaded__icon', { 'image-loaded__icon--loaded': !!imagePreviewUrl })}
            onClick={this.clearSelectedImage}
          />
        </div>
      </div>
    );
  }
}

export default UploadedImage;
