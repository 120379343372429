export enum RouteUrls {
  NewsList = '/newsList',
  CreateTheme = '/createTheme',
  CreateMeetup = '/createMeetup',
  CreateNews = '/createNews',
  EditMeetup = '/editMeetup/:id',
  EditNews = '/editNews/:id',
  EditTheme = '/editTheme/:id',
  PreviewEvent = '/previewEvent/:id',
  PreviewTheme = '/previewTheme/:id',
  PreviewNews = '/previewNews/:id',
  PageNotFound = '/pageNotFound',
  RestrictedPage = '/restrictedPage',
  UnauthorizedPage = '/unauthorized',
  DraftMeetup = '/meetuplist/draft',
  FutureMeetup = '/meetuplist/future',
  PastMeetup = '/meetuplist/past',
  Themes = '/meetuplist/themes',
  MeetupList = '/meetuplist',
  AdminPanel = '/adminPanel',
  RoleManagement = '/roleManagement',
  MemoManagement = '/memoManagement',
  EditMemo = '/editMemo',
  Statistics = '/statistics',
}
