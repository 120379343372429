import {
  FETCH_NOTIFICATION_LIST,
  FETCH_NOTIFICATION_LIST_ERROR,
  FETCH_NOTIFICATION_LIST_SUCCESS,
} from 'constants/actionConstants';
import INotificationDetails from '../../models/INotificationDetails';

export interface INotificationStore {
  notifications: INotificationDetails[];
  isEmpty: boolean;
}

const initState: INotificationStore = {
  notifications: [],
  isEmpty: false,
};

export default function notificationReducer(
  state: INotificationStore = initState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case FETCH_NOTIFICATION_LIST:
      return { ...state };
    case FETCH_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        isEmpty: action.payload.length === 0,
      };
    case FETCH_NOTIFICATION_LIST_ERROR:
      return { ...state };
    default:
      return state;
  }
}
