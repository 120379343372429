import {
  EDIT_MEETUP,
  EDIT_MEETUP_SUCCESS,
  EDIT_MEETUP_ERROR
} from 'constants/actionConstants';

export function editMeetup() {
  return { type: EDIT_MEETUP };
}

export function editMeetupSuccess() {
  return { type: EDIT_MEETUP_SUCCESS };
}

export function editMeetupError(error: Error) {
  return { type: EDIT_MEETUP_ERROR, payload: error };
}
