import classNames from 'classnames';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { IntlShape } from 'react-intl';
import { descriptionLimit, placeLimit, otherLimit } from 'constants/inputValueLimits';

interface ICustomFieldProps {
  intl: IntlShape;
  className: string;
  label: string;
  value: string;
  multiline: boolean;
  name: string;
  maxLength?: number;
  rows: number;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ICustomFieldState {
  isFocused: boolean;
  shouldValidate: boolean;
}

export default class CustomInput extends React.Component<ICustomFieldProps, ICustomFieldState> {
  public state = {
    isFocused: false,
    shouldValidate: false,
  };

  public render() {
    const { intl, maxLength, className, label, value, multiline, rows, disabled, onChange, name } = this.props;
    const { isFocused } = this.state;
    const isInvalid = !!maxLength && value && value.length < maxLength;
    const counterValue = value && value.length ? value.length + '/' + maxLength : maxLength;

    return (
      <>
        <div className="input-label">{label}</div>
        <TextField
          onFocus={() => {
            name === 'subject'
              ? this.setState({ isFocused: !isFocused, shouldValidate: true })
              : this.setState({ isFocused: !isFocused, shouldValidate: false });
          }}
          onBlur={() => this.setState({ isFocused: !isFocused })}
          className={classNames('form__field', className)}
          InputLabelProps={{
            classes: {
              root: 'form__field-label',
              shrink: 'form__field-label_shrink',
              focused: 'form__field-label_focused',
              disabled: 'form__field-label_disabled',
            },
          }}
          InputProps={{
            classes: {
              root: 'form__field-input-wrapper',
              disabled: 'form__field-input-wrapper_disabled',
              focused: 'form__field-input-wrapper_focused',
              input: 'form__field-input',
            },
            inputProps: {
              maxLength:
                label ===
                intl.formatMessage({
                  id: 'meetupList.meetupCreate.labelPlace',
                })
                  ? placeLimit
                  : label ===
                    intl.formatMessage({
                      id: 'meetupList.meetupCreate.labelDescription',
                    })
                  ? descriptionLimit
                  : otherLimit,
            },
          }}
          name={name}
          fullWidth
          value={value}
          variant="outlined"
          multiline={multiline}
          rows={rows}
          disabled={disabled}
          onChange={onChange}
        />
        <div
          className={
            isInvalid
              ? classNames('form__field__counter', { form__field__show: isFocused })
              : classNames('form__field__counter', 'form__field__invalid', { form__field__show: isFocused })
          }
        >
          <span className={value ? 'form__field__show' : 'form__field__hide'}>{counterValue}</span>
        </div>
      </>
    );
  }
}
