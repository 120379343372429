import { DELETE_MEETUP, DELETE_MEETUP_SUCCESS, DELETE_MEETUP_ERROR } from 'constants/actionConstants';
import { UserRoles } from 'constants/UserRoles';

export function deleteMeetup(meetupId: string, userRoles: UserRoles[]) {
  return { type: DELETE_MEETUP, payload: meetupId, userRoles };
}

export function deleteMeetupSuccess() {
  return { type: DELETE_MEETUP_SUCCESS };
}

export function deleteMeetupError(error: {}) {
  return {
    type: DELETE_MEETUP_ERROR,
    payload: error,
  };
}
