import ApiService from 'services/ApiService';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_NEWS_LIST } from 'constants/actionConstants';
import { fetchedNewsListError, fetchedNewsListSuccess } from './NewsList.actions';
import INewsDetails from 'models/INewsDetails';
import INewsImage from 'models/INewsImage';

function* fetchNewsListAsync() {
  try {
    const newsData = yield call(() => ApiService.getAllNews());
    const setOfImages = new Set<String>();
    newsData.data.forEach((news: INewsDetails) => news.picId && setOfImages.add(news.picId));
    const promises = Array.from(setOfImages).map((picId: any) =>
      (async () => {
        const response = await ApiService.getNewsListImage(picId.toString(), {});
        return { picId, image: response.data };
      })(),
    );

    const imageCollection: [INewsImage] = yield all(promises);

    const data = newsData.data.map((news: any) => {
      const record: INewsImage | undefined = imageCollection.find(record => record.picId === news.picId);
      return { ...news, image: record && record.image };
    });
    yield put(fetchedNewsListSuccess(data));
  } catch (error) {
    yield put(fetchedNewsListError(error));
  }
}

export const newsListSagas = [takeEvery(FETCH_NEWS_LIST, fetchNewsListAsync)];
