import React, { memo } from 'react';

const Bucket = props => (
  <svg
    className="lds-gooeyring"
    width="200px"
    height="200px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <defs>
      <filter
        id="gooey"
        x="-100%"
        y="-100%"
        width="300%"
        height="300%"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2.4000000000000004" />
        <feComponentTransfer result="cutoff">
          <feFuncA type="linear" slope="10" intercept="-5" />
        </feComponentTransfer>
      </filter>
    </defs>
    <g filter="url(#gooey)">
      <g transform="translate(50 50)">
        <g transform="rotate(236.818)">
          <circle cx="17" cy="0" r="6.88486" fill="#f7f7f7">
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
              dur="4s"
              repeatCount="indefinite"
              begin="-4s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="4s"
            repeatCount="indefinite"
            begin="0s"
          />
        </g>
      </g>
      <g transform="translate(50 50)">
        <g transform="rotate(257.635)">
          <circle cx="17" cy="0" r="4.40972" fill="#c2cbda">
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
              dur="2s"
              repeatCount="indefinite"
              begin="-3.2s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="2s"
            repeatCount="indefinite"
            begin="-0.8s"
          />
        </g>
      </g>
      <g transform="translate(50 50)">
        <g transform="rotate(62.4532)">
          <circle cx="17" cy="0" r="5.77458" fill="#5b6887">
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
              dur="1.3333333333333333s"
              repeatCount="indefinite"
              begin="-2.4s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="1.3333333333333333s"
            repeatCount="indefinite"
            begin="-1.6s"
          />
        </g>
      </g>
      <g transform="translate(50 50)">
        <g transform="rotate(11.2709)">
          <circle cx="17" cy="0" r="5.82056" fill="#8065ec">
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
              dur="1s"
              repeatCount="indefinite"
              begin="-1.6s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="1s"
            repeatCount="indefinite"
            begin="-2.4s"
          />
        </g>
      </g>
      <g transform="translate(50 50)">
        <g transform="rotate(104.089)">
          <circle cx="17" cy="0" r="6.3757" fill="#314ad7">
            <animate
              attributeName="r"
              keyTimes="0;0.5;1"
              values="3.5999999999999996;8.399999999999999;3.5999999999999996"
              dur="0.8s"
              repeatCount="indefinite"
              begin="-0.8s"
            />
          </circle>
          <animateTransform
            attributeName="transform"
            type="rotate"
            keyTimes="0;1"
            values="0;360"
            dur="0.8s"
            repeatCount="indefinite"
            begin="-3.2s"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default memo(Bucket);
