import * as React from 'react';
import classNames from 'classnames';
import { IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';

import { RouteUrls } from 'constants/RouteUrls';
import { replaceUrlParams } from 'utils/routeUrls';

import IMeetupShort from 'models/IMeetupShort';
import ThemeCardHeader from './ThemeCardHeader';
import ThemeCardContent from './ThemeCardContent';
import ThemeCardFooter from './ThemeCardFooter';

interface IThemeCardInterfaceProps {
  meetup: IMeetupShort;
  intl?: IntlShape;
}

const ThemeCard = ({ meetup, intl }: IThemeCardInterfaceProps) => {
  const { id, author, subject, excerpt, likesCount, liked } = meetup;

  return (
    <div id={id} className={classNames('meetup-card')}>
      <ThemeCardHeader id={id} author={author} />
      <Link to={replaceUrlParams(RouteUrls.PreviewTheme, { id })}>
        <ThemeCardContent subject={subject} excerpt={excerpt.replace(/&nbsp;/g, '')} />
      </Link>
      <ThemeCardFooter id={id} likesCount={likesCount} intl={intl!} isLiked={liked} />
    </div>
  );
};

export default ThemeCard;
