import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import { call, put, takeEvery } from 'redux-saga/effects';
import { DELETE_NEWS } from 'constants/actionConstants';
import { deleteNewsSuccess, deleteNewsError } from './NewsDelete.actions';
import { fetchNewsList } from 'containers/NewsList/NewsList.actions';
import { NotificationManager } from 'react-notifications';

function* deleteNewsAsync({ payload }: any) {
  try {
    yield call(async () => {
      return await ApiService.news().delete(payload);
    });
    yield put(deleteNewsSuccess());
    NotificationManager.success(
      TranslateService.translate('newsList.newsDelete.deleteSuccess')
    );
    yield put(fetchNewsList());
  } catch (error) {
    yield put(deleteNewsError(error));
  }
}

export const deleteNewsSagas = [takeEvery(DELETE_NEWS, deleteNewsAsync)];
