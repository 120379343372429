import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Transition from './Transition';
import { DialogContent, DialogContentText } from '@material-ui/core';

interface IDialogModal {
  handleConfirm: any;
  handleClose: any;
  titleTextId: string;
  textId: string;
  open: boolean;
  showButtons?: boolean;
  isForRichEditor?: boolean;
}

function DialogModal(props: IDialogModal) {
  const { open, handleClose, titleTextId, textId, handleConfirm, showButtons, isForRichEditor } = props;

  const entityConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleConfirm(event);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <FormattedMessage id={titleTextId} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id={textId} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {showButtons && (
          <Button onClick={handleClose} color="primary">
            <FormattedMessage
              id={isForRichEditor ? 'editor.disagreeCleanStyles' : 'modal.meetupDelete.dialog.disagree'}
            />
          </Button>
        )}
        <Button onClick={entityConfirm} color="primary">
          <FormattedMessage id={isForRichEditor ? 'editor.agreeCleanStyles' : 'modal.meetupDelete.dialog.agree'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogModal;
