import { takeEvery, call, select, put } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import { CREATE_THEME } from 'constants/actionConstants';
import { descriptionLimit } from 'constants/inputValueLimits';
import { meetupStatus } from 'constants/meetupStatus';
import { RouteUrls } from 'constants/RouteUrls';
import { fixDescription } from 'utils/draft.utils';
import history from 'utils/history';
import { replaceUrlParams } from 'utils/routeUrls';
import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import { getCurrentUser } from 'selectors/storeSelectors';
import IAppUser from 'models/IAppUser';
import { createThemeError, createThemeSuccess } from './ThemeCreate.actions';

function* createThemeAsync({ payload }: any) {
  try {
    const { subject } = payload;
    const description = fixDescription(payload.description, descriptionLimit);
    const user: IAppUser = yield select(getCurrentUser);
    const themeData = yield call(() => {
      return ApiService.themes().createNew({
        description,
        subject,
        status: meetupStatus.Request,
        author: user,
        isMeetup: false,
      });
    });

    yield put(createThemeSuccess(themeData));
    yield history.push(replaceUrlParams(RouteUrls.PreviewTheme, { id: themeData.data.id }));
    NotificationManager.success(TranslateService.translate('themeList.themeCreate.createSuccess'));
  } catch (error) {
    yield put(createThemeError(error));
  }
}

export const themeCreateSagas = [takeEvery(CREATE_THEME, createThemeAsync)];
