import {
  CHANGE_SELECTED_MEETUP,
  CLEAR_DELETED_MEETUP,
  CLEAR_SELECTED_MEETUP,
  DELETE_SELECTED_MEETUP,
  FETCH_MEETUPS_LIST,
  FETCH_THEMES_LIST,
  FETCHED_MEETUPS_LIST_ERROR,
  FETCHED_MEETUPS_LIST_SUCCESS,
  FETCHED_THEMES_LIST_ERROR,
  FETCHED_THEMES_LIST_SUCCESS,
  SAVE_ID_MEETUP_FOR_DELETE,
  SAVE_PATHNAME_MEETUP_TABS,
} from 'constants/actionConstants';
import IMeetupShort from 'models/IMeetupShort';
import { meetupStatus } from 'constants/meetupStatus';
import { meetupFilter } from 'constants/meetupFilter';
import { storeNames } from 'constants/storeNames';

export interface IMeetupStore {
  themes: IMeetupShort[];
  upcomingMeetups: IMeetupShort[];
  draftMeetups: IMeetupShort[];
  isOverMeetups: IMeetupShort[];
  selectedMeetup: string;
  goBackUrl: string;
  idForDelete: string;
}

const initState: IMeetupStore = {
  themes: [],
  upcomingMeetups: [],
  draftMeetups: [],
  isOverMeetups: [],
  selectedMeetup: '',
  goBackUrl: '',
  idForDelete: '',
};

export default function meetupsReducer(state: IMeetupStore = initState, action: { type: string; payload: any }) {
  switch (action.type) {
    case FETCH_MEETUPS_LIST:
      return { ...state };
    case FETCH_THEMES_LIST:
      return { ...state };
    case FETCHED_THEMES_LIST_SUCCESS:
      return { ...state, themes: action.payload.data };
    case FETCHED_THEMES_LIST_ERROR:
      return { ...state };
    case FETCHED_MEETUPS_LIST_SUCCESS:
      const selector = action.payload.meetupSelector;
      const meetupSectionName =
        selector.status === meetupStatus.Draft
          ? storeNames.DraftMeetups
          : selector.status === meetupStatus.Confirmed && selector.filter === meetupFilter.COMING
          ? storeNames.UpcomingMeetups
          : selector.filter === meetupFilter.PAST
          ? storeNames.IsOverMeetups
          : meetupStatus.Request;
      return {
        ...state,
        [meetupSectionName]: action.payload.meetups.data,
      };
    case FETCHED_MEETUPS_LIST_ERROR:
      return { ...state };
    case CHANGE_SELECTED_MEETUP:
      return { ...state, selectedMeetup: action.payload };
    case DELETE_SELECTED_MEETUP:
      return { ...state, selectedMeetup: action.payload };
    case SAVE_PATHNAME_MEETUP_TABS:
      return { ...state, goBackUrl: action.payload };
    case SAVE_ID_MEETUP_FOR_DELETE:
      return { ...state, idForDelete: action.payload };
    case CLEAR_DELETED_MEETUP:
      return { ...state, idForDelete: '' };
    case CLEAR_SELECTED_MEETUP:
      return { ...state, selectedMeetup: '' };
    default:
      return state;
  }
}
