import React from 'react';
import IFile from 'models/IFile';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import iconDownload from 'assets/images/icons/icon-download.svg';
import { FormattedMessage, IntlShape } from 'react-intl';
import CustomButton from 'components/CustomButton/CustomButton';
import { deleteFile } from '../../MeetupFile/FileDelete/FileDelete.actions';
import { downloadFileArchieve } from '../../MeetupFile/FileArchieveDownload/FileArchieveDownload.actions';
import { downloadFile } from '../../MeetupFile/FileDonwload/FileDownload.actions';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { mediaTypes } from '../../../../constants/mediaTypes';
import { triggerTypes } from '../../../../constants/triggerTypes';
import DeleteModal from '../../../../components/Modal/DeleteModal';

interface IMeetupFileListProps {
  meetupId: string;
  existingFiles: IFile[];
  userCanAddFiles: boolean;
  handleOpenDialog: () => void;
  intl: IntlShape;
  downloadFileArchieve: (id: string) => {};
  deleteFile: (fileId: string) => {};
  downloadFile: (fileId: string, fileName: string) => {};
}

class MeetupFileList extends React.Component<IMeetupFileListProps, {}> {
  public deleteFile(fileId: string) {
    this.props.deleteFile(fileId);
  }

  public downloadFile(fileId: string, fileName: string) {
    this.props.downloadFile(fileId, fileName);
  }

  public downloadFileArchieve(meetupId: string) {
    this.props.downloadFileArchieve(meetupId);
  }

  public render() {
    const { existingFiles, userCanAddFiles, handleOpenDialog, intl, meetupId } = this.props;
    return (
      <div>
        <Table size="small" aria-label="simple table" className="add-file__table">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="meetupList.meetupPreview.fileList.name" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="meetupList.meetupPreview.fileList.size" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="meetupList.meetupPreview.fileList.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {existingFiles.map(file => (
              <TableRow key={file.id}>
                <TableCell component="th" scope="row" className="add-file__cell__name-cell">
                  {file.name}
                </TableCell>
                <TableCell className="add-file__cell">
                  {file.size} <FormattedMessage id="loadedFile.mb" />
                </TableCell>
                <TableCell className="add-file__cell__buttons-cell">
                  <IconButton onClick={() => this.downloadFile(file.id, file.name)}>
                    <img src={iconDownload} alt={'icon-download'} />
                  </IconButton>
                  {userCanAddFiles && (
                    <DeleteModal
                      handleDelete={() => this.deleteFile(file.id)}
                      mediaType={mediaTypes.File}
                      triggerType={triggerTypes.Icon}
                      isConfirmationNeeded={true}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="add-file__action-panel">
          {existingFiles.length > 0 ? (
            <CustomButton
              className="add-file__action-panel__button"
              label={intl.formatMessage({ id: 'meetupList.meetupPreview.button.downloadAll' })}
              variant="outlined"
              onClick={() => this.downloadFileArchieve(meetupId)}
            />
          ) : (
            <div className="add-file__no-files">
              <FormattedMessage id="meetupList.meetupPreview.fileList.noFiles" />
            </div>
          )}
          {userCanAddFiles && (
            <CustomButton
              className="add-file__action-panel__button"
              label={intl.formatMessage({ id: 'meetupList.meetupPreview.button.addFile' })}
              variant="outlined"
              onClick={handleOpenDialog}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    downloadFileArchieve: bindActionCreators(downloadFileArchieve, dispatch),
    deleteFile: bindActionCreators(deleteFile, dispatch),
    downloadFile: bindActionCreators(downloadFile, dispatch),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(MeetupFileList);
