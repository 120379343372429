import { FETCH_USER_PHOTO, FETCH_USER_PHOTO_ERROR, FETCH_USER_PHOTO_SUCCESS } from 'constants/actionConstants';
import { IUserPhoto } from './UserAvatar.reducer';
import IUser from 'models/IUser';

export function fetchUsersPhoto(users: IUser[]) {
  return { type: FETCH_USER_PHOTO, payload: users };
}

export function fetchUserPhotoSuccess(photos: IUserPhoto[]) {
  return { type: FETCH_USER_PHOTO_SUCCESS, payload: photos };
}

export function fetchUserPhotoError() {
  return { type: FETCH_USER_PHOTO_ERROR, payload: [] };
}
