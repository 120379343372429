import * as React from 'react';

interface INoAccessProps {
  login: () => void;
}

export default class NoAccess extends React.Component<INoAccessProps, {}> {
  public componentDidMount(): void {
    setTimeout(() => {
      this.props.login();
    }, 2000);
  }

  public render() {
    return (
      <div className="la-pacman la-3x">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
}
