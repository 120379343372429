import { UserRoles } from 'constants/UserRoles';
import IMeetupAuthor from 'models/IMeetupAuthor';
import IAppUser from 'models/IAppUser';
import IMeetupSpeaker from 'models/IMeetupSpeaker';
import { meetupStatus } from 'constants/meetupStatus';

export function isAuthor(author: IMeetupAuthor, userId: string): boolean {
  return author && author.id === userId;
}
export function isChief(user: IAppUser): boolean {
  return user.roles!.includes(UserRoles.CHIEF);
}
export function isSpeaker(speaker: IMeetupSpeaker, userId: string): boolean {
  return speaker && speaker.id === userId;
}

export const canEditMeetup = (
  speaker: IMeetupSpeaker,
  author: IMeetupAuthor,
  status: meetupStatus,
  isOver: boolean,
  user: IAppUser,
): boolean => {
  const isUserAuthor = isAuthor(author, user.id);
  const isUserSpeaker = isSpeaker(speaker, user.id);
  const isUserChief = isChief(user);
  if (status === meetupStatus.Draft) {
    return isUserChief || isUserAuthor || isUserSpeaker;
  }
  if (status === meetupStatus.Confirmed && !isOver) {
    return isUserChief;
  }
  if (status === meetupStatus.Confirmed && isOver) {
    return isUserChief || isUserAuthor || isUserSpeaker;
  }
  return false;
};
export const canDeleteMeetup = (user: IAppUser): boolean => {
  return isChief(user);
};

//themes rules
export const canEditTheme = (author: IMeetupAuthor, user: IAppUser): boolean => {
  return isAuthor(author, user.id) || isChief(user);
};
export const canDeleteTheme = (author: IMeetupAuthor, user: IAppUser): boolean => {
  return isAuthor(author, user.id) || isChief(user);
};
export const canApproveTheme = (user: IAppUser): boolean => {
  return isChief(user);
};

//draft meetups rules
export const canCreateDraftMeetup = (user: IAppUser): boolean => {
  return isChief(user);
};
export const canEditDraftMeetup = (speaker: IMeetupSpeaker, author: IMeetupAuthor, user: IAppUser): boolean => {
  return isAuthor(author, user.id) || isChief(user) || isSpeaker(speaker, user.id);
};
export const canDeleteDraftMeetup = (user: IAppUser): boolean => {
  return isChief(user);
};
export const canPublishDraftMeetup = (user: IAppUser): boolean => {
  return isChief(user);
};
export const canAddFiles = (
  speaker: IMeetupSpeaker,
  author: IMeetupAuthor,
  user: IAppUser,
  status: meetupStatus,
): boolean => {
  return (isAuthor(author, user.id) || isChief(user) || isSpeaker(speaker, user.id)) && status !== meetupStatus.Request;
};

//future meetups rules
export const canEditFutureMeetup = (user: IAppUser): boolean => {
  return isChief(user);
};
export const canDeleteFutureMeetup = (user: IAppUser): boolean => {
  return isChief(user);
};
export const canWithdrawFutureMeetup = (user: IAppUser): boolean => {
  return isChief(user);
};

//past meetups rules
export const canEditPastMeetup = (speaker: IMeetupSpeaker, author: IMeetupAuthor, user: IAppUser): boolean => {
  return isAuthor(author, user.id) || isChief(user) || isSpeaker(speaker, user.id);
};
export const canDeletePastMeetup = (user: IAppUser): boolean => {
  return isChief(user);
};
