import { fieldNames } from 'constants/fieldNames';
import IMeetupDetails from 'models/IMeetupDetails';
import { isAuthor, isSpeaker } from 'utils/userRules';
import { UserRoles } from 'constants/UserRoles';
import { meetupStatus } from 'constants/meetupStatus';

export function isFieldEditable(
  field: fieldNames,
  meetup: IMeetupDetails,
  userId: string,
  userRoles: UserRoles[],
): boolean {
  const speaker = meetup.speakers ? meetup.speakers[0] : meetup.author;
  const isUserAuthor = isAuthor(meetup.author, userId);
  const isUserSpeaker = isSpeaker(speaker, userId);
  const isChief = userRoles!.includes(UserRoles.CHIEF);
  let canEdit = isChief || isUserAuthor || isUserSpeaker;

  if (meetup.isOver && field !== fieldNames.description && meetup.status !== meetupStatus.Draft) {
    canEdit = isChief;
  }
  if (meetup.status === meetupStatus.Draft) {
    if (field === fieldNames.speaker) {
      return isChief || isUserAuthor;
    }
    if ([fieldNames.start, fieldNames.place].includes(field)) {
      return isChief;
    }
  }
  return canEdit;
}
