import 'styles/main.scss';
import App from 'App';
import { store } from 'store/configureStore';
import IntlComponentWrap from 'containers/IntlWrapComponent/IntlWrapComponent';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

ReactDOM.render(
  <Provider store={store}>
    <IntlComponentWrap>
      <App />
    </IntlComponentWrap>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);
