import React, { memo } from 'react';

const PointIcon = () => (
  <svg
    className="preview__time-n-place-icon"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6 7.62727C13.6 12.7818 7.3 17.2 7.3 17.2C7.3 17.2 1 12.7818 1 7.62727C1 3.96713 3.82061 1 7.3 1C10.7794 1 13.6 3.96713 13.6 7.62727V7.62727Z"
      stroke="#AAB6CA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.3002 9.83639C8.45999 9.83639 9.4002 8.84735 9.4002 7.6273C9.4002 6.40726 8.45999 5.41821 7.3002 5.41821C6.1404 5.41821 5.2002 6.40726 5.2002 7.6273C5.2002 8.84735 6.1404 9.83639 7.3002 9.83639Z"
      stroke="#AAB6CA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(PointIcon);
