import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { RouteUrls } from 'constants/RouteUrls';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { isPathBelongRoute } from 'utils/routeUrls';

interface IHeaderLinksProps {
  location: {
    pathname: string;
  };
  isUserChief: boolean;
}

class HeaderLinks extends React.Component<IHeaderLinksProps, {}> {
  public render() {
    const { isUserChief, location } = this.props;
    const { pathname } = location;
    return (
      <div className={pathname === RouteUrls.UnauthorizedPage ? 'hidden' : 'App-header__links'}>
        <Link to={RouteUrls.Themes}>
          <Button
            className={classNames('App-header__link', {
              'active-menu':
                pathname.toLowerCase().includes('meetup') ||
                pathname.toLowerCase().includes('theme') ||
                pathname.toLowerCase().includes('event') ||
                pathname === RouteUrls.Themes,
            })}
            disableFocusRipple={true}
          >
            <FormattedMessage id="header.links.meetups" />
          </Button>
        </Link>
        <Link to={RouteUrls.NewsList}>
          <Button
            className={classNames('App-header__link', {
              'active-menu': pathname.toLowerCase().includes('news'),
            })}
            disableFocusRipple={true}
          >
            <FormattedMessage id="header.links.news" />
          </Button>
        </Link>
        {isUserChief && (
          <Link to={RouteUrls.AdminPanel}>
            <Button
              className={classNames('App-header__link', {
                'active-menu': isPathBelongRoute(pathname, RouteUrls.AdminPanel),
              })}
              disableFocusRipple={true}
            >
              <FormattedMessage id="header.links.adminPanel" />
            </Button>
          </Link>
        )}
      </div>
    );
  }
}

export default HeaderLinks;
