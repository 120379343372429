import { CREATE_THEME, CREATE_THEME_ERROR, CREATE_THEME_SUCCESS } from 'constants/actionConstants';

export function createTheme(theme: any) {
  return { type: CREATE_THEME, payload: theme };
}

export function createThemeSuccess({ id }: any) {
  return { type: CREATE_THEME_SUCCESS, payload: id };
}

export function createThemeError(error: {}) {
  return { type: CREATE_THEME_ERROR, payload: error };
}
