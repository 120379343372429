import ApiService from 'services/ApiService';
import history from 'utils/history';
import TranslateService from 'services/TranslateService';
import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { CREATE_NEWS, GET_DEFAULT_IMAGES_NEWS } from 'constants/actionConstants';
import {
  createNewsError,
  createNewsSuccess,
  getDefaultImagesNewsError,
  getDefaultImagesNewsSuccess,
} from './NewsCreate.actions';
import { NotificationManager } from 'react-notifications';
import { RouteUrls } from 'constants/RouteUrls';
import { getCurrentNews } from 'selectors/storeSelectors';
import { mediaImageTypes, mediaTypes } from 'constants/mediaTypes';
import { fieldNames } from 'constants/fieldNames';
import { descriptionLimit } from 'constants/inputValueLimits';
import { fixDescription } from 'utils/draft.utils';
import { replaceUrlParams } from 'utils/routeUrls';

function* createNewsAsync() {
  try {
    const payload = yield select(getCurrentNews);

    const { subject, image, picId } = payload;
    const description = fixDescription(payload.description, descriptionLimit);

    const newsData = yield call(async () => {
      return await ApiService.news().createNew({ subject, description, picId });
    });
    if (image) {
      yield call(async () => {
        const formData = new FormData();
        formData.append(fieldNames.file, image);
        return await ApiService.fileUpload(mediaTypes.News).meetupImageUpload(newsData.data.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      });
    }
    yield put(createNewsSuccess(newsData));
    yield history.push(replaceUrlParams(RouteUrls.PreviewNews, { id: newsData.data.id }));
    NotificationManager.success(TranslateService.translate('newsList.newsCreate.createSuccess'));
  } catch (error) {
    yield put(createNewsError(error));
  }
}

function* getDefaultImages() {
  const newsData = yield select(getCurrentNews);
  try {
    const defaultImagesIds = yield call(async () => {
      return await ApiService.fileUpload(mediaTypes.News).getDefaultImageList({
        params: { type: mediaImageTypes.News },
      });
    });
    const defaultImagesNews = yield all([
      call(async () => {
        return await ApiService.getFileById(defaultImagesIds.data[0], {
          params: { format: 'base64' },
        });
      }),
      call(async () => {
        return await ApiService.getFileById(defaultImagesIds.data[1], {
          params: { format: 'base64' },
        });
      }),
    ]);
    newsData.defaultImages = [];
    newsData.defaultImageIds = [];
    newsData.defaultImageIds = defaultImagesIds.data;
    defaultImagesNews.map((image: any) => newsData.defaultImages.push(image.data));
    yield put(getDefaultImagesNewsSuccess(newsData));
  } catch (error) {
    yield put(getDefaultImagesNewsError(error));
  }
}

export const createNewsSagas = [
  takeEvery(CREATE_NEWS, createNewsAsync),
  takeEvery(GET_DEFAULT_IMAGES_NEWS, getDefaultImages),
];
