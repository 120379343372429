import ApiService from 'services/ApiService';
import { call, put, takeEvery } from 'redux-saga/effects';
import { downloadFileArchieveError, downloadFileArchieveSuccess } from './FileArchieveDownload.actions';
import { fetchMeetup } from '../../MeetupPreview/MeetupPreview.actions';
import { DOWNLOAD_FILE_ARCHIVE } from '../../../../constants/actionConstants';
import { downloadWithLink } from '../../../../utils/fileDownload.utils';

function* fileArchieveDownloadAsync({ payload }: any) {
  try {
    const response = yield call(async () => {
      return await ApiService.archiveFilesDownload(payload, {
        responseType: 'arraybuffer',
      });
    });
    yield downloadWithLink(response, true);
    yield put(downloadFileArchieveSuccess());
    yield put(fetchMeetup(payload));
  } catch (error) {
    yield put(downloadFileArchieveError(error));
  }
}

export const downloadFileArchieveSagas = [takeEvery(DOWNLOAD_FILE_ARCHIVE, fileArchieveDownloadAsync)];
