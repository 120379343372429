import { DOWNLOAD_FILE, DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_ERROR } from 'constants/actionConstants';

export function downloadFile(fileId: string, fileName: string) {
  return { type: DOWNLOAD_FILE, payload: { fileId, fileName } };
}

export function downloadFileSuccess() {
  return { type: DOWNLOAD_FILE_SUCCESS };
}

export function downloadFileError(error: {}) {
  return { type: DOWNLOAD_FILE_ERROR, payload: error };
}
