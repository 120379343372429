import {
  DOWNLOAD_FILE_ARCHIVE,
  DOWNLOAD_FILE_ARCHIVE_SUCCESS,
  DOWNLOAD_FILE_ARCHIVE_ERROR,
} from 'constants/actionConstants';

export function downloadFileArchieve(meetupId: string) {
  return { type: DOWNLOAD_FILE_ARCHIVE, payload: meetupId };
}

export function downloadFileArchieveSuccess() {
  return { type: DOWNLOAD_FILE_ARCHIVE_SUCCESS };
}

export function downloadFileArchieveError(error: {}) {
  return { type: DOWNLOAD_FILE_ARCHIVE_ERROR, payload: error };
}
