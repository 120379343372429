import { takeEvery, call, put } from 'redux-saga/effects';
import { FETCH_THEME } from 'constants/actionConstants';
import ApiService from 'services/ApiService';
import { fetchThemeError, fetchThemeSuccess } from 'containers/Theme/Theme.actions';
import { convertStringToRichText } from 'utils/draft.utils';
import { fetchUsersPhoto } from 'containers/UserAvatar/UserAvatar.actions';
import history from 'utils/history';
import { meetupStatus } from '../../../constants/meetupStatus';
import { replaceUrlParams } from '../../../utils/routeUrls';
import { RouteUrls } from '../../../constants/RouteUrls';

function* fetchThemeAsync({ payload }: any) {
  try {
    const theme = yield call(async () => {
      return await ApiService.themes().getById(payload.id);
    });
    if (theme.data.status !== meetupStatus.Request) {
      history.replace(replaceUrlParams(RouteUrls.PreviewEvent, { id: payload.id }));
    }
    yield put(fetchUsersPhoto([theme.data.author]));
    yield put(fetchThemeSuccess({ ...theme.data, description: convertStringToRichText(theme.data.description) }));
  } catch (error) {
    yield put(fetchThemeError(error));
  }
}

export const themePreviewSagas = [takeEvery(FETCH_THEME, fetchThemeAsync)];
