import { DELETE_FILE, DELETE_FILE_SUCCESS, DELETE_FILE_ERROR } from 'constants/actionConstants';

export function deleteFile(fileId: string) {
  return { type: DELETE_FILE, payload: fileId };
}

export function deleteFileSuccess() {
  return { type: DELETE_FILE_SUCCESS };
}

export function deleteFileError(error: {}) {
  return {
    type: DELETE_FILE_ERROR,
    payload: error,
  };
}
