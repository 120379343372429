import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import CustomUserAvatar from 'components/CustomUserAvatar/CustomUserAvatar';
import DeleteModal from 'components/Modal/DeleteModal';
import { IUserPhoto } from 'containers/UserAvatar/UserAvatar.reducer';

import history from 'utils/history';
import { replaceUrlParams } from 'utils/routeUrls';
import { IStore } from 'store/rootReducer';

import { triggerTypes } from 'constants/triggerTypes';
import { mediaTypes } from 'constants/mediaTypes';
import { RouteUrls } from 'constants/RouteUrls';

import IMeetupAuthor from 'models/IMeetupAuthor';
import IAppUser from 'models/IAppUser';

import { deleteTheme } from 'containers/Theme/ThemeDelete/ThemeDelete.actions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'assets/images/icons/EditIcon';
import { canDeleteTheme, canEditTheme } from 'utils/userRules';
import { saveMeetupIdForDelete } from '../../MeetupList/MeetupList.actions';

interface IThemeHeaderCardProps {
  id: string;
  author: IMeetupAuthor;
  user: IAppUser;
  userPhoto: IUserPhoto[];
  deleteTheme: (themeId: string) => void;
  saveMeetupIdForDelete: (id: string) => {};
}

class ThemeCardHeader extends React.Component<IThemeHeaderCardProps, {}> {
  public handleDelete = () => {
    this.props.saveMeetupIdForDelete(this.props.id);
    this.props.deleteTheme(this.props.id);
  };

  public handleEdit = () => history.push(replaceUrlParams(RouteUrls.EditTheme, { id: this.props.id }));

  render() {
    const { author, user } = this.props;
    return (
      <div className="theme-card__header">
        <div className="theme-card__header__info">
          <div className="theme-card__header-wrapper">
            <CustomUserAvatar user={author} />
          </div>
        </div>
        <div className="theme-card__header__buttons">
          <>
            {canEditTheme(author, user) && (
              <span>
                <DeleteModal
                  handleDelete={this.handleDelete}
                  mediaType={mediaTypes.Theme}
                  triggerType={triggerTypes.Icon}
                  isConfirmationNeeded={true}
                />
              </span>
            )}
            {canDeleteTheme(author, user) && (
              <span>
                <IconButton onClick={this.handleEdit}>
                  <EditIcon />
                </IconButton>
              </span>
            )}
          </>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    user: state.userInfo.user,
    userPhoto: state.usersPhoto,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    deleteTheme: bindActionCreators(deleteTheme, dispatch),
    saveMeetupIdForDelete: bindActionCreators(saveMeetupIdForDelete, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeCardHeader);
