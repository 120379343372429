import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import history from 'utils/history';
import { CREATE_MEETUP, GET_DEFAULT_IMAGES_MEETUP, SEARCH_USER } from 'constants/actionConstants';
import { RouteUrls } from 'constants/RouteUrls';
import { mediaImageTypes, mediaTypes } from 'constants/mediaTypes';
import { fieldNames } from 'constants/fieldNames';
import {
  createMeetupError,
  createMeetupSuccess,
  getDefaultImagesMeetupError,
  getDefaultImagesMeetupSuccess,
  searchUserError,
  searchUserSuccess,
} from './MeetupCreate.actions';
import { getCurrentMeetup } from 'selectors/storeSelectors';
import { descriptionLimit } from 'constants/inputValueLimits';
import { fixDescription } from 'utils/draft.utils';
import { replaceUrlParams } from 'utils/routeUrls';

function* createMeetupAsync() {
  try {
    const payload = yield select(getCurrentMeetup);
    const { image, picId, place, speakerId, subject, start, finish } = payload;
    const description = fixDescription(payload.description, descriptionLimit);
    const meetupData = yield call(async () => {
      return await ApiService.meetUps().createNew({
        description,
        picId,
        place,
        speakerId,
        subject,
        start,
        finish,
        isMeetup: true,
      });
    });
    if (image) {
      yield call(async () => {
        const formData = new FormData();
        formData.append(fieldNames.file, image);
        return await ApiService.fileUpload(mediaTypes.Meetup).meetupImageUpload(meetupData.data.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      });
    }
    yield put(createMeetupSuccess(meetupData));
    NotificationManager.success(TranslateService.translate('meetupList.meetupCreate.createSuccess'));
    yield history.push(replaceUrlParams(RouteUrls.PreviewEvent, { id: meetupData.data.id }));
  } catch (error) {
    yield put(createMeetupError(error));
  }
}

function* searchUserAsync({ payload }: any) {
  try {
    const searchResults = yield call(async () => {
      return await ApiService.searchUsers(payload);
    });
    yield put(searchUserSuccess(searchResults));
  } catch (error) {
    yield put(searchUserError(error));
  }
}

function* getDefaultImages() {
  const meetupData = yield select(getCurrentMeetup);
  try {
    const defaultImagesIds = yield call(async () => {
      return await ApiService.fileUpload(mediaTypes.Meetup).getDefaultImageList({
        params: { type: mediaImageTypes.Meetup },
      });
    });
    const defaultImages = yield all([
      call(async () => {
        return await ApiService.getFileById(defaultImagesIds.data[0], {
          params: { format: 'base64' },
        });
      }),
      call(async () => {
        return await ApiService.getFileById(defaultImagesIds.data[1], {
          params: { format: 'base64' },
        });
      }),
    ]);
    meetupData.defaultImages = [];
    meetupData.defaultImageIds = [];
    meetupData.defaultImageIds = defaultImagesIds.data;
    defaultImages.map((image: any) => meetupData.defaultImages.push(image.data));
    yield put(getDefaultImagesMeetupSuccess(meetupData));
  } catch (error) {
    yield put(getDefaultImagesMeetupError(error));
  }
}

export const createMeetupSagas = [
  takeEvery(CREATE_MEETUP, createMeetupAsync),
  takeEvery(SEARCH_USER, searchUserAsync),
  takeEvery(GET_DEFAULT_IMAGES_MEETUP, getDefaultImages),
];
