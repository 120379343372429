import ApiService from 'services/ApiService';
import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_MEETUPS_LIST, FETCH_THEMES_LIST } from 'constants/actionConstants';
import { meetupStatus } from 'constants/meetupStatus';
import { fetchedMeetupListError, fetchedMeetupsListSuccess } from './MeetupList.actions';
import { fetchedThemesListError, fetchedThemesListSuccess } from 'containers/Theme/ThemeList.actions';
import { fetchUsersPhoto } from 'containers/UserAvatar/UserAvatar.actions';
import IMeetupShort from 'models/IMeetupShort';
import IThemeDetails from 'models/IThemeDetails';

function* fetchThemesListAsync() {
  try {
    const themesData = yield call(async () => {
      return await ApiService.getAllThemes({ params: { status: meetupStatus.Request } });
    });
    const authors = themesData.data.map((theme: IThemeDetails) => {
      return theme.author;
    });
    yield put(fetchUsersPhoto(authors));
    yield put(fetchedThemesListSuccess(themesData));
  } catch (error) {
    yield put(fetchedThemesListError(error));
  }
}

function* fetchMeetupsListAsync({ payload }: any) {
  try {
    const meetupsData = yield call(async () => {
      return await ApiService.getAllThemes({ params: { status: payload.status, filter: payload.filter } });
    });
    const speakers = meetupsData.data.map((meetup: IMeetupShort) => {
      return meetup.speakers ? meetup.speakers[0] : meetup.author;
    });
    yield put(fetchUsersPhoto(speakers));
    yield put(fetchedMeetupsListSuccess(payload, meetupsData));
  } catch (error) {
    yield put(fetchedMeetupListError(error));
  }
}

export const themesSagas = [takeEvery(FETCH_THEMES_LIST, fetchThemesListAsync)];
export const meetupsSagas = [takeEvery(FETCH_MEETUPS_LIST, fetchMeetupsListAsync)];
