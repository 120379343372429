import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import { RawDraftContentState } from 'draft-js';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { IStore } from 'store/rootReducer';
import INewsDetails from 'models/INewsDetails';
import { RouteUrls } from 'constants/RouteUrls';
import { NewsFieldsValuesType } from 'constants/customTypes';
import { isNoEmptyDescription } from 'utils/draft.utils';
import CustomButton from 'components/CustomButton/CustomButton';
import GooeyLoader from 'components/GooeyLoader/GooeyLoader';
import NewsCreateRequiredForm from './NewsCreateRequiredForm/NewsCreateRequiredForm';
import { createNews, resetNewsData, updateNewsData, getDefaultImagesNews } from './NewsCreate.actions';
import DialogModal from '../../../components/Modal/DialogModal';

interface ITabContainerProps {
  children?: React.ReactNode;
}

interface INewsCreateProps {
  intl: IntlShape;
  createNews: () => {};
  resetNewsData: () => {};
  updateNewsData: (payload: { name: string; value: NewsFieldsValuesType }) => void;
  currentNews: INewsDetails;
  defaultImages: string[];
  defaultImageIds: string[];
  getDefaultImages: () => {};
}

interface INewsCreateState {
  formInfo: {
    subject: string;
    description?: RawDraftContentState;
    image: string | null;
    tmpImage?: string | null;
    tmpImageOrig?: string | File | null;
  };
  isDialogVisible: boolean;
}

function TabContainer(props: ITabContainerProps) {
  return (
    <Typography className="meetup-create__content" component="div">
      {props.children}
    </Typography>
  );
}

class NewsCreate extends React.Component<INewsCreateProps, INewsCreateState> {
  public state = {
    formInfo: {
      subject: '',
      description: undefined,
      image: null,
      tmpImage: null,
      tmpImageOrig: null,
    },
    isDialogVisible: false,
  };

  public componentDidMount(): void {
    this.props.resetNewsData();
    this.props.getDefaultImages();
  }

  public componentWillUnmount(): void {
    this.props.resetNewsData();
    this.props.getDefaultImages();
  }

  public onChange = (name: string, value: NewsFieldsValuesType) => {
    this.setState(prevState => ({
      formInfo: {
        ...prevState.formInfo,
        [name]: value,
      },
    }));
    this.props.updateNewsData({ name, value });
  };

  public createNews = () => {
    this.props.createNews();
  };

  public disableCheck = () => {
    return !this.state.formInfo.subject || !isNoEmptyDescription(this.state.formInfo.description);
  };

  public openDialog = () => {
    this.setState({ isDialogVisible: true });
  };

  public closeDialog = () => {
    this.setState({ isDialogVisible: false });
  };

  public render() {
    const { intl } = this.props;
    const formInfo = this.props.currentNews;
    const { defaultImageIds, defaultImages } = formInfo;

    return (
      <div className="news-create">
        <div className="news-create__wrapper">
          <h1 className="news-create__page-heading">
            <FormattedMessage id="newsList.newsCreate.title" />
          </h1>

          <TabContainer>
            {defaultImageIds.length ? (
              <NewsCreateRequiredForm
                intl={intl}
                onChange={this.onChange}
                formInfo={formInfo}
                defaultImages={defaultImages}
                defaultImageIds={defaultImageIds}
              />
            ) : (
              <GooeyLoader isLoading={true} />
            )}
            <Card className="news-create__actions card card_controls card_controls-form">
              <CardActions className="card__actions">
                <Link to={RouteUrls.NewsList}>
                  <CustomButton
                    className="button button_outlined"
                    variant="outlined"
                    disabled={false}
                    label={intl.formatMessage({
                      id: 'meetupList.meetupCreate.button.goBack',
                    })}
                  />
                </Link>
                <CustomButton
                  className="button button_contained-violet margin-left-10"
                  variant="contained"
                  onClick={() => (formInfo.subject.length > 500 ? this.openDialog() : this.createNews())}
                  disabled={this.disableCheck()}
                  label={intl.formatMessage({
                    id: 'newsList.newsCreate.button.create',
                  })}
                />
              </CardActions>
            </Card>
          </TabContainer>
        </div>
        <DialogModal
          handleConfirm={() => {}}
          handleClose={this.closeDialog}
          titleTextId="editor.subjectValidation.warningTitle"
          textId="editor.subjectValidation.warningText"
          open={this.state.isDialogVisible}
          showButtons={false}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    currentNews: state.currentNews,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createNews: bindActionCreators(createNews, dispatch),
    updateNewsData: bindActionCreators(updateNewsData, dispatch),
    resetNewsData: bindActionCreators(resetNewsData, dispatch),
    getDefaultImages: bindActionCreators(getDefaultImagesNews, dispatch),
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NewsCreate),
);
