import { APPROVE_THEME_ERROR, APPROVE_THEME_SUCCESS, APPROVE_THEME } from 'constants/actionConstants';

export function approveTheme(themeId: string) {
  return { type: APPROVE_THEME, payload: themeId };
}

export function approveThemeSuccess() {
  return { type: APPROVE_THEME_SUCCESS };
}

export function approveThemeError(error: {}) {
  return {
    type: APPROVE_THEME_ERROR,
    payload: error,
  };
}
