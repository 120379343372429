import ApiService from 'services/ApiService';
import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_NOTIFICATION_LIST, READ_NOTIFICATIONS } from 'constants/actionConstants';
import {
  fetchNotificationList,
  fetchNotificationListError,
  fetchNotificationListSuccess,
  readNotificationsSuccess,
  readNotificationsListError,
} from './NotificationList.actions';

function* fetchNotificationListAsync() {
  try {
    const notificationData = yield call(async () => {
      return await ApiService.getNotifications();
    });
    yield put(fetchNotificationListSuccess(notificationData.data));
  } catch (error) {
    yield put(fetchNotificationListError(error));
  }
}

function* readNotificationsAsync({ payload }: any) {
  try {
    const data = yield call(async () => {
      return await ApiService.readNotifications(payload);
    });
    yield put(readNotificationsSuccess(data));
    yield put(fetchNotificationList());
  } catch (error) {
    yield put(readNotificationsListError(error));
  }
}

export const notificationListSagas = [
  takeEvery(FETCH_NOTIFICATION_LIST, fetchNotificationListAsync),
  takeEvery(READ_NOTIFICATIONS, readNotificationsAsync),
];
