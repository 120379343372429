import { FETCH_ACCESS_DENIED, FETCH_ACCESS_GRANTED, RESET_ACCESS_ROUTE } from 'constants/actionConstants';

export interface IAccessRoute {
  restricted: boolean;
  redirect: boolean;
}

export default function privateRouteReducer(
  state: IAccessRoute = { restricted: true, redirect: false },
  action: { type: string },
) {
  switch (action.type) {
    case FETCH_ACCESS_GRANTED:
      return {
        ...state,
        restricted: false,
        redirect: false,
      };
    case FETCH_ACCESS_DENIED:
      return {
        ...state,
        restricted: true,
        redirect: true,
      };
    case RESET_ACCESS_ROUTE:
      return {
        ...state,
        restricted: true,
        redirect: false,
      };
    default:
      return state;
  }
}
