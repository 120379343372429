import * as React from 'react';
import DeleteModal from 'components/Modal/DeleteModal';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from 'assets/images/icons/EditIcon';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { deleteMeetup } from 'containers/Meetup/MeetupDelete/MeetupDelete.actions';
import { FormattedDate, IntlShape } from 'react-intl';
import { IStore } from 'store/rootReducer';
import history from 'utils/history';
import { replaceUrlParams } from 'utils/routeUrls';
import { mediaTypes } from 'constants/mediaTypes';
import { triggerTypes } from 'constants/triggerTypes';
import { UserRoles } from 'constants/UserRoles';
import { RouteUrls } from 'constants/RouteUrls';
import IMeetupShort from 'models/IMeetupShort';
import { canDeleteDraftMeetup, canEditMeetup } from 'utils/userRules';
import IAppUser from 'models/IAppUser';
import { cancelGoToMeetup, changeSelectedMeetup, goToMeetup, saveMeetupIdForDelete } from '../../MeetupList.actions';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { meetupStatus } from '../../../../constants/meetupStatus';

interface IMeetupCardHeaderProps {
  meetup: IMeetupShort;
  deleteMeetup: (meetupId: string, userRoles: UserRoles[]) => void;
  user: IAppUser;
  userRole: UserRoles[];
  saveMeetupIdForDelete: (id: string) => {};
  intl?: IntlShape;
  go: boolean;
  goToMeetup: (id: string) => {};
  cancelGoToMeetup: (id: string) => {};
  changeSelectedMeetup: (id: string) => {};
}

class MeetupCardHeader extends React.Component<IMeetupCardHeaderProps, {}> {
  public handleDelete = () => {
    this.props.saveMeetupIdForDelete(this.props.meetup.id);
    this.props.deleteMeetup(this.props.meetup.id, this.props.userRole);
  };

  public handleEdit = () => history.push(replaceUrlParams(RouteUrls.EditMeetup, { id: this.props.meetup.id }));

  public hanldeChangeButton = () => {
    this.props.changeSelectedMeetup(this.props.meetup.id);
    this.props.go ? this.props.cancelGoToMeetup(this.props.meetup.id) : this.props.goToMeetup(this.props.meetup.id);
  };

  public render() {
    const {
      meetup: { author, speakers = [], start, place, status, isOver, go },
      user,
      intl,
    } = this.props;
    const speaker = speakers[0] || author;

    return (
      <div className="meetup-card__header">
        <div className="meetup-card__header__info">
          {!start && !place ? (
            <>&ndash;</>
          ) : (
            <>
              {start && (
                <span className="meetup-card__header__info__item">
                  <FormattedDate weekday="short" value={start} day="numeric" month="long" />
                  <span className="meetup-card__header__info__item-divider">&#8226;</span>
                  <FormattedDate value={start} hour="numeric" minute="numeric" />
                </span>
              )}
              {place && (
                <>
                  <span className="meetup-card__header__info__item-divider">&#8226;</span>
                  <span className="meetup-card__header__info__item">{place}</span>
                </>
              )}
            </>
          )}
        </div>
        <div className="meetup-card__header__buttons">
          {canDeleteDraftMeetup(user) && (
            <span>
              <DeleteModal
                handleDelete={this.handleDelete}
                mediaType={mediaTypes.Meetup}
                triggerType={triggerTypes.Icon}
                isConfirmationNeeded={true}
              />
            </span>
          )}
          {canEditMeetup(speaker, author, status, isOver, user) && (
            <span>
              <IconButton onClick={this.handleEdit}>
                <EditIcon />
              </IconButton>
            </span>
          )}
        </div>
        {status === meetupStatus.Confirmed && !isOver && (
          <div className="go-to-meetup">
            <CustomButton
              onClick={this.hanldeChangeButton}
              className={go ? 'button joined-to-meetup-card' : 'button go-to-meetup-card'}
              variant="outlined"
              label={
                go
                  ? intl!.formatMessage({ id: 'meetupList.meetupPreview.button.joinedToMeetup' })
                  : intl!.formatMessage({ id: 'meetupList.meetupPreview.button.goToMeetup' })
              }
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: IStore) {
  return {
    meetups: state.meetups.themes,
    user: state.userInfo.user,
    userRole: state.userInfo.user.roles,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    deleteMeetup: bindActionCreators(deleteMeetup, dispatch),
    saveMeetupIdForDelete: bindActionCreators(saveMeetupIdForDelete, dispatch),
    goToMeetup: bindActionCreators(goToMeetup, dispatch),
    cancelGoToMeetup: bindActionCreators(cancelGoToMeetup, dispatch),
    changeSelectedMeetup: bindActionCreators(changeSelectedMeetup, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeetupCardHeader);
