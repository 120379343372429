import {
  ADD_MEMO,
  DELETE_MEMO,
  GET_MEMO_SUCCESS,
  MOVE_MEMO_ITEM,
  RESET_MEMO_DATA,
  UPDATE_MEMO_DATA,
} from 'constants/actionConstants';
import IMemo from '../../models/IMemo';
import { getIdForMemo, reorder } from './Memo.utils';
import { convertStringToRichText } from '../../utils/draft.utils';

export interface IMemoStore {
  memoInfo: IMemo[];
}

const initState: IMemoStore = {
  memoInfo: [],
};

export default function memoReducer(state: IMemoStore = initState, action: { type: string; payload: any }): IMemoStore {
  switch (action.type) {
    case GET_MEMO_SUCCESS:
      return {
        ...state,
        memoInfo: action.payload,
      };
    case UPDATE_MEMO_DATA:
      const newArray = state.memoInfo.map((item, index) => {
        return index === action.payload.id ? { ...item, [action.payload.name]: action.payload.value } : item;
      });
      return {
        ...state,
        memoInfo: newArray,
      };
    case ADD_MEMO:
      const newMemo = { id: getIdForMemo(), subject: '', body: convertStringToRichText('') };
      return {
        ...state,
        memoInfo: [...state.memoInfo, newMemo],
      };
    case DELETE_MEMO:
      return {
        ...state,
        memoInfo: state.memoInfo.filter((memo, index) => index !== action.payload),
      };
    case MOVE_MEMO_ITEM:
      return {
        ...state,
        memoInfo: reorder(state.memoInfo, action.payload.sourceIndex, action.payload.destinationIndex),
      };
    case RESET_MEMO_DATA:
      return {
        ...initState,
      };
    default:
      return state;
  }
}
