import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export default function configureStore(initialState?: any) {
  let store;
  const sagaMiddleware = createSagaMiddleware();

  if (process.env.NODE_ENV === 'development') {
    store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  } else {
    store = createStore(rootReducer, initialState, applyMiddleware(sagaMiddleware));
  }

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();
