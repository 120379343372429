export const SWITCH_LOCALE = 'SWITCH_LOCALE';
export const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO';
export const GET_ACCOUNT_INFO_SUCCESS = 'GET_ACCOUNT_INFO_SUCCESS';
export const GET_ACCOUNT_INFO_ERROR = 'GET_ACCOUNT_INFO_ERROR';

export const FETCH_MEETUP = 'FETCH_MEETUP';
export const FETCH_MEETUP_SUCCESS = 'FETCH_MEETUP_SUCCESS';
export const FETCH_MEETUP_ERROR = 'FETCH_MEETUP_ERROR';

export const FETCH_THEME = 'FETCH_THEME';
export const FETCH_THEME_SUCCESS = 'FETCH_THEME_SUCCESS';
export const FETCH_THEME_ERROR = 'FETCH_THEME_ERROR';

export const RESET_CURRENT_THEME = 'RESET_CURRENT_THEME';

export const FETCH_MEETUPS_LIST = 'FETCH_MEETUPS_LIST';
export const FETCHED_MEETUPS_LIST_SUCCESS = 'FETCHED_MEETUPS_LIST_SUCCESS';
export const FETCHED_MEETUPS_LIST_ERROR = 'FETCHED_MEETUPS_LIST_ERROR';
export const CHANGE_SELECTED_MEETUP = 'CHANGE_SELECTED_MEETUP';
export const DELETE_SELECTED_MEETUP = 'DELETE_SELECTED_MEETUP';
export const SAVE_PATHNAME_MEETUP_TABS = 'SAVE_PATHNAME_MEETUP_TABS';
export const SAVE_ID_MEETUP_FOR_DELETE = 'SAVE_ID_MEETUP_FOR_DELETE';
export const CLEAR_DELETED_MEETUP = 'CLEAR_DELETED_MEETUP';
export const CLEAR_SELECTED_MEETUP = 'CLEAR_SELECTED_MEETUP';

export const CREATE_MEETUP = 'CREATE_MEETUP';
export const CREATE_MEETUP_SUCCESS = 'CREATE_MEETUP_SUCCESS';
export const CREATE_MEETUP_ERROR = 'CREATE_MEETUP_ERROR';
export const UPDATE_MEETUP_DATA = 'UPDATE_MEETUP_DATA';
export const RESET_CURRENT_MEETUP = 'RESET_CURRENT_MEETUP';

export const EDIT_MEETUP = 'EDIT_MEETUP';
export const EDIT_MEETUP_SUCCESS = 'EDIT_MEETUP_SUCCESS';
export const EDIT_MEETUP_ERROR = 'EDIT_MEETUP_ERROR';

export const DELETE_MEETUP = 'DELETE_MEETUP';
export const DELETE_MEETUP_SUCCESS = 'DELETE_MEETUP_SUCCESS';
export const DELETE_MEETUP_ERROR = 'DELETE_MEETUP_ERROR';

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR';

export const DOWNLOAD_FILE_ARCHIVE = 'DOWNLOAD_FILE_ARCHIVE';
export const DOWNLOAD_FILE_ARCHIVE_SUCCESS = 'DOWNLOAD_FILE_ARCHIVE_SUCCESS';
export const DOWNLOAD_FILE_ARCHIVE_ERROR = 'DOWNLOAD_FILE_ARCHIVE_ERROR';

export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const DELETE_THEME = 'DELETE_THEME';

export const LIKE_THEME = 'LIKE_THEME';
export const DISLIKE_THEME = 'DISLIKE_THEME';

export const PUBLISH_MEETUP = 'PUBLISH_MEETUP';
export const PUBLISH_MEETUP_SUCCESS = 'PUBLISH_MEETUP_SUCCESS';
export const PUBLISH_MEETUP_ERROR = 'PUBLISH_MEETUP_ERROR';

export const GO_TO_MEETUP = 'GO_TO_MEETUP';
export const CANCEL_GO_TO_MEETUP = 'CANCEL_GO_TO_MEETUP';

export const BACK_TO_MODERATE = 'BACK_TO_MODERATE';
export const BACK_TO_MODERATE_SUCCESS = 'BACK_TO_MODERATE_SUCCESS';
export const BACK_TO_MODERATE_ERROR = 'BACK_TO_MODERATE_ERROR';

export const RATE_MEETUP = 'RATE_MEETUP';
export const UNRATE_MEETUP = 'UNRATE_MEETUP';

export const UPLOAD_MEETUP_FILE = 'UPLOAD_MEETUP_FILE';
export const UPLOAD_MEETUP_FILE_STATUS = 'UPLOAD_MEETUP_FILE_STATUS';
export const UPLOAD_MEETUP_FILE_ERROR = 'UPLOAD_MEETUP_FILE_ERROR';

export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_ERROR = 'SEARCH_USER_ERROR';

export const GET_DEFAULT_IMAGES_MEETUP = 'GET_DEFAULT_IMAGES_MEETUP';
export const GET_DEFAULT_IMAGES_MEETUP_SUCCESS = 'GET_DEFAULT_IMAGES_MEETUP_SUCCESS';
export const GET_DEFAULT_IMAGES_MEETUP_ERROR = 'GET_DEFAULT_IMAGES_MEETUP_ERROR';

export const GET_DEFAULT_IMAGES_NEWS = 'GET_DEFAULT_IMAGES_NEWS';
export const GET_DEFAULT_IMAGES_NEWS_SUCCESS = 'GET_DEFAULT_IMAGES_NEWS_SUCCESS';
export const GET_DEFAULT_IMAGES_NEWS_ERROR = 'GET_DEFAULT_IMAGES_NEWS_ERROR';

export const FETCH_NEWS_LIST = 'FETCH_NEWS_LIST';
export const FETCHED_NEWS_LIST_SUCCESS = 'FETCHED_NEWS_LIST_SUCCESS';
export const FETCHED_NEWS_LIST_ERROR = 'FETCHED_NEWS_LIST_ERROR';

export const FETCH_NEWS = 'FETCH_NEWS';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_ERROR = 'FETCH_NEWS_ERROR';
export const RESET_CURRENT_NEWS = 'RESET_CURRENT_NEWS';

export const DELETE_NEWS = 'DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_ERROR = 'DELETE_NEWS_ERROR';

export const EDIT_NEWS = 'EDIT_NEWS';
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS';
export const EDIT_NEWS_ERROR = 'EDIT_NEWS_ERROR';

export const CREATE_NEWS = 'CREATE_NEWS';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_ERROR = 'CREATE_NEWS_ERROR';
export const UPDATE_NEWS_DATA = 'UPDATE_NEWS_DATA';
export const CHANGE_SELECTED_NEWS = 'CHANGE_SELECTED_NEWS';
export const DELETE_SELECTED_NEWS = 'DELETE_SELECTED_NEWS';

export const LIKE_NEWS = 'LIKE_NEWS';
export const DISLIKE_NEWS = 'DISLIKE_NEWS';

export const FETCH_THEMES_LIST = 'FETCH_THEMES_LIST';
export const FETCHED_THEMES_LIST_SUCCESS = 'FETCHED_THEMES_LIST_SUCCESS';
export const FETCHED_THEMES_LIST_ERROR = 'FETCHED_THEMES_LIST_ERROR';

export const APPROVE_THEME = 'APPROVE_THEME';
export const APPROVE_THEME_SUCCESS = 'APPROVE_THEME_SUCCESS';
export const APPROVE_THEME_ERROR = 'APPROVE_THEME_ERROR';

export const FETCH_ACCESS_ROUTE = 'FETCH_ACCESS_ROUTE';
export const FETCH_ACCESS_GRANTED = 'FETCH_ACCESS_GRANTED';
export const FETCH_ACCESS_DENIED = 'FETCH_ACCESS_DENIED';
export const RESET_ACCESS_ROUTE = 'RESET_ACCESS_ROUTE';

export const CREATE_THEME = 'CREATE_THEME';
export const CREATE_THEME_SUCCESS = 'CREATE_THEME_SUCCESS';
export const CREATE_THEME_ERROR = 'CREATE_THEME_ERROR';

export const EDIT_THEME = 'EDIT_THEME';
export const EDIT_THEME_SUCCESS = 'EDIT_THEME_SUCCESS';
export const EDIT_THEME_ERROR = 'EDIT_THEME_ERROR';

export const FETCH_USER_PHOTO = 'FETCH_USER_PHOTO';
export const FETCH_USER_PHOTO_SUCCESS = 'FETCH_USER_PHOTO_SUCCESS';
export const FETCH_USER_PHOTO_ERROR = 'FETCH_USER_PHOTO_ERROR';

export const FETCH_NOTIFICATION_LIST = 'FETCH_NOTIFICATION_LIST';
export const FETCH_NOTIFICATION_LIST_SUCCESS = 'FETCH_NOTIFICATION_LIST_SUCCESS';
export const FETCH_NOTIFICATION_LIST_ERROR = 'FETCH_NOTIFICATION_LIST_ERROR';

export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const READ_NOTIFICATIONS_SUCCESS = 'READ_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATIONS_ERROR = 'READ_NOTIFICATIONS_ERROR';

export const GET_MEMO = 'GET_MEMO';
export const GET_MEMO_SUCCESS = 'GET_MEMO_SUCCESS';
export const GET_MEMO_ERROR = 'GET_MEMO_ERROR';

export const ADD_MEMO = 'ADD_MEMO';
export const DELETE_MEMO = 'DELETE_MEMO';
export const UPDATE_MEMO_DATA = 'UPDATE_MEMO_DATA';
export const RESET_MEMO_DATA = 'RESET_MEMO_DATA';

export const EDIT_MEMO = 'EDIT_MEMO';
export const EDIT_MEMO_SUCCESS = 'EDIT_MEMO_SUCCESS';
export const EDIT_MEMO_ERROR = 'EDIT_MEMO_ERROR';

export const MOVE_MEMO_ITEM = 'MOVE_MEMO_ITEM';

export const AUTHENTICATION_EXPIRED = 'AUTHENTICATION_EXPIRED';
