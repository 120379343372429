import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { DELETE_MEETUP } from 'constants/actionConstants';
import { deleteMeetupSuccess, deleteMeetupError } from './MeetupDelete.actions';
import { fetchMeetupsList } from 'containers/MeetupList/MeetupList.actions';
import { getMeetupSelector } from 'utils/meetupSelector';
import { NotificationManager } from 'react-notifications';
import history from '../../../utils/history';
import { RouteUrls } from '../../../constants/RouteUrls';
import { meetupStatus } from '../../../constants/meetupStatus';

function* deleteMeetupAsync({ payload }: any) {
  const role = yield select(state => state.userInfo.user.roles);
  const status = yield select(state => state.currentMeetup.status);
  const isOver = yield select(state => state.currentMeetup.isOver);
  const filter = getMeetupSelector(role);
  try {
    yield call(async () => {
      return await ApiService.meetUps().delete(payload);
    });
    yield put(deleteMeetupSuccess());
    NotificationManager.success(TranslateService.translate('meetupList.meetupDelete.deleteSuccess'));
    if (status === meetupStatus.Draft) {
      yield history.push(RouteUrls.DraftMeetup);
    }
    if (status === meetupStatus.Confirmed && !isOver) {
      yield history.push(RouteUrls.FutureMeetup);
    }
    if (status === meetupStatus.Confirmed && isOver) {
      yield history.push(RouteUrls.PastMeetup);
    }
    yield put(fetchMeetupsList(filter));
  } catch (error) {
    yield put(deleteMeetupError(error));
  }
}

export const deleteMeetupSagas = [takeEvery(DELETE_MEETUP, deleteMeetupAsync)];
