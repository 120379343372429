// Don't use is it as string refs because of deprecated
export enum fieldNames {
  description = 'description',
  author = 'author',
  speaker = 'speakerId',
  subject = 'subject',
  image = 'image',
  defaultImage = 'picId',
  place = 'place',
  start = 'start',
  finish = 'finish',
  tmpImage = 'tmpImage',
  file = 'file',
  tmpImageOrig = 'tmpImageOrig',
  body = 'body',
}
