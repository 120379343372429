import * as React from 'react';
import IUser from 'models/IUser';
import UserAvatar from 'containers/UserAvatar/UserAvatar';

interface ICustomUserAvatarProps {
  user: IUser;
}

class CustomUserAvatar extends React.Component<ICustomUserAvatarProps, {}> {
  render() {
    const { user } = this.props;

    return (
      <div className="card-avatar__wrapper">
        <UserAvatar
          className="card-avatar"
          user={user}
          round={true}
          size="30"
        />
        <span className="card-avatar__title">{user.name}</span>
        <span className="card-avatar__title">{user.surname}</span>
      </div>
    );
  }
}

export default CustomUserAvatar;
