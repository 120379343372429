export default interface IImageInfo {
  imageSize: number;
  imageTitle: string;
  imagePreviewUrl?: string;
}

export class InitImageInfo implements IImageInfo {
  imageTitle = '';
  imageSize = 0;
  imagePreviewUrl = '';
}
