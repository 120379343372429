import ApiService from 'services/ApiService';
import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_NEWS } from 'constants/actionConstants';
import { fetchNewsSuccess, fetchNewsError } from './NewsPreview.actions';

function* fetchNewsAsync({ payload }: any) {
  try {
    const newsData = yield call(async () => {
      return await ApiService.news().getById(payload);
    });
    const newsImage = yield call(async () => {
      return await ApiService.getNewsImage(newsData.data.picId, {});
    });
    yield put(fetchNewsSuccess({ ...newsData, image: newsImage.data }));
  } catch (error) {
    yield put(fetchNewsError(error));
  }
}

export const newsSagas = [takeEvery(FETCH_NEWS, fetchNewsAsync)];
