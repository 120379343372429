import {
  EDIT_NEWS,
  EDIT_NEWS_SUCCESS,
  EDIT_NEWS_ERROR
} from 'constants/actionConstants';

export function editNews() {
  return { type: EDIT_NEWS };
}

export function editNewsSuccess() {
  return { type: EDIT_NEWS_SUCCESS };
}

export function editNewsError(error: Error) {
  return { type: EDIT_NEWS_ERROR, payload: error };
}
