import {
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_ERROR,
  AUTHENTICATION_EXPIRED,
} from 'constants/actionConstants';
import { IAccountInfo } from 'react-aad-msal';

export function getAccountInfo(userInfo: IAccountInfo) {
  return { type: GET_ACCOUNT_INFO, payload: { userInfo } };
}

export function getAccountInfoSuccess(userInfo: Account) {
  return { type: GET_ACCOUNT_INFO_SUCCESS, payload: { userInfo } };
}

export function getAccountInfoError() {
  return { type: GET_ACCOUNT_INFO_ERROR };
}

export function fetchUnauthorized() {
  return { type: AUTHENTICATION_EXPIRED };
}
