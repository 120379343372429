export const downloadWithLink = (response: any, isArchieve: boolean, fileName?: string) => {
  const nameForFilesArchive = response.headers['content-disposition'].slice(21);
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: isArchieve ? 'application/zip' : 'content-type',
    }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', isArchieve ? nameForFilesArchive : fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
