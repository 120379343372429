import ApiService from 'services/ApiService';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { downloadFileError, downloadFileSuccess } from './FileDownload.actions';
import { fetchMeetup } from '../../MeetupPreview/MeetupPreview.actions';
import { DOWNLOAD_FILE } from '../../../../constants/actionConstants';
import { downloadWithLink } from '../../../../utils/fileDownload.utils';

function* fileDownloadAsync({ payload }: any) {
  const meetupId = yield select(state => state.currentMeetup.id);

  try {
    const response = yield call(async () => {
      return await ApiService.fileDownload(payload.fileId, {
        responseType: 'arraybuffer',
      });
    });
    yield downloadWithLink(response, false, payload.fileName);
    yield put(downloadFileSuccess());
    yield put(fetchMeetup(meetupId));
  } catch (error) {
    yield put(downloadFileError(error));
  }
}

export const downloadFileSagas = [takeEvery(DOWNLOAD_FILE, fileDownloadAsync)];
