import { call, put, takeEvery, select } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import ApiService from 'services/ApiService';
import TranslateService from 'services/TranslateService';
import { getCurrentMeetup } from 'selectors/storeSelectors';
import { editMeetupError, editMeetupSuccess } from './MeetupEdit.actions';
import { EDIT_MEETUP } from 'constants/actionConstants';
import { mediaTypes } from 'constants/mediaTypes';
import { fieldNames } from 'constants/fieldNames';
import { descriptionLimit } from 'constants/inputValueLimits';

import { fixDescription } from 'utils/draft.utils';
import history from 'utils/history';
import { replaceUrlParams } from 'utils/routeUrls';
import { RouteUrls } from 'constants/RouteUrls';

function* editMeetupAsync() {
  try {
    const payload = yield select(getCurrentMeetup);
    const { id, tmpImageOrig, speakerId, subject, start, finish, place, picId } = payload;
    const description = fixDescription(payload.description, descriptionLimit);

    yield call(async () => {
      return await ApiService.meetUps().edit(payload.id, {
        speakerId,
        subject,
        description,
        start,
        finish,
        place,
        picId,
      });
    });
    if (tmpImageOrig) {
      yield call(async () => {
        const formData = new FormData();
        formData.append(fieldNames.file, tmpImageOrig);
        return await ApiService.fileUpload(mediaTypes.Meetup).meetupImageUpload(id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      });
    }
    yield put(editMeetupSuccess());
    NotificationManager.success(TranslateService.translate('meetupList.meetupUpdate.updateSuccess'));
    yield history.push(replaceUrlParams(RouteUrls.PreviewEvent, { id }));
  } catch (error) {
    yield put(editMeetupError(error));
  }
}

export const editMeetupSagas = [takeEvery(EDIT_MEETUP, editMeetupAsync)];
