import React from 'react';
import { ContentState } from 'draft-js';

type Props = {
  children: React.ReactNode;
  entityKey: string;
  contentState: ContentState;
};

const findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

const Link = (props: Props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <a href={url} title={url} className="inline-link" target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

export default {
  strategy: findLinkEntities,
  component: Link,
};
